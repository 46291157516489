import { API } from '@/services/api/api'

const getOrders = async ({ commit, rootState, dispatch }, { filters = {}, loading, onSuccess }) => {
  if (loading) commit('setOrdersRequest', 'loading')
  rootState.delayOrdersListRefresh = null

  let params = {
    archive: filters.archive,
    employee_id: filters.employeeID,
    payment_method: filters.paymentMethod,
    finished_order_choices: filters.finishedOrderChoices,
    menu_source: filters.menuSource,
    discount: filters.discount,
    has_tip: filters.tips,
    has_uber_discount: filters.uberDiscount,
    page: filters.page,
    finished: filters.finished,
    brand: filters.brand,
    search_keyword: filters.searchKeyword,
    search_by: filters.searchBy,
    is_printed: filters.isPrinted,
    has_invoice: filters.hasInvoice,
    is_splitted: filters.isSplitted,
    is_transferred: filters.isTransferred,
  }
  if (filters && filters.dateType) {
    params[`${filters.dateType}_after`] = filters.startDate
    params[`${filters.dateType}_before`] = filters.endDate
  }
  const { error, data } = await API.getOrders(params)
  if (!error && data) {
    // eslint-disable-next-line camelcase
    const { results, count, price_sum, discount_sum, tip_sum } = data
    // If it's archive then response contains pagination,
    // if current list, then response is flat orders list
    const orders = results || data
    commit('setOrdersRequest', 'success')
    commit('setOrders', orders)
    // eslint-disable-next-line camelcase
    if (onSuccess) {
      onSuccess({
        count: count,
        price_sum: price_sum,
        discount_sum: discount_sum,
        tip_sum: tip_sum,
      })
    }
    commit(
      'setNewOrdersCount',
      orders.filter((order) => {
        return order.status === 'new'
      }).length,
      { root: true }
    )

  } else {
    commit('setOrdersRequest', 'error')
  }
}

const getOnlineCouriersList = async ({ commit }) => {
  const { error, data } = await API.getCouriers()
  if (!error && data) {
    commit('setCouriers', data)
  } else {
    // TODO: Handle error
  }
}

const getMapCouriesList = async ({ commit }) => {
  const { error, data } = await API.getMapCouriers()
  if (!error && data) {
    commit('setCouriers', data)
  } else {
    // TODO: Handle error
  }
  return { error, data }
}

const getEmployees = async ({ commit }) => {
  const { error, data } = await API.getUsers({ active: true })
  if (!error && data) {
    commit('setEmployees', data)
  } else {
    //TODO: Handle error
  }
}

const regeocodeOrders = async ({ state, commit, dispatch }, { ordersIds, onSuccess, onError }) => {
  // Save orders IDs in cache
  commit('setOrdersInRegeocode', [...state.ordersInRegeocode, ...ordersIds])
  const { error } = await API.regeocodeOrders(ordersIds)
  if (!error) {
    if (onSuccess) onSuccess()
    setTimeout(() => {
      dispatch('notifications/getNotifications', null, { root: true })
    }, 5000)
  } else {
    if (onError) onError()
    // Remove orders from cache if them could not be regeocoded
    commit('removeOrdersInRegeocodeByIds', ordersIds)
  }
}

export default {
  getOrders,
  getOnlineCouriersList,
  getMapCouriesList,
  getEmployees,
  regeocodeOrders,
}
