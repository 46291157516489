import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
// Global Store Modules
import auth from './modules/auth'
import settings from './modules/settings'
import employees from './modules/employees'
import _orders from './modules/orders'
import modules, { IModulesState } from './modules/modules'
import integrations, { IntegrationsState } from './modules/integrations'
import menu from './modules/menu'
import newOrder, { INewOrderState } from '@/store/modules/new-order'
import order, { IOrderState } from '@/store/modules/order'
import ordersPapu3 from '@/store/modules/papu-3-orders'
import recommendations, { IRecommendationsState } from './modules/recommendations'
import notifications from './modules/notifications'

import {
  IAuthState,
  ICategory,
  IMenu,
  IMenuExtended,
  IMenuState,
  IMultiChoiceGroup,
  ISingleChoiceGroup,
} from '@/types/interface'


Vue.use(Vuex)
export interface RootState {
  menus: any[]
  getMenuRequestStatus: string
  currentBrandId: number | null
  currentBrandName: string
  currentSource: number
  stateLoadedFromSession: boolean
  newOrdersCount: number
  halfPizzaSettings: boolean
  delayOrdersListRefresh: number | null
  windowWidth: number
  getPredictionsRequest: boolean
  auth?: IAuthState // Add auth state type
  settings?: any // Add settings state type
  employees?: any // Add employees state type
  modules?: IModulesState
  integrations?: IntegrationsState
  _orders?: any
  menu?: IMenuState
  newOrder?: INewOrderState
  order?: IOrderState
  mealSets: any[]
  recommendations?: IRecommendationsState
}

export interface RootGetters {
  menu: IMenu | null
  menus: Array<IMenuExtended>
  menuIndex: number
  mealSets: Array<any>
  categories: Array<ICategory>
  multiChoiceGroups: Array<IMultiChoiceGroup>
  singleChoiceGroups: Array<ISingleChoiceGroup>
  currentSource: number
  currentBrandId: number | null
  stateLoadedFromSession: boolean
  newOrdersCount: number
  halfPizzaSettings: boolean
  currency: string
  delayOrdersListRefresh: any
  isMobile: boolean
  getChoiceGroupIndexes: void
}

const getInitialState = (): RootState => {
  return {
    menus: [],
    mealSets: sessionStorage.getItem('mealSets') ? JSON.parse(sessionStorage.getItem('mealSets') || '') : [],
    getMenuRequestStatus: '',
    currentBrandId: null,
    currentBrandName: '',
    currentSource: 0,
    stateLoadedFromSession: false,
    newOrdersCount: 0,
    halfPizzaSettings: false,
    delayOrdersListRefresh: null,
    windowWidth: 1200,
    getPredictionsRequest: false,
  }
}

export default new Vuex.Store({
  state: getInitialState(),
  getters,
  mutations: {
    ...mutations,
    resetState(state) {
      Object.assign(state, {
        // we can't set to default properties:
        // - stateLoadedFromSession - displaying app depends on it
        // - windowWidth - we shouldn't set it manually since user could change it
        menus: [],
        mealSets: [],
        getMenuRequestStatus: '',
        currentBrandId: '',
        currentBrandName: '',
        currentSource: 0,
        newOrdersCount: 0,
        halfPizzaSettings: false,
        delayOrdersListRefresh: null,
      })
    },
  },
  actions,
  modules: {
    auth,
    settings,
    employees,
    _orders,
    modules,
    integrations,
    _menu: menu,
    newOrder,
    order,
    ordersPapu3,
    recommendations,
    notifications
  },
})
