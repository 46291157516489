import store from '@/store/index'
import { API } from '@/services/api/api'
import { ORDER_STATUSES } from '../../../common/constants'

const getOrder = async ({ commit, state, dispatch }, onSuccess) => {
  commit('setOrderRequestStatus', 'loading')
  const { error, data } = await API.getOrder(state.orderId)
  if (!error && data) {
    commit('setCart', data)
    commit('setOrder', data)
    commit('_orders/updateOrderList', data, { root: true })
    commit('setPaymentLoading', false)
    commit('setTableLoading', false)
    commit('setOrderRequestStatus', 'success')
    if (data.status === ORDER_STATUSES.ORDER_STATUS_NEW) {
      dispatch('getPredictions')
    }
    if (onSuccess) onSuccess()
  } else {
    commit('setPaymentLoading', false)
    commit('setTableLoading', false)
    commit('setOrderRequestStatus', 'error')
  }
}

const getInvoiceData = async ({ commit, state }) => {
  const { error, data } = await API.getInvoiceData({ order_id: state.orderId })
  if (!error && data) {
    commit('setInvoiceData', data)
  }
}

const getInvoiceById = async ({ commit, state }) => {
  const { error, data } = await API.getInvoice(state.order.invoice_id)
  if (!error && data) {
    commit('setInvoiceData', data)
  }
}

const editInvoice = async (
  { state, getters, dispatch },
  { send_invoice_by_email = false, print_invoice = false, onSuccess = null, onError = null }
) => {
  const invoiceData = { ...getters.getInvoiceDataPrepareToApi }
  invoiceData.send_invoice_by_email = send_invoice_by_email
  invoiceData.print_invoice = print_invoice

  const { error } = await API.updateInvoice(state.order.invoice_id, invoiceData)
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getOrder')
  } else {
    if (onError) onError(error)
  }
}

const createInvoice = async (
  { getters, dispatch },
  { send_invoice_by_email = false, print_invoice = false, onSuccess = null, onError = null }
) => {
  const invoiceData = { ...getters.getInvoiceDataPrepareToApi }
  invoiceData.send_invoice_by_email = send_invoice_by_email
  invoiceData.print_invoice = print_invoice

  const { error } = await API.createInvoice(invoiceData)
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getOrder')
  } else {
    if (onError) onError(error)
  }
}

const updateOrder = async ({ dispatch, commit, state }, { order, onSuccess, onError, refresh }) => {
  const { error, data } = await API.updateOrder(state.orderId, order)
  if (!error) {
    if (refresh) {
      if (onSuccess) onSuccess(data)
      dispatch('getOrder')
    } else {
      store.dispatch('goToDefaultOrdersList')
    }
  } else {
    if (onError) onError(error)
    commit('setPaymentLoading', false)
    commit('setTableLoading', false)
  }
}

const changeFinishedOrderStatus = async ({ commit, state }, payload) => {
  const { selectedFinishOrderChoice, onSuccess } = payload
  const { error, data } = await API.updateOrder(state.orderId, {
    finished_type: selectedFinishOrderChoice,
    status: 'finished',
  })
  if (!error && data) {
    commit('setCart', data)
    commit('setOrder', data)
    if (onSuccess) onSuccess()
  } else {
    //TODO: HANDLE ERROR
  }
}

const closeOrder = async ({ commit }, payload) => {
  const { orderData, orderId, onSuccess, onError } = payload
  const { error, data } = await API.closeOrder(orderId, orderData)
  if (!error && data) {
    if (data) {
      commit('_orders/removeOrderFromList', orderId, { root: true })
      commit('updateOrder', data)
      commit('updateCart', data)
    }
    if (onSuccess) onSuccess()
  } else {
    const errorData = error?.response?.data
    if (onError && errorData) onError(errorData)
  }
}

const acceptOrDeclineOrder = async ({}, payload) => {
  // makes a call to 'accept' with promised times or minutes or 'decline' api
  const { error } = await API.acceptOrDeclineOrder(payload.sendData.order_id, payload.apiUrl, payload.sendData)
  if (!error) {
    if (payload.onSuccess) payload.onSuccess()
    store.dispatch('goToDefaultOrdersList')
  } else {
    if (error.response && payload.onError) {
      payload.onError(error.response.data)
    }
  }
}

const transferOrder = async ({}, payload) => {
  const { orderId, localizationId, onSuccess, disableRedirect } = payload
  const { error } = await API.transferOrder(orderId, localizationId)
  if (!error) {
    if (onSuccess) onSuccess()
    if (!disableRedirect) {
      store.dispatch('goToDefaultOrdersList')
    }
  } else {
    // TODO: Handle error
  }
}

const getPredictions = ({ commit, state, dispatch }) => {
  // eslint-disable-next-line camelcase
  const { order_customer, delivery_type, pickup_type, lat, lon, price } = state.order
  const predictionData = {
    lat,
    lon,
    address: order_customer.address,
    deliveryType: delivery_type,
    pickupType: pickup_type,
    price,
    onSuccess: (data) => {
      commit('setEstimatedDeliveryTime', data)
    },
  }
  dispatch('getPredictions', predictionData, { root: true })
}

const getBills = async ({ commit, state }) => {
  const { error, data } = await API.getBills({ order: state.order.id })
  if (!error && data) {
    commit('setBills', data)
  } else {
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const getErrorValue = (error) => {
  const response = error?.response || null
  const data = response?.data || null
  const errorKey = data ? Object.keys(data) : null
  const errorValue = errorKey?.length ? data[errorKey[0]] : null
  return errorValue
}

const getErrorStatus = (error) => {
  const response = error?.response || null
  const errorStatus = response?.status || null
  return errorStatus
}

export default {
  getOrder,
  updateOrder,
  changeFinishedOrderStatus,
  acceptOrDeclineOrder,
  closeOrder,
  transferOrder,
  getPredictions,
  getInvoiceData,
  createInvoice,
  editInvoice,
  getInvoiceById,
  getBills,
}
