<template>
  <div v-if="visible" class="notification-toast-container" :style="{ marginBottom }">
    <NotificationToast
      v-for="(notification, index) in visibleTopNotifications"
      :key="'notification_' + notification.pk + notification.type"
      :notification="notification"
    />
    <div v-if="showMore" class="notification-more"></div>
    <div v-if="showExtraMore" class="notification-more notification-more--extra"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NotificationToast from './NotificationToast.vue'

export default {
  name: 'NotificationToastContainer',
  components: {
    NotificationToast,
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapState('notifications', ['unreadNotifications']),
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
    }),
    visible() {
      return (
        this.unreadNotifications.length > 0 &&
        (this.$route.name === 'orders-list' || this.$route.name === 'couriers-map')
      )
    },
    visibleTopNotifications() {
      // Get only 3 notifications from the top
      return this.unreadNotifications.slice(0, 3)
    },
    showMore() {
      return this.unreadNotifications.length >= 4
    },
    showExtraMore() {
      return this.unreadNotifications.length >= 5
    },
    marginBottom() {
      if (this.showExtraMore) {
        return '28px'
      } else if (this.showMore) {
        return '18px'
      }
      return '12px'
    },
  },
}
</script>

<style lang="scss">
.notification-toast-container {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 12px;
  z-index: 1000;
  padding: 0;
}

.notification-more {
  background-color: #fff;
  height: 20px;
  width: calc(100% - 32px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: -8px;
  z-index: -1;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.15);

  &--extra {
    width: calc(100% - 52px);
    bottom: -16px;
    z-index: -2;
  }
}

@media (max-width: 768px) {
  .notification-toast-container {
    bottom: 68px;
  }
}
</style>
