import { mapGetters, mapMutations } from 'vuex'

export const newOrderMixins = {
  computed: {
    ...mapGetters({
      isMultiBrand: 'auth/isMultiBrand',
      brands: 'auth/brands',
    }),
  },
  methods: {
    ...mapMutations({
      setBrand: 'setBrand',
    }),
    showBrandsModal(payload = {}) {
      this.$eventBus.$emit('onShowBrandsModal', payload)
    },
    goToNewOrder(onsite = false) {
      // We need to set brand to fix possible issues when wrong brand is set
      // only for single brand, because multiBrand will choose brand from modal
      if (!this.isMultiBrand) {
        // get the only json element from brands json
        const brandId = Object.keys(this.brands)[0]
        const brandName = this.brands[brandId].name
        this.setBrand({
          brandId: parseInt(brandId),
          brandName,
        })
      }

      // If meal was changed, it pops up confirmation modal, otherwise changes view
      if (this.$store.state['_orderEdit'] && !this.$store.getters['_orderEdit/mealSaved']) {
        this.$eventBus.$emit('onShowMealDiscardChangesModal')
      } else {
        this.$router.push({ name: 'new-order', params: { edit: false }, query: { orderOnSite: onsite } })
        // If it's not new action and _orderEdit is already registered (as if you're actually in orderEdit),
        // start MealsMenu view for new order
        if (this.$store.state['_orderEdit']) {
          this.$eventBus.$emit('initNewOrder')
          this.$eventBus.$emit('onUpdateCartActiveMeal', null)
        }
      }
    },
    onClickTrigger(onsite = false) {
      this.isMultiBrand ? this.showBrandsModal({ orderOnSite: onsite, showOnline: false }) : this.goToNewOrder(onsite)
      this.$emit('trigger-clicked')
    },
  },
}
