<template>
  <div class="view-container">
    <div class="widget">
      <div class="widget-body-wrapper px-0">
        <b-row>
          <b-col>
            <b-breadcrumb class="bg-white px-3">
              <b-breadcrumb-item :to="{ name: 'settings' }">
                {{ $t('message.settings.settings') }}
              </b-breadcrumb-item>
              <b-breadcrumb-item active :to="{ name: 'mealsets-list' }">
                {{ $t('message.mealSets.mealSetsSettings') }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>
        </b-row>
        <b-container fluid>
          <b-row>
            <b-col class="d-inline-flex">
              <b-dropdown :text="$t('message.mealSets.newMealSet')" variant="primary" :size="isMobile ? 'sm' : 'lg'">
                <b-dropdown-item :to="{ name: 'mealset-edit', params: { type: MEALSET_TYPES.MEAL_SET } }">
                  {{ $t('message.mealSets.normal') }}
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'mealset-edit', params: { type: MEALSET_TYPES.HALF_PIZZA } }">
                  {{ $t('message.mealSets.halfByHalf') }}
                </b-dropdown-item>
              </b-dropdown>
              <BrandsDropdown
                :value="currentBrand"
                :size="isMobile ? 'sm' : 'lg'"
                class="ml-3"
                @choose-brand="chooseBrand"
              />
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid class="mt-3">
          <b-skeleton-table v-if="loading" :rows="5" :columns="6"></b-skeleton-table>
          <b-table-simple v-else striped responsive thead-class="table__thead" table-class="table--cell-wrap">
            <b-thead>
              <b-tr>
                <b-th scope="col"></b-th>
                <b-th scope="col">{{ $t('message.mealSets.type') }}</b-th>
                <b-th scope="col">{{ $t('message.mealSets.brandName') }}</b-th>
                <b-th scope="col">{{ $t('message.mealSets.name') }}</b-th>
                <b-th scope="col">{{ $t('message.mealSets.discount') }}</b-th>
                <b-th scope="col">{{ $t('message.mealSets.items') }}</b-th>
                <b-th scope="col">{{ $t('erestaurant') }}</b-th>
                <b-th scope="col"></b-th>
              </b-tr>
            </b-thead>
            <draggable v-model="mealSets" v-bind="dragOptions" tag="tbody" @change="updateMealSetPositions">
              <MealSetRow
                v-for="mealSet in mealSets"
                :key="mealSet.id"
                :meal-set="mealSet"
                @removeMealSet="removeMealSet"
              />
            </draggable>
          </b-table-simple>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import draggable from 'vuedraggable'
import MealSetRow from './components/MealSetRow'
import BrandsDropdown from '@/components/BrandsDropdown'
import { API } from '@/services/api/api'
import Vue from 'vue'
import { showErrorToast } from '@/utils/common'
import { MEALSET_TYPES } from '@/common/constants'

export default {
  name: 'MealSetsList',
  components: { BrandsDropdown, MealSetRow, draggable },
  data: () => ({
    MEALSET_TYPES,
    loading: false,
    menuId: '',
    currentBrand: '',
  }),
  computed: {
    ...mapGetters(['isMobile', 'menu', 'menus', 'currentBrandName']),
    ...mapFields('_menu', ['mealSets']),
    dragOptions() {
      return {
        animation: 200,
        group: 'mealSets',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  mounted() {
    this.onInit()
  },
  destroyed() {
    this.$eventBus.$off('brandChosen')
  },
  methods: {
    ...mapActions(['chooseMenu']),
    ...mapActions('_menu', ['getMealSets', 'removeMealSet']),
    async removeMealSet(mealSetId) {
      this.loading = true
      const { error } = await API.deleteMealset(mealSetId)
      if (!error) {
        this.getMealSets()
      }
    },
    onInit() {
      // menuId route param is set when going back from MealSet edit
      const menuId = this.$route.params.menuId
      if (menuId) {
        this.menuId = this.menu.id
        if (this.menus && this.menus.length > 1) this.currentBrand = this.currentBrandName
        this.getMealSets()
      } else if (this.menus?.length > 1) {
        this.$eventBus.$emit('onShowBrandsModal', { setBrand: true })
        this.$eventBus.$on('brandChosen', () => {
          this.menuId = this.menu.id
          this.currentBrand = this.currentBrandName
          this.getMealSets()
        })
      } else {
        this.menuId = this.menu.id
        this.getMealSets()
      }
    },
    async getMealSets() {
      this.loading = true
      const { error, data } = await API.getMealSets({ menu: this.menuId })
      if (!error && data) {
        this.mealSets = data
        this.loading = false
      }
    },
    chooseBrand({ name, brandId }) {
      this.chooseMenu({ brandId: parseInt(brandId), brandName: name })
      this.menuId = this.menu.id
      this.getMealSets()
    },
    async updateMealSetPositions() {
      this.loading = true
      let requestData = this.mealSets.map((category, index) => ({ id: category.id, position: index + 1 }))
      requestData = { menu_id: this.menuId, positions: requestData }
      const { error } = await API.updateMealSetsPositions(requestData)
      Vue.toasted.clear()
      if (error) {
        showErrorToast(Vue.toasted, error)
        this.getMealSets()
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
.table {
  &__thead {
    th {
      max-width: 100px;
    }
  }
}
</style>
