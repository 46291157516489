<template>
  <b-modal
    id="printInvoice"
    ref="modal"
    :title="title"
    no-close-on-backdrop
    no-close-on-esc
    :hide-header-close="printingInProcess"
    hide-footer
    @keyup.enter.native="acceptAndSendInvoiceByEmail"
    @show="show"
    @shown="onShown"
    @hidden="onHidden"
  >
    <b-form @submit.stop.prevent="acceptAndSendInvoiceByEmail">
      <b-container class="bv-example-row">
        <b-form-group>
          <template #label
            >{{ $t('message.invoiceModal.companyName') }}
            <span class="text-danger ml-1">*</span>
          </template>
          <b-form-input
            v-model="invoice.name"
            v-validate="{ required: true }"
            type="text"
            name="companyName"
            :state="validateState('companyName')"
            aria-describedby="companyNameFeedback"
            data-vv-as=" "
          />
          <b-form-invalid-feedback id="companyNameFeedback">{{ errors.first('companyName') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <template #label
            >{{ $t('message.invoiceModal.streetAddress') }}
            <span class="text-danger ml-1">*</span>
          </template>
          <b-form-input
            v-model="invoice.address"
            v-validate="{ required: true }"
            type="text"
            name="streetAddress"
            :state="validateState('streetAddress')"
            aria-describedby="streetAddressFeedback"
            data-vv-as=" "
          />
          <b-form-invalid-feedback id="streetAddressFeedback">{{
            errors.first('streetAddress')
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col sm="4">
            <b-form-group>
              <template #label
                >{{ $t('message.invoiceModal.postalCode') }}
                <span class="text-danger ml-1">*</span>
              </template>
              <b-form-input
                v-model="invoice.postalCode"
                v-validate="{ required: true }"
                type="text"
                name="postalCode"
                :state="validateState('postalCode')"
                aria-describedby="postalCodeFeedback"
                data-vv-as=" "
              />
              <b-form-invalid-feedback id="postalCodeFeedback">{{
                errors.first('postalCode')
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="8">
            <b-form-group>
              <template #label
                >{{ $t('message.invoiceModal.city') }}
                <span class="text-danger ml-1">*</span>
              </template>
              <b-form-input
                v-model="invoice.city"
                v-validate="{ required: true }"
                type="text"
                name="city"
                :state="validateState('city')"
                aria-describedby="cityFeedback"
                data-vv-as=" "
              />
              <b-form-invalid-feedback id="cityFeedback">{{ errors.first('city') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <template #label>
            {{ $t('message.invoiceModal.taxIDNumber') }}<span class="text-danger ml-1">*</span>
          </template>
          <b-form-input
            v-model="invoice.taxId"
            v-validate="{ required: true }"
            type="text"
            name="taxId"
            :state="validateState('taxId')"
            aria-describedby="taxIdFeedback"
            data-vv-as=" "
            @input="customerTaxIDformatter('invoice.taxId')"
          />
          <b-form-invalid-feedback id="taxIdFeedback">{{ errors.first('taxId') }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="form-check form-check-inline">
          <input
            id="hide-details"
            v-model="invoice.hideDetails"
            class="form-check-input"
            type="checkbox"
            @change="resetHideDetailsFields"
          />
          <label class="form-check-label" for="hide-details">{{ $t('message.invoiceModal.hideDetails') }}</label>
        </div>
        <div v-if="invoice.hideDetails" class="mt-3">
          <b-form-group :label="$t('message.invoiceModal.hideName')">
            <input
              id="hide-name-def"
              v-model="invoice.hideNameTypeChosen"
              type="radio"
              value="default"
              name="hideNameTypeChosen"
              @input="resetCustomVatFields"
            />
            <label for="hide-name-def"
              >{{ $t('message.invoiceModal.hideNames.default') }} ({{ getDefaultFoodServiceVatRateName() }} VAT)</label
            >
            <br />
            <input
              id="hide-name-cus"
              v-model="invoice.hideNameTypeChosen"
              type="radio"
              value="custom"
              name="hideNameTypeChosen"
              @click="showCustomInputsAndFocusOn('hideNameChosenInput')"
            />
            <label for="hide-name-cus">{{ $t('message.invoiceModal.hideNames.custom') }}</label>
          </b-form-group>
          <div v-if="isActiveCustomVat">
            <b-row>
              <b-col sm="8">
                <b-form-group>
                  <template #label> {{ $t('message.cart.name') }}<span class="text-danger ml-1">*</span> </template>
                  <b-form-input
                    id="hideNameChosen"
                    ref="hideNameChosenInput"
                    v-model="invoice.hideNameChosen"
                    v-validate="{ required: invoice.hideDetails && isActiveCustomVat }"
                    type="text"
                    size="lg"
                    name="hideNameChosen"
                    :state="validateState('hideNameChosen')"
                    aria-describedby="hideNameChosenFeedback"
                    data-vv-as=" "
                  />
                  <b-form-invalid-feedback id="hideNameChosenFeedback">{{
                    errors.first('hideNameChosen')
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <template #label> {{ $t('message.cart.vat') }}<span class="text-danger ml-1">*</span> </template>
                  <b-dropdown
                    id="hide-details-vat"
                    block
                    variant="primary"
                    menu-class="w-100"
                    :text="invoice.hideDetailsVat"
                  >
                    <b-dropdown-item
                      v-for="(vat, index) in getVatRates(true)"
                      :key="index"
                      type="button"
                      class="dropdown-item dropdown-item--inner-hover"
                      :value="vat.value"
                      :class="{ 'dropdown-item--active': invoice.hideDetailsVat === vat.value }"
                      @click="invoice.hideDetailsVat = vat.value"
                    >
                      {{ vat.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <hr />
        <b-form-group class="mb-2">
          <template #label>
            {{ $t('message.invoiceModal.paymentType') }}<span class="text-danger ml-1">*</span>
          </template>
          <template v-if="!editMode && paymentFirst">
            <input
              id="payment-type-order"
              v-model="invoice.paymentTypeChosen"
              type="radio"
              value="order"
              name="paymentTypeOption"
              @input="resetCustomPaymentsFields"
            />
            <label for="payment-type-order"
              >{{ $t('message.invoiceModal.paymentTypes.order') }} – {{ getPaymentName(paymentFirst) }}</label
            >
            <br />
          </template>
          <input
            id="payment-type-custom"
            v-model="invoice.paymentTypeChosen"
            type="radio"
            value="custom"
            name="paymentTypeOption"
          />
          <label for="hide-name-cus">{{ $t('message.invoiceModal.paymentTypes.custom') }}</label>
        </b-form-group>
        <div v-if="isActiveCustomPaymentType">
          <b-row>
            <b-col sm="6">
              <b-form-group label-for="paymentTypeDropdown">
                <template #label>
                  {{ $t('message.common.selectFromList') }}<span class="text-danger ml-1">*</span>
                </template>
                <b-dropdown
                  id="paymentTypeDropdown"
                  block
                  variant="primary"
                  menu-class="w-100"
                  :text="getPaymentName(invoice.customPaymentType)"
                >
                  <b-dropdown-item
                    v-for="(paymentType, paymentTypeIndex) in payments"
                    :key="paymentTypeIndex"
                    type="button"
                    class="dropdown-item dropdown-item--inner-hover"
                    :value="paymentType.value"
                    :class="{ 'dropdown-item--active': invoice.customPaymentType === paymentType.value }"
                    @click="
                      invoice.customPaymentType = paymentType.value
                      invoice.paymentTypeName = ''
                    "
                  >
                    {{ paymentType.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col v-if="isActiveCustomPaymentType && invoice.customPaymentType === null" cols="12">
              <b-form-group label-for="paymentTypeName">
                <template #label> {{ $t('message.cart.name') }}<span class="text-danger ml-1">*</span> </template>
                <b-form-input
                  id="paymentTypeName"
                  ref="paymentTypeNameInput"
                  v-model="invoice.paymentTypeName"
                  v-validate="{ required: isActiveCustomPaymentType && invoice.customPaymentType === null }"
                  type="text"
                  size="lg"
                  name="paymentTypeName"
                  :state="validateState('paymentTypeName')"
                  aria-describedby="paymentTypeNameFeedback"
                  data-vv-as=" "
                />
                <b-form-invalid-feedback id="paymentTypeNameFeedback">{{
                  errors.first('paymentTypeName')
                }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <span v-if="error" class="error">{{ $t('message.invoiceModal.allFieldsRequired') }}</span>
        <b-overlay :show="printingInProcess" rounded="sm" spinner-variant="success">
          <b-container>
            <b-row class="d-flex">
              <b-col class="mt-3">
                <b-button
                  ref="sendInvoiceButton"
                  variant="success"
                  size="lg"
                  class="w-100 h-100"
                  :disabled="printingInProcess"
                  @click="acceptAndSendInvoiceByEmail"
                >
                  <span>
                    <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>
                    {{ $t('message.invoiceModal.sendInvoiceByEmail') }}
                  </span>
                </b-button>
              </b-col>
              <b-col class="mt-3">
                <b-button
                  ref="printInvoiceButton"
                  variant="success"
                  size="lg"
                  class="w-100 h-100"
                  :disabled="printingInProcess"
                  @click="acceptAndPrintInvoice"
                >
                  <span>{{ $t('message.invoiceModal.printInvoice') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </b-container>
    </b-form>
  </b-modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { taxIdMixins, vatMixins } from '@/mixins'
export default {
  name: 'PrintInvoiceModal',
  mixins: [taxIdMixins, vatMixins],
  data() {
    return {
      error: false,
      orderId: 0,
      printingInProcess: false,
      sendInvoiceByEmail: false,
      printInvoice: false,
      payments: [
        { name: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash' },
        { name: this.$t('message.orderFormFull.paymentTypes.paid'), value: 'paid' },
        { name: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card' },
        { name: this.$t('message.common.other'), value: null },
      ],
    }
  },
  computed: {
    ...mapFields('_orderDetails', ['cart', 'invoice', 'order.invoice_id', 'order.payment']),
    title() {
      return this.editMode ? this.$t('message.invoiceModal.titleEdit') : this.$t('message.invoiceModal.title')
    },
    editMode() {
      return !!this.invoice_id
    },
    isActiveCustomPaymentType() {
      return this.invoice.paymentTypeChosen === 'custom'
    },
    isActiveCustomVat() {
      return this.invoice.hideNameTypeChosen === 'custom'
    },
    paymentFirst() {
      return this.payment?.length ? this.payment[0] : ''
    },
  },
  mounted() {
    if (this.invoice.hideDetails && !this.invoice.hideDetailsVat) {
      this.invoice.hideDetailsVat = this.getDefaultFoodServiceVatSymbol()
    }
  },
  methods: {
    ...mapActions('_orderDetails', ['getInvoiceData', 'getInvoiceById', 'createInvoice', 'editInvoice']),
    async show() {
      this.printingInProcess = true
      this.error = false
      this.orderId = this.cart.id
      this.invoice_id ? await this.getInvoiceById() : await this.getInvoiceData()
      this.sendInvoiceByEmail = false
      this.printInvoice = false
      this.printingInProcess = false
    },
    async acceptAndSendInvoiceByEmail() {
      this.sendInvoiceByEmail = true
      await this.accept()
    },
    async acceptAndPrintInvoice() {
      this.printInvoice = true
      await this.accept()
    },
    async accept() {
      this.error = false
      this.$validator.validateAll().then(async (result) => {
        const isDropdownCustomVatValid =
          this.invoice.hideDetails && this.isActiveCustomVat && this.invoice.hideDetailsVat === ''
        const isDropdownCustomPaymentValid =
          this.isActiveCustomPaymentType && this.invoice.paymentTypeName === '' && this.invoice.customPaymentType === ''

        if (!result || isDropdownCustomVatValid || isDropdownCustomPaymentValid) {
          this.error = true
          return
        }
        this.printingInProcess = true
        if (this.invoice_id) {
          await this.editInvoice({
            send_invoice_by_email: this.sendInvoiceByEmail,
            print_invoice: this.printInvoice,
            onSuccess: this.onSuccess,
            onError: this.onError,
          })
        } else {
          await this.createInvoice({
            send_invoice_by_email: this.sendInvoiceByEmail,
            print_invoice: this.printInvoice,
            onSuccess: this.onSuccess,
            onError: this.onError,
          })
        }
        this.printingInProcess = false
      })
    },
    onSuccess() {
      let message = ''
      if (this.sendInvoiceByEmail) {
        message = 'message.orderDetails.printInvoiceResult.successSendInvoiceByEmail'
      } else if (this.printInvoice) {
        message = 'message.orderDetails.printInvoiceResult.successPrintInvoice'
      }
      this.$toasted.show(this.$t(message), {
        type: 'success',
        icon: {
          name: 'print',
        },
        duration: 3000,
      })
      this.close()
    },
    showCustomInputsAndFocusOn(focusElement) {
      this.invoice.hideDetails = true
      this.invoice.hideNameTypeChosen = 'custom'
      this.invoice.paymentTypeChosen = 'custom'
      // wait for inputs to show
      setTimeout(() => {
        if (focusElement === 'paymentTypeName') this.$refs.paymentTypeNameInput.focus()
        if (focusElement === 'hideNameChosenInput') this.$refs.hideNameChosenInput.focus()
      }, 100)
    },
    getPaymentName(value) {
      return this.payments.find((payment) => payment.value === value)?.name || ''
    },
    keyListener(e) {
      if (e.key === 'Enter') {
        if (!this.printingInProcess) {
          this.acceptAndSendInvoiceByEmail()
        }
      }
    },
    onShown() {
      this.$nextTick(() => {
        this.$refs['sendInvoiceButton'].focus()
        window.addEventListener('keydown', this.keyListener)
      })
    },
    onHidden() {
      window.removeEventListener('keydown', this.keyListener)
    },
    onError(error) {
      this.printingInProcess = false
      let message = ''
      switch (error.response.data.reason) {
        case 'no vatrate':
          message = 'message.orderDetails.printInvoiceResult.noVatrate'
          break
        case 'no order':
          message = 'message.orderDetails.printInvoiceResult.noOrder'
          break
        case 'already exists':
          message = 'message.orderDetails.printInvoiceResult.alreadyExists'
          break
        case 'localization mismatch':
          message = 'message.orderDetails.printInvoiceResult.localizationMismatch'
          break
        default:
          message = error.response.data.reason[0]
          break
      }
      this.$toasted.show(this.$t(message), {
        type: 'error',
        icon: {
          name: 'warning',
        },
        duration: 3000,
      })
    },
    close() {
      this.$bvModal.hide('printInvoice')
    },
    resetCustomPaymentsFields() {
      this.invoice.paymentTypeName = ''
      this.invoice.customPaymentType = this.paymentFirst
    },
    resetCustomVatFields() {
      this.invoice.hideDetailsVat = ''
      this.invoice.hideDetailsVat = this.getDefaultFoodServiceVatSymbol()
    },
    resetHideDetailsFields() {
      this.invoice.hideDetailsVat = ''
      this.invoice.hideNameChosen = ''
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    },
  },
}
</script>
<style lang="scss" scoped>
.error {
  color: $red;
  display: block;
  margin-bottom: 15px;
}
</style>
