import router from '@/router'
import { i18n as t } from '@/utils/i18n'
import { APIAuth } from '@/services/api/apiAuth'
import { API } from '@/services/api/api'
import {
  IAuthSuccessData,
  IAuthState,
  IAuthArgs,
  IAuthErrorMessage,
  IMeArgs,
  IUserMe,
  ICredentials,
} from '@/types/interface'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { rollbar } from '@/plugins/rollbar'
import { posthog } from '@/plugins/posthog'

const signIn = async (
  { commit, dispatch }: ActionContext<IAuthState, RootState>,
  { credentials, onSuccess, onError }: IAuthArgs
) => {
  commit('setSignInRequestStatus', 'loading')
  const { error, data }: { error: any; data: IAuthSuccessData } = await APIAuth.login(credentials)
  if (!error && data) {
    const { role, localizations, key } = data
    const localizationsLength = localizations.length

    if (role === 'courier') {
      if (onError !== null) onError({ non_field_errors: [t.t('message.auth.courierCanNotLogin')] })
      commit('setSignInRequestStatus', 'error')
      return false
    }

    dispatch('setAuthData', data)
    dispatch('flutterCallHandler', { credentials, key })

    if (localizationsLength === 1) {
      await dispatch('getMe', { id: localizations[0].id, onError, onSuccess })
    }
  } else {
    commit('setSignInRequestStatus', 'error')
    if (onError) onError(error?.response?.data)
  }
}

const setAuthData = (
  { commit }: ActionContext<IAuthState, RootState>,
  { key, role, localizations }: IAuthSuccessData
) => {
  API.setAuthToken(key)
  sessionStorage.setItem('authToken', key)
  commit('setAuthToken', key)
  commit('setUserRole', role)
  commit('setLocalizations', localizations)
}

const getMe = async (
  { commit, dispatch, rootGetters }: ActionContext<IAuthState, RootState>,
  { id, onError, onSuccess }: IMeArgs
) => {
  const { error, data }: { error: any; data: IUserMe } = await API.getMe(id)
  if (!error && data) {
    commit('setSignInRequestStatus', 'success')
    if (onSuccess) onSuccess()
    rollbar.configure({
      payload: {
        person: {
          id: data.user,
        },
        localization: {
          id: data.localization?.id,
          name: data.localization?.name,
        },
      },
    })

    posthog.identify(data.user, {
      user: {
        id: data.user,
        role: data.role,
      },
      localization: {
        id: data.localization?.id,
        name: data.localization?.name,
      },
    })

    if (window.Ybug) {
      window.Ybug.setUser({
        user_id: data.user,
        localization_id: data.localization?.id,
        localization_name: data.localization?.name,
      })
    }

    dispatch('setDataInSessionStorage', data)
    dispatch('initStateFromSessionStorage', null, { root: true })
    if (rootGetters['modules/hasModuleAccess']('get-menus')) await dispatch('setMenu', data)
    commit('setHalfPizzaSettings', data.halfable, { root: true })
    dispatch('goToDefaultOrdersList', null, { root: true })
  } else {
    commit('setSignInRequestStatus', 'error')
    if (onError) onError(error?.response?.data)
  }
}

const setDataInSessionStorage = ({ dispatch }: ActionContext<IAuthState, RootState>, data: IUserMe) => {
  const staff = router.currentRoute.query.staff
  sessionStorage.setItem('name', data.name)
  sessionStorage.setItem('localization', JSON.stringify(data.localization))
  sessionStorage.setItem('loggedIn', 'true')
  sessionStorage.setItem('userRole', data.role)
  sessionStorage.setItem('fanoutChannel', data.fanout_channel)
  sessionStorage.setItem('brands', JSON.stringify(data.brands))
  sessionStorage.setItem('companyLocalizations', JSON.stringify(data.company_localizations))
  sessionStorage.setItem('menuSources', JSON.stringify(data.menu_sources))
  sessionStorage.setItem('localizationPreferences', JSON.stringify(data.loc_preferences))
  sessionStorage.setItem('erestoPreferences', JSON.stringify(data.eresto))
  sessionStorage.setItem('localizationPermissions', JSON.stringify(data.loc_permissions))
  sessionStorage.setItem('company', JSON.stringify(data.company))
  sessionStorage.setItem('paymentBackend', data.payment_backend)
  if (staff) sessionStorage.setItem('isPapuStaff', staff.toString())
  dispatch('integrations/saveIntegrations', data.integrations, { root: true })
}

const flutterCallHandler = ({}, { credentials, key }: { credentials: ICredentials; key: string }) => {
  // @ts-ignore
  if (window.flutter_inappwebview?.callHandler) {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('OnLoginFormSubmitted', {
      email: credentials.email,
      password: credentials.password,
      token: key,
    })
  }
}

const setMenu = async ({ dispatch }: ActionContext<IAuthState, RootState>) => {
  await dispatch('getMenus', {}, { root: true })
}

const signUp = async ({}, { credentials, onSuccess, onError }: any) => {
  const { error, data }: { error: any; data: any } = await APIAuth.register(credentials)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
  } else {
    if (onError) onError(error?.response?.data)
  }
}

const logout = async ({ commit, dispatch }: any) => {
  const { error } = await APIAuth.logout({ Authorization: `Token ${sessionStorage.getItem('authToken')}` })
  if (!error) {
    await dispatch('cleanAfterLogout')
    router.push({ name: 'auth', query: { refresh: 'true' } })
    dispatch('resetState', {}, { root: true })
  }
}

const cleanAfterLogout = ({ commit }: any) => {
  const notificationsClosed = sessionStorage.getItem('notificationsClosed') || ''
  API.removeAuthToken()
  commit('setAuthToken', null)
  sessionStorage.clear()
  sessionStorage.setItem('notificationsClosed', notificationsClosed)
  if (posthog?.reset) posthog.reset()
}

const resetPassword = async ({}, { resetData, onSuccess, onError }: any) => {
  const { error, data }: { error: any; data: any } = await APIAuth.resetPassword(resetData)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
  } else {
    if (onError) onError(error?.response)
  }
}

const resetPasswordConfirm = async ({}, { resetConfirmData, onSuccess, onError }: any) => {
  const { error, data }: { error: any; data: any } = await APIAuth.resetPasswordConfirm(resetConfirmData)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
  } else {
    if (onError) onError(error?.response)
  }
}

const createDemo = async ({}, { data, onSuccess, onError }: any) => {
  const { error, data: responseData }: { error: any; data: any } = await APIAuth.createDemo(data)
  if (!error) {
    if (onSuccess) onSuccess(responseData)
  } else {
    if (onError) onError(error?.response)
  }
}

export default {
  signIn,
  signUp,
  logout,
  resetPassword,
  resetPasswordConfirm,
  createDemo,
  cleanAfterLogout,
  getMe,
  setDataInSessionStorage,
  flutterCallHandler,
  setAuthData,
  setMenu,
}
