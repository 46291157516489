<template>
  <b-tr :ref="fieldName" :variant="variant">
    <b-td colspan="2">
      <div class="label-with-tooltip">
        {{ label }}
        <TooltipButton v-if="tooltipText" :id="`tooltip_${fieldName}`" button-class="defaultTooltipButton">
          ?
          <template #tooltipText>{{ tooltipText }}</template>
        </TooltipButton>
      </div>
    </b-td>
    <b-td class="text-center">
      <label class="switch">
        <input :checked="checked" @change="updateValue($event.target.checked)" type="checkbox" />
        <span class="slider round"></span>
      </label>
    </b-td>
  </b-tr>
</template>

<script>
import TooltipButton from '@/components/TooltipButton'

export default {
  name: 'ToggleSettingRow',
  components: {
    TooltipButton,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: undefined,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('change', value)
    },
  },
}
</script>
