<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <div class="container-fluid mt-3">
        <!-- ACTIONS -->
        <div class="settings-section">
          <h3 class="settings-section__title">{{ $t('message.settings.actions') }}</h3>
          <open-drawer-button />
        </div>
        <!-- SECTION -->
        <div class="settings-section">
          <h3 class="settings-section__title">{{ $t('message.settings.management') }}</h3>
          <template v-if="$hasModuleAccess(MODULES.employeeAdd) || $hasModuleAccess(MODULES.employeesList)">
            <h4 class="settings-section__subtitle">{{ $t('message.settings.employees') }}</h4>
            <div class="row">
              <settings-button
                v-if="$hasModuleAccess(MODULES.employeeAdd)"
                link="employee"
                icon="user-plus"
                :text="$t('message.settings.addEmployee')"
              />
              <settings-button
                v-if="$hasModuleAccess(MODULES.employeesList)"
                link="employees-list"
                icon="users"
                :text="$t('message.settings.employeesList')"
              />
            </div>
          </template>
          <h4
            v-if="$hasModuleAccess(MODULES.ordersArchive) || $hasModuleAccess(MODULES.ordersSummary)"
            class="settings-section__subtitle"
          >
            {{ $t('message.settings.orders') }}
          </h4>
          <div class="row">
            <settings-button
              v-if="$hasModuleAccess(MODULES.ordersArchive)"
              link="orders-archive"
              icon="archive"
              :text="$t('message.settings.orderBase')"
            />
            <settings-button
              v-if="$hasModuleAccess(MODULES.ordersSummary)"
              link="orders-summary"
              icon="calculator"
              :text="$t('message.settings.ordersSummary')"
            />
          </div>
        </div>
        <div v-if="$hasModuleAccess(MODULES.billingPeriods)" class="settings-section">
          <h3 class="settings-section__title">{{ $t('message.settings.salaries') }}</h3>
          <div class="row">
            <settings-button
              link="current-billing-periods"
              icon="calendar"
              :text="$t('message.settings.currentBillingPeriods')"
            />
            <settings-button link="billing-periods" icon="calendar-alt" :text="$t('message.settings.billingPeriods')" />
          </div>
        </div>
        <div v-if="$hasModuleAccess(MODULES.shifts)" class="settings-section">
          <h3 class="settings-section__title">{{ $t('message.settings.shifts') }}</h3>
          <div class="row">
            <settings-button link="current-shifts" icon="hourglass-half" :text="$t('message.settings.currentShifts')" />
            <settings-button link="shifts" icon="hourglass" :text="$t('message.settings.shiftsList')" />
          </div>
        </div>
        <template v-if="hasUserRole([ROLE.OWNER, ROLE.MANAGER])">
          <div class="settings-section">
            <h3 class="settings-section__title">{{ $t('message.settings.payments') }}</h3>
            <div class="row">
              <settings-button-external
                external-url="https://admin.papu.io/subscriptions"
                icon="file-invoice-dollar"
                :text="$t('message.settings.subscriptions')"
                :description="$t('message.settings.goToAdmin')"
              />
            </div>
          </div>
          <div class="settings-section">
            <h3 class="settings-section__title">{{ $t('message.settings.management') }}</h3>
            <div class="row">
              <settings-button
                v-if="$hasModuleAccess(MODULES.menuEdit)"
                link="menu-edit"
                icon="file-alt"
                :text="$t('message.menu.menuEdit')"
              />
              <settings-button
                v-if="$hasModuleAccess(MODULES.mapping)"
                link="mapping"
                icon="link"
                :text="$t('message.mapping.mappingMenu')"
              />
              <settings-button
                v-if="$hasModuleAccess(MODULES.mealSets)"
                link="mealsets-list"
                icon="utensils"
                :text="$t('message.mealSets.mealSetsSettings')"
              />
              <settings-button-external
                v-if="$hasModuleAccess(MODULES.discountList)"
                external-url="https://admin.papu.io/discounts"
                icon="tags"
                :text="$t('message.discounts.discountsSettings')"
                :description="$t('message.settings.goToAdmin')"
              />
              <settings-button link="regions" icon="map-marked-alt" :text="$t('message.settings.regions')" />
            </div>
          </div>
          <div v-if="$hasModuleAccess(MODULES.kds)" class="settings-section">
            <h3 class="settings-section__title">KDS</h3>
            <div class="row">
              <settings-button link="workstations-list" icon="tablet-alt" :text="$t('message.workstations.title')" />
              <settings-button link="kitchen-settings" icon="fire" :text="$t('kitchen_settings')" />
            </div>
          </div>
          <div
            v-if="
              $hasModuleAccess(MODULES.administration) ||
              ($hasModuleAccess(MODULES.erestaurant) && erestaurantConfigAvailable()) ||
              $hasModuleAccess(MODULES.receiptPrintersSettings) ||
              ($hasModuleAccess(MODULES.permissions) && hasUserRole([ROLE.OWNER])) ||
              $hasModuleAccess(MODULES.integrations)
            "
            class="settings-section"
          >
            <h3 class="settings-section__title">{{ $t('message.administration.settings') }}</h3>
            <b-row>
              <settings-button
                v-if="$hasModuleAccess(MODULES.administration)"
                link="administration"
                icon="cog"
                :text="$t('message.administration.settings')"
              />
              <settings-button
                v-if="$hasModuleAccess(MODULES.erestaurant) && erestaurantConfigAvailable()"
                link="erestaurant"
                icon="cog"
                :text="$t('message.erestaurant.erestaurant')"
              />
              <settings-button
                v-if="$hasModuleAccess(MODULES.receiptPrintersSettings)"
                link="receipt-printers-list"
                icon="print"
                :text="$t('message.receiptPrintersSettings.name')"
              />
              <settings-button
                v-if="$hasModuleAccess(MODULES.permissions) && hasUserRole([ROLE.OWNER])"
                link="permissions"
                icon="user-lock"
                :text="$t('message.permissions.permissionsSettings')"
              />
              <!-- <settings-button
                v-if="$hasModuleAccess(MODULES.integrations)"
                link="integrations"
                icon="sitemap"
                :text="$t('message.settings.integrations')"
              /> -->
            </b-row>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsButton from '@/components/SettingsButton'
import SettingsButtonExternal from '@/components/SettingsButtonExternal'
import { mapGetters } from 'vuex'
import { ROLE } from '@/common/constants'
import OpenDrawerButton from '@/components/OpenDrawerButton.vue'

export default {
  name: 'Settings',
  components: {
    OpenDrawerButton,
    SettingsButton,
    SettingsButtonExternal,
  },
  data() {
    return { ROLE }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
    }),
  },
  methods: {
    erestaurantConfigAvailable() {
      return sessionStorage.getItem('erestoPreferences') !== 'null'
    },
  },
}
</script>
