<template>
  <div :class="['cart', { 'h-100': !full }]">
    <cart-meals
      v-if="showMeals"
      :order-details="orderDetails"
      :is-order-form-full="full"
      :edit-meal-button-is-active="editMealButtonIsActive"
      :show-additional-fees-header="showAdditionalFeesHeader"
      :cart="cart"
      :order="order"
      :total-price="totalPrice"
      :from-order-details-modal="fromOrderDetailsModal"
      :order-brand-id="orderBrandId"
      :disabled-mapping="disabledMapping"
      @closeModal="$emit('closeModal')"
    />
    <div v-if="!full" :class="['cart-full-price', { 'cart-full-price--on-site': isOrderOnSite }]">
      <span class="cart-full-price__title">{{ $t('message.mealDetails.totalPrice') }}:</span>
      <h2 class="cart-full-price__value">{{ priceDecimal(totalPrice) }} {{ currency }}</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import store from '@/views/order-edit/_store'
import { PICKUP_TYPE_DELIVERY } from '@/common/constants'
import { responseErrorsHandler, mealsMixins } from '@/mixins'
import CartMeals from './CartMeals'

const STORE = '_orderEdit'

export default {
  name: 'Cart',
  components: {
    CartMeals,
  },
  mixins: [responseErrorsHandler, mealsMixins],
  props: [
    'full',
    'orderDetails',
    'cart',
    'orderSource',
    'orderStatus',
    'orderBrandId',
    'order',
    'isOrderOnSite',
    'fromOrderDetailsModal',
    'orderBills',
    'orderAllBillsLength',
    'disabledMapping',
  ],
  data() {
    return {
      PICKUP_TYPE_DELIVERY,
    }
  },
  computed: {
    ...mapGetters(STORE, [
      'isEdit',
      'isAnyMealDiscountOrPromo',
      'getServiceChargePercent',
      'cartTotalPrice',
      'orderTotalPrice',
    ]),
    ...mapGetters(['currency', 'menus', 'menu']),
    ...mapFields(STORE, {
      // Plugin do mutowania stanu w store
      customerPhone: 'cart.customer.phone',
      customerAddress: 'cart.customer.address',
      customerName: 'cart.customer.name',
      customerPickupType: 'cart.customer.pickupType',
      customerPaymentType: 'cart.customer.paymentType',
      orderTip: 'order.tip',
    }),
    totalPrice() {
      return this.orderDetails ? this.cart.totalPrice : this.orderTotalPrice
    },
    editMealButtonIsActive() {
      return (
        this.orderStatus !== 'finished' &&
        (!this.orderDetails || (this.orderDetails && this.orderAllBillsLength > 0)) &&
        this.$hasModuleAccess('edit-order')
      )
    },
    disableDiscountCode() {
      return (
        this.cart.discountCodeApplied ||
        !!this.cart.discountPercent ||
        !!this.cart.promotionalPrice ||
        this.isAnyMealDiscountOrPromo
      )
    },
    showMeals() {
      return !this.isOrderOnSite
    },
    showAdditionalFeesHeader() {
      return (
        (this.cart.serviceChargeValue && this.cart.serviceChargeValue !== '0.00') ||
        (this.order?.tip && this.order?.tip !== '0.00') ||
        (this.cart.glovoServiceCharge && this.cart.glovoServiceCharge !== '0.00') ||
        this.cart.deliveryPrice ||
        (this.cart.packagePrice && this.cart.packagePrice !== '0.00') ||
        this.cart.discount ||
        (this.cart.discount_value && this.cart.discount_value !== '0.00')
      )
    },
  },
  beforeCreate() {
    if (!this.$store.state['_orderEdit']) this.$store.registerModule('_orderEdit', store)
  },
  methods: {
    editOrder() {
      this.$router.push({ name: 'edit-order', params: { id: this.order.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.cart {
  margin: 0 -15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.cart-full-price {
  width: 100%;
  padding: 10px;
  border-top: 1px dashed $gray-400;
  margin-top: auto;
  position: relative;

  &__title {
    position: absolute;
    top: 5px;
    background-color: white;
    padding: 0 4px;
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }

  &__value {
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
  }

  &--on-site {
    border-bottom: 1px dashed $gray-400;
    margin-bottom: 20px;
  }
}

.discountCodePrepend {
  button:disabled {
    opacity: 1;
    background-color: #24d17e !important;
    cursor: default;
  }
}

.comment-display {
  white-space: pre-line;

  .alert {
    padding: 1.5em 3em;
  }
}
</style>
