import { getField, updateField } from 'vuex-map-fields'
import { INotification } from '@/types/interface'
import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { NOTIFICATION } from '@/common/constants'

interface INotificationsState {
  notifications: INotification[]
  unreadNotifications: INotification[]
  isNotificationListOpen: boolean
}

const getInitialState = (): INotificationsState => {
  return {
    notifications: [], //[],
    unreadNotifications: [], //[],
    isNotificationListOpen: false,
  }
}

// GETTERS

const hasSubscriptionEndsNotification = (state: INotificationsState): INotification | undefined => {
  return state.notifications.find(
    (n: { type: number }) =>
      n.type === NOTIFICATION.SUBSCRIPTION_ENDS.type || n.type === NOTIFICATION.PURCHASED_PLAN_FINISHED.type
  )
}

// MUTATIONS

const resetState = (state: INotificationsState) => {
  Object.assign(state, getInitialState())
}

const setNotifications = (state: INotificationsState, notifications: INotification[]) => {
  state.notifications = notifications
}

const setUnreadNotifications = (state: INotificationsState, notifications: INotification[]) => {
  state.unreadNotifications = notifications
}

const openNotificationList = (state: INotificationsState) => {
  state.isNotificationListOpen = true
}

const closeNotificationList = (state: INotificationsState) => {
  state.isNotificationListOpen = false
}

const temporaryRemoveNotification = (state: INotificationsState, notification: INotification) => {
  state.notifications = state.notifications.filter((n: INotification) => n.pk !== notification.pk)
  state.unreadNotifications = state.unreadNotifications.filter((n: INotification) => n.pk !== notification.pk)
}

// ACTIONS

const getNotifications = async ({ commit, dispatch }: ActionContext<INotificationsState, RootState>) => {
  const { error, data }: { data: any; error: any } = await API.getNotifications()
  if (!error) {
    let unreadNotifications = []

    if (data && data.length > 0) {
      unreadNotifications = data
      const notficicationsClosedStorage = sessionStorage.getItem('notificationsClosed')
      const notificationsClosed = notficicationsClosedStorage ? JSON.parse(notficicationsClosedStorage) : []
      unreadNotifications = unreadNotifications.filter((n: { pk: any }) => notificationsClosed.indexOf(n.pk) === -1)
      dispatch('handleRegeocodeNotifications', unreadNotifications)
    } else {
      sessionStorage.setItem('notificationsClosed', JSON.stringify([]))
    }

    commit('setUnreadNotifications', unreadNotifications)
    commit('setNotifications', data || [])
  }
}

const handleRegeocodeNotifications = (
  { commit, dispatch }: ActionContext<INotificationsState, RootState>,
  notifications: any[]
) => {
  const regeocodeNotifs = notifications.filter((notif: { type: number }) => notif.type === NOTIFICATION.REGEOCODE.type)
  if (regeocodeNotifs.length > 0) {
    dispatch('_orders/getOrders', { filters: { archive: false } })
    let orderIdsToUnlock: any[] = []
    regeocodeNotifs.forEach((notif: { data: { order_ids: any } }) => {
      if (notif?.data?.order_ids) {
        orderIdsToUnlock = [...orderIdsToUnlock, ...notif.data.order_ids]
      }
    })
    commit('_orders/removeOrdersInRegeocodeByIds', orderIdsToUnlock)
  }
}

const deleteNotification = async (
  { commit, dispatch }: ActionContext<INotificationsState, RootState>,
  notification: INotification
) => {
  if (notification.closable) {
    const res = await API.deleteNotification(notification.pk)
    if (res.response.status === 204) {
      commit('temporaryRemoveNotification', notification)
      dispatch('getNotifications')
    }
  }
}

const closeNotification = async (
  { dispatch, commit, state }: ActionContext<INotificationsState, RootState>,
  notification: INotification
) => {
  const notificationsClosed = sessionStorage.getItem('notificationsClosed')
  const notificationsPrevClosed = notificationsClosed ? JSON.parse(notificationsClosed) : []

  sessionStorage.setItem('notificationsClosed', JSON.stringify([notification.pk].concat(notificationsPrevClosed)))

  const newUnreadNotifications = state.unreadNotifications.filter((n: INotification) => n.pk !== notification.pk)
  commit('setUnreadNotifications', newUnreadNotifications)
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getField,
    hasSubscriptionEndsNotification,
  },
  mutations: {
    updateField,
    setNotifications,
    setUnreadNotifications,
    resetState,
    openNotificationList,
    closeNotificationList,
    temporaryRemoveNotification,
  },
  actions: {
    getNotifications,
    handleRegeocodeNotifications,
    deleteNotification,
    closeNotification,
  },
}
