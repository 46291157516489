<template>
  <div v-if="notification" class="notification-subscription-ends">
    <div class="notification-subscription-ends-icon">
      <i class="fas fa-calendar-times" />
    </div>

    <div>
      <div class="notification-subscription-ends__title">{{ $t(`notificationTypes.subscription-ends.title`) }}!</div>

      <div class="notification-subscription-ends__content">
        {{ notification.content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationSubscriptionEnds',
  computed: {
    notification() {
      return this.$store.getters['notifications/hasSubscriptionEndsNotification']
    },
  },
}
</script>
<style lang="scss">
.notification-subscription-ends {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  background-color: #fd4a4a;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &-icon {
    font-size: 24px;
    color: #9e9e9e;
    margin-right: 24px;
    i {
      color: #fff;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
  }

  &__content {
    line-height: 130%;
  }
}

@media (min-width: 1280px) {
  .notification-subscription-ends {
    border-radius: 4px;
    margin-bottom: 12px;
  }
}
</style>
