export const de = {
  message: {
    demo: {
      company: 'Unternehmen',
      localization: 'Lokalisierung',
      createAccount: 'Benutzerkonto erstellen',
      thankYou: 'Vielen Dank!',
      successMessage:
        'Eine Nachricht mit weiteren Details wird innerhalb von 5 Minuten an die angegebene E-Mail-Adresse gesendet. Schauen Sie in der Zwischenzeit in unserer Wissensdatenbank nach:',
      errorMessage: 'Bitte korrigieren Sie das Formular und versuchen Sie es erneut.',
      nip: 'UstID',
      company_email: 'E-Mail-Addresse',
      menu_url: 'Menü-URL (Lieferando.de)',
      name: 'Lokalisierungsname',
      city: 'Stadt',
      address: 'Adresse',
      phone: 'Telefonnummer',
      localization_email: 'E-Mail-Addresse',
      fineprint:
        'Der Verantwortliche für Ihre personenbezogenen Daten ist PapuKurier sp. z o.o. mit Sitz in Poznań, ul. Sowińskiego 18A, die Sie unter kontakt@papu.io kontaktieren können. Ihre personenbezogenen Daten werden verarbeitet, um mit Ihnen Kontakt aufzunehmen, insbesondere um Ihnen unser kommerzielles Angebot zu präsentieren. Im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten haben Sie das Recht auf Auskunft über Ihre personenbezogenen Daten, deren Berichtigung, Löschung, Einschränkung der Verarbeitung und Übertragung sowie auf Einlegung eines Widerspruchs und auf Beschwerde bei der Aufsichtsbehörde. Einzelheiten dazu finden Sie in der <a href="https://papu.io/de/datenschutzerklarung/" target="_blank">Datenschutzerklärung</a>.',
      phoneCalls: 'Ich erteile meine Zustimmung für eine Kontaktaufnahme mittels E-Mail und Telefonaruf.',
      pl: 'Polen',
      uk: 'Vereinigtes Königreich',
      de: 'Germany',
      country: 'Deutschland',
      street: 'Straße',
      house: 'Wohnung',
      kitchenType: 'Küchentyp',
      kitchenTypeOptions: {
        1: 'American',
        2: 'Bistro',
        3: 'Burger',
        4: 'Indian',
        5: 'Kebab',
        6: 'Mix house',
        7: 'Pizza & Kebab',
        8: 'Pizzeria',
        9: 'Ramen',
        10: 'Steak house',
        11: 'Sushi',
        12: 'Thai',
        13: 'Turkish',
        14: 'Vege',
        15: 'Italian',
      },
      logo: 'Logo',
    },
    common: {
      reset: 'Zurücksetzen',
      pickTime: 'Wähle eine Zeit',
      weekTimeTable: {
        bothTimeFieldsMustBeFilled: 'Beide Zeitfelder für einen bestimmten Tag müssen ausgefüllt werden.',
      },
      cancel: 'Abbrechen',
      edit: 'Bearbeiten',
      save: 'Speichern',
      delete: 'Entfernen',
      savingWait: 'Speichern. Bitte warten...',
      default: 'Standard',
      continue: 'Fortsetzen',
      selectFromList: 'Wähle aus der Liste',
      other: 'Andere',
      confirm: 'Bestätigen',
      cash: 'Bar',
      card: 'Bankkarte',
      paid: 'Bezahlt',
      end: 'Ende',
      bill: 'Rechnung',
      all: 'Alle',
      printAgain: 'Bitte versuche es erneut',
      enterCash: 'Geben Sie Bargeld ein',
      price: 'Preis',
      rest: 'Rückstand',
      cashCalculator: 'Bargeldrechner',
      order: 'Bestellung',
      move: 'Transfer',
      paymentMethod: 'Zahlungsart',
      or: 'Oder',
      anErrorOccured: 'Es ist ein Fehler aufgetreten',
      discardChangesQuestion: 'Möchten Sie nicht gespeicherte Änderungen verwerfen?',
      discardChanges: 'Änderungen verwerfen',
      stayOnPage: 'Bleib auf der Seite',
      back: 'Zurück zur vorherigen Seite',
      none: 'Keiner',
      noData: 'Daten nicht verfügbar',
      close: 'Schließen',
      menu: 'Menü',
      saving: 'Verarbeitung',
      source: 'Source',
      sourceMenu: 'Menu source',
      name: 'Name',
      size: 'Size',
      group: 'Group',
      category: 'Category',
      errorOccured: 'Error',
      color: 'Farbe',
      user: 'Nutzer',
      menuDefault: 'Standard Menu',
      choose: 'Choose',
    },
    auth: {
      signIn: 'Anmelden',
      signUp: 'Registrierenmmmm',
      email: 'Email-Adresse',
      password: 'Passwort',
      confirmPassword: 'Passwort bestätigen',
      signInButton: 'Anmelden',
      signUpButton: 'Registrierenmmmm',
      username: 'Benutzername',
      courierCanNotLogin: 'Kuriere dürfen sich nicht bei PapuPOS anmelden!',
      appDownload: 'Laden Sie die Kurier-App Papu.io herunter',
      loggedOutInvalidToken:
        "Sie wurden abgemeldet (wahrscheinlich durch Klicken auf die Schaltfläche 'Abmelden' auf einem anderen Gerät).",
      forgotPassword: 'Passwort vergessen?',
      passwordReset: 'Setze dein Passwort zurück',
      send: 'Senden',
      setNewPassword: 'Neues Passwort setzen',
      resetLinkExpired:
        'Der Link zum Zurücksetzen des Passworts ist abgelaufen. Sie können Ihr Passwort wieder zurücksetzen.',
      canSignIn: 'Sie können sich jetzt anmelden.',
      selectLocalization: 'Wählen Sie eine Lokalisierung',
      loadingMenu: 'Menü herunterladen',
    },
    employeeForm: {
      employeeType: 'Vertragsverhältnis',
      employeeTypes: { waiter: 'Kellner', courier: 'Fahrer', manager: 'Manager', owner: 'Inhaber', cook: 'Koch' },
      employeeName: 'Name des Mitarbeiters',
      phoneNumber: 'Telefonnummer',
      email: 'Email-Adresse',
      password: 'Passwort',
      confirmPassword: 'Passwort best��tigen',
      isActive: 'Ist Aktiviert',
      addUserButton: 'Benutzer hinzufügen',
      saveChangesButton: 'Änderungen speichern',
      courierType: 'Fahrer',
      courierTypes: { core: 'Basic', uber: 'Austausch' },
      courierTransportMethod: 'Fahrzeugtyp',
      courierTransportMethods: { car: 'Auto', motorbike: 'Motorrad', bike: 'Fahrrad' },
      sendApp: 'App senden',
      appSent: 'wurde versendet',
      appInfo:
        'Um die Fahrerapp herunterladen zu können gehe bitte auf die Hauptseite https://rest.papu.io und klicke auf den jeweiligen Store (AppStore/Google Play Store) oder suche alternativ auf dem Handy nach der PAPU.io-App.',
      salaryPerKm: 'Gehalt pro km',
      salaryPerHour: 'Gehalt pro Stunde',
      salaryPerDelivery: 'Gehalt pro Lieferung',
      salaryPerZoneDelivery: 'Gehalt pro Zonenlieferung',
      verifyEmailMessage:
        'A verification link has been sent to the e-mail address provided. Click on it and confirm the e-mail address in the ADMIN PAPU.io application.',
      updateEmployeeSuccess: 'The user has been saved.',
    },
    employeeList: {
      showOnlyActive: 'Nur aktive Mitarbeiter anzeigen',
      showAllEmployees: 'Alle Mitarbeiter anzeigen',
      name: 'Vollst��ndiger Name',
      username: 'Benutzername',
      phone: 'Telefon',
      active: 'Aktiv',
      type: 'Typ',
      edit: 'Bearbeiten',
      yes: 'Ja',
      no: 'Nein',
      courier: 'Fahrer',
      waiter: 'Kellner',
      manager: 'Manager',
      owner: 'Inhaber',
      loggedIn: 'Eingeloggt',
      remove: 'Entfernen',
      removeEmployee: 'Mitarbeiter entfernen',
      areYouSure: 'Sind Sie sicher, dass Sie einen Mitarbeiter entfernen möchten?',
    },
    orderDetails: {
      orderNumber: 'Anzahl',
      orderSource: 'Quelle',
      orderStatus: 'Status',
      customerDetails: 'Kundendetails',
      customerData: 'Kundendetails',
      orderContent: 'Bestellung',
      edit: 'Bearbeiten',
      close: 'Schließen',
      changeOrderLocalization: 'Bestellort ändern',
      changeOrderLocalizationInfo: 'Senden Sie die Bestellung an ein anderes Restaurant',
      changeOrderLocalizationDescription:
        'Die Bestellung verschwindet aus der Liste der aktuellen Bestellungen und ' +
        'ist nur für die ausgewählte Lokalisierung sichtbar',
      transferedFrom: 'Zamówienie przesłane z',
      transferOrder: 'Prześlij zamówienie',
      finishOrderButton: 'Abschließen',
      finishOrder: 'Bestellung Abschließen',
      printingError: 'Fehler aufgetreten',
      printingErrorTryAgain: 'Bitte versuche es erneut',
      printOrderConfirmation: { btn: 'Küchenbon', success: 'Die Bestätigung der Druckreihenfolge wird ausgeführt' },
      printBill: 'Kassenbon',
      closeOrderOnPrintBill: 'Bestellung automatisch schließen',
      finishOrderChoices: {
        canceled: 'Storniert',
        delivered: 'Geliefert',
        notDelivered: 'Nicht ausgeliefert',
        pickedUp: 'Abgeholt',
        serviceCharge: 'Bearbeitungsgebühr',
        gloVoServiceCharge: 'Glovo Bearbeitungsgebühr',
        tip: 'Trinkgeld',
      },
      accept: 'Akzeptieren',
      acceptFor: 'Akzeptieren für',
      acceptInfo: 'Diese Bestellung kann von Ihrem Restaurant ausgeführt werden',
      decline: 'Ablehnen',
      declineTransferInfo: 'Diese Bestellung wird an das Restaurant {oldLocalizationName} zurückgeschickt',
      changePaymentMethod: 'Zahlungsart ändern',
      changeFinishOrderButton: 'Ändern',
      selectPromisedTime: 'Wählen Sie die dem Kunden versprochene Zeit aus',
      changeOrAcceptPromisedTime: 'Ändern oder Akzeptieren Sie die dem Kunden versprochene Zeit',
      billAlreadyPrinted: 'Diese Quittung wurde bereits gedruckt',
      printInvoice: 'Bewirtungsbeleg',
      restoreOrder: 'Wiederherstellen',
      orderRestored: 'Die Bestellung ist wiederhergestellt',
      printInvoiceResult: {
        noVatrate: 'Mehrwertsteuersätze nicht festgelegt',
        noOrder: 'Keine solche Bestellung',
        alreadyExists: 'Rechnungsnummer ist bereits vorhanden.',
        unknownError: 'Unbekannter Fehler',
        localizationMismatch: 'Lokalisierungen stimmen nicht überein',
        successPrintInvoice: 'Rechnungsdruck läuft',
        successSendInvoiceByEmail: 'Rechnung per E-Mail verschickt',
        sendInvoiceByEmail: 'Rechnung per E-Mail verschicken',
        printInvoice: 'Rechnung drucken',
      },
      paymentTypeNotSet: 'Zahlungsart nicht festgelegt',
      paymentTypeUpdateError: 'Beim Ändern der Zahlungsart ist ein Fehler aufgetreten',
      tableNumberUpdateError: 'Beim Ändern der Tabellennummer ist ein Problem aufgetreten',

      paymentTypeChanged: 'Zahlungsart geändert',
      tableNumberChanged: 'Die Tabellennummer wurde geändert',
      orderNotAccepted:
        'Um diese Bestellung anzunehmen, klicken Sie unten auf Akzeptieren (oder wählen Sie die versprochene Zeit) und klicken Sie anschließend auf OK. Die Bestellung wird in der Bestellliste angezeigt. Für einen Moment (bis zu 30 Sekunden) werden bei der Bestellung keine Daten ausgefüllt, aber keine Sorge - sie wird automatisch verarbeitet.',
      table: {
        nameTable: 'Tabelle',
        missingTable: 'Kein Tisch',
      },
      today: 'Heute',
    },
    mealDetails: {
      chooseSize: 'Größe wählen.',
      chooseAdditives: 'Zusätzliche Zutaten',
      discount: 'Rabatt',
      valueDiscount: 'Rabatt in EUR',
      promotionalPrice: 'Aktionspreis €',
      quantity: 'Menge',
      addComment: 'Kommentar hinzufügen',
      splitBill: 'Getrennte Rechung',
      main: 'Hauptrechnung',
      billNo: 'Rechnungsnummer',
      createNew: 'Neue erstellen',
      totalPrice: 'Gesamtpreis',
      add: 'Hinzufügen',
      save: 'Speichern',
      delete: 'Löschen',
      price: 'Preis',
      changeHalfPizza: 'Pizza auswählen',
      changeMeal: 'Gericht ändern',
      finalView: 'Zusammenfassung',
      discountDisabled: 'Rabatt nicht verfügbar.',
      discountDisabledInfo:
        'Der Bestellrabatt ist aufgrund des bei der Bestellung festgelegten Rabatts nicht verfügbar.',
      selectionRequired: 'Auswahl erforderlich',
      mealRemovedFromMenu:
        'Das Essen wurde aus dem Menü entfernt. Alles, was Sie mit dieser Mahlzeit tun können, ist, ' +
        'sie aus der Bestellung zu entfernen.',
      left: 'Linke Seite',
      right: 'Rechte Seite',
      chooseMeal: 'Wählen Sie eine Mahlzeit',
      none: 'Keiner',
      submealsNotFilled: 'Wählen Sie alle Mahlzeiten des Menüs',
    },
    mealsMenu: {
      search: 'Suche',
      mealsList: 'Liste der Gerichte',
    },
    categories: {
      mealSets: 'MENÜS',
    },
    mealsModal: {
      title: 'Wählen Sie die Pizzahälfte aus',
    },
    multiChoiceEntryModal: {
      search: 'Suche',
      ingredients: 'Zutaten',
      selectedAdditives: 'Ausgewählte Zutaten',
      paidAdditives: 'Inhaltsstoffe',
      freeAdditives: 'Inhaltsstoffe',
      save: 'Speichern',
      additives: 'Zusätzliche Zutaten',
      noIngredients: 'Keine Zutaten',
      noAdditives: 'Keine Extras',
      noAdditivesToChoose: 'Keine Add-Ons zur Auswahl',
      noIngredientsToChoose: 'Keine Zutaten zur Auswahl',
      deletedIngredients: 'Gelöschte Zutaten',
    },
    cart: {
      title: 'Vor Ort bestellen',
      name: 'Name',
      quantity: 'Menge',
      unitPrice: 'Einzelpreis',
      discountLabel: 'Rabatt',
      discountValue: 'Rabatt in EUR',
      cart_percent_discount: 'Preisnachlass',
      cart_promotional_price: 'Sonderpreis',
      percent: 'Preisnachlass',
      value: 'Rabatt in EUR',
      promotionalPrice: 'Sonderpreis',
      freeDelivery: 'Kostenlose Lieferung',
      discount: 'Rabatt',
      sum: 'Summe',
      edit: 'Bearbeiten',
      totalPrice: 'Gesamtpreis',
      totalBillPrice: 'Rechnung gedruckt mit Wert',
      deliveryPrice: 'Lieferpreis',
      deliveryPriceIncluded: 'Inkl. Lieferkosten',
      comment: 'Kommentare',
      vat: 'MwSt.',
      vatExempt: 'Steuerfrei',
      none: 'Keiner',
      discount_all: 'Summe der Rabatte',
      deliveryArea: 'Llefergeblet',
      actualMeal: 'Aktuelles Gericht',
      discountDisabled: 'Rabatt nicht verfügbar.',
      discountDisabledInfo: 'Der Bestellrabatt ist nicht verfügbar, da für 1 Mahlzeit ein Rabatt festgelegt wurde.',
      discountCode: 'Rabattcode',
      applyCode: 'Code verwenden',
      tableNumber: 'Tisch Nummer',
      differentTableNumber: 'Bitte geben Sie eine andere Nummer ein',
      printBill: 'Rechnung drucken',
      closeOrder: 'Schließen Sie die Bestellung',
      paymentMethod: 'Zahlungsmethode',
      releaseType: 'Freigabetyp',
      insertCash: 'Geben Sie Bargeld ein',
      price: 'Preis',
      change: 'Rückstand',
      noMeals: 'Warenkorb leer',
      mainBill: 'Hauptrechnung',
      tip: 'Trinkgeld',
      packagePriceIncluded: 'Paketpreis',
      packagePrice: 'Paketpreis',
      additionalFees: 'Zusätzliche Gebühren',
      orderContent: 'Inhalt bestellen',
      addMeal: 'Gericht hinzufügen',
    },
    orderFormFull: {
      customerDetails: 'Kundendetails',
      phoneNumber: 'Telefonnummer',
      address: 'Adresse',
      name: 'Name',
      customerTaxID: 'Steuernummer',
      pickupType: 'Typ des Heranholens',
      pickupTypes: {
        onSite: 'vor Ort',
        takeaway: 'Zum Mitnehmen',
        delivery: 'Lieferung',
      },
      deliveryTypes: {
        asap: 'Schnellstmöglichst',
        onTime: 'Versprochene Lieferzeit',
      },
      paymentType: 'Zahlungsart',
      paymentTypes: {
        cash: 'Bar',
        card: 'Bankkarte',
        paid: 'Bezahlt',
      },
      promisedTime: 'Versprochene Lieferzeit',
      placeOrder: 'Bestellung bestätigen',
      updateOrder: 'Bestellung aktualisieren',
      deliveryType: 'Versandart',
      emptyCart: 'Warenkorb ist leer, bitte einige Mahlzeiten hinzufügen.',
      minValue: 'Minimaler Bestellwert fehlt',
      minValueFrom: 'Kostenlose Lieferung',
      noDeliveryArea:
        'Keine definierten Lieferbereiche. Bitte melden Sie sich im Manager-Konto an und fügen Sie Lieferbereiche und Werte in den Einstellungen hinzu.',
      outOfDeliveryArea: 'Adresse außerhalb des Liefergebiets',
      orderSource: 'Quelle der Bestellung',
      choose: 'Wählen',
      changeOrderSource: 'Bestellquelle ändern',
      printBill: 'Rechnungsausdruck',
      addressComment: 'Adresskommentar',
      removeAddressConfirm: 'Möchten Sie die Adresse dieses Kunden wirklich entfernen?',
      yes: 'Ja',
      no: 'Nein',
      deliveryPriceFromArea: 'Lieferpreis festlegen',
      useNewAreaDeliveryPrice:
        'Möchten Sie den Lieferpreis mit dem Lieferpreis {calculatedDeliveryPrice} aus dem neuen Bereich überschreiben?',
      deliveryAreaChanged: 'Lieferbereich wurde geändert',
      deliveryPriceChangeDueToCart:
        'Für diesen Wert und diese Bestelladresse beträgt der aus den angegebenen ' +
        'Lieferbereichen resultierende Lieferbetrag {deliveryPrice}. Setzen Sie den Lieferbetrag in dieser Bestellung ' +
        'auf {deliveryPrice}?',
      payment: 'Zahlungen',
      newOrder: 'Neue Bestellung',
    },
    orderFormShort: {
      number: 'Nummer',
      customer: 'Kunde',
      address: 'Adresse',
      changeDetails: 'Bestellung aufnehmen',
      addOrderOnSite: 'Bestellung vor Ort hinzufügen',
      order: 'Bestellung',
      new: 'Neu',
    },
    promisedTime: {
      ownPromisedTime: 'Eigene',
      recommendedByAi:
        'Unsere künstliche Intelligenz hat die folgende Lieferzeit vorhergesagt. ' +
        'Wenn Sie dies nicht akzeptieren, wählen Sie manuell eine andere Zeit.',
      futurePromisedTimeInfo:
        'Bestellungen mit einer versprochenen Zeit von mehr als 24 Stunden ab ' +
        'dem tatsächlichen Zeitpunkt werden in der Liste der aktuellen Bestellungen nicht angezeigt',
      predictingPromisedTime: 'Vorhersagen die Lieferzeit',
    },
    futureDateConfirmModal: {
      chosenDateBigger: 'Die ausgewählte Zeit beträgt mehr als 12 Stunden ab der aktuellen Zeit',
      hosenDateBiggerInfo:
        'Sie haben {die dateType} auf den {dateTimeDisplay} festgelegt - wir sind {hoursToDate} Stunden ' +
        'von diesem Moment entfernt. Bist du sicher?',
      promisedTime: 'die versprochene Zeit',
      shiftFinishedAt: 'das Schicht Enddatum',
    },
    assignCourier: {
      assignCourierToOrder: 'Weisen Sie der Bestellung einen Fahrer zu',
      assignCourierToOrders: 'Fahrer zuweisen',
      none: 'Kurier entfernen',
      emptyCouriersList: 'Es sind keine Kuriere angemeldet',
    },
    ordersMap: {
      clearSelectedOrders: 'Deaktivieren Sie alle Bestellungen',
      selectOrders: 'Wählen Sie alle Bestellungen aus',
      assignCourierToOrders: 'Fahrer zuweisen',
      regeocodeOrders: 'Neu lokalisieren',
    },
    ordersList: {
      search: 'Suche',
      searchInfo:
        'Suche - Geben Sie Namen der Mahlzeit, Bestellnummer, Preis, Adresse, Telefonummer oder Kundennamen ein',
      unaccepted: 'Unbestätigte',
      todo: 'Aufgabenliste',
      deliveringIn: 'In Lieferung',
      futureOrders: 'Künftig',
      remainingPromisedTime: 'Verbleibende Zeit',
      address: 'Adresse',
      payment: 'Zahlungen',
      meals: 'Mahlzeiten',
      courier: 'Kurierdienst',
      status: 'Status',
      source: 'Quellen',
      assignCourier: 'Kurier zuweisen',
      cantAssignCourierNotAllMealsReady: 'Sie können keinen Kurier zuweisen, wenn nicht alle Mahlzeiten fertig sind',
      price: 'Preis',
      priceTooltip: 'Warenpreis + Lieferpreis = Bestellpreis',
      onSite: 'vor Ort',
      takeaway: 'zum Mitnehmen',
      delivery: 'Lieferung',
      filterByEmployee: 'Angstellter',
      filterByPaymentMethod: 'Zahlungsmethoden',
      createdAt: 'Erstellt am',
      finishedAt: 'Beendet am',
      promisedTime: 'Versprochene Lieferzeit',
      orderStatus: 'Bestellstatus',
      statusFinished: 'Abgeschlossen',
      statusCurrent: 'Andauernd',
      none: 'Keine',
      finishedStatus: 'Beendet',
      all: 'Alle',
      noAddress: 'Adresse wurde nicht erkannt - Reihenfolge ist auf der Karte nicht sichtbar!',
      restaurants: 'Alle Restaurants',
      restaurant: 'Restaurant',
      total: 'Insgesamt',
      for: 'Aufträge zu',
      discountsFor: 'Rabatte für',
      billValueHigherInOrders: 'Rechnungswerte höher als der Bestellwert um',
      noOrders: 'Keine Bestellungen für ausgewählte Filter.',
      discountFilter: 'Nur mit Rabatt',
      billValueHigherFilter: 'Nur bei Rechnungswert höher als Bestellpreis',

      discount: 'Rabatt',
      uberDiscountFilter: 'Nur mit UberEats Rabatt (Rabatte werden ab dem 06.05.2021 mitgerechnet)',
      uberDiscountFor: 'Alle Rabatte von UberEats',
      onTime: 'Pünktlich bestellen.',
      printed: 'Rechnung gedruckt.',
      orderBillValuesMismatch: 'Der Preis dieser Bestellung ist niedriger als der Wert der Rechnungen.',

      customerPhone: 'Kontakt-Telefonnummer',
      filterThroughDateType: 'Bestellungen filtern',
      browserPreventsSoundNotifications:
        'Der Browser hat die automatische Wiedergabe des Mediums verhindert. Bitte klicken Sie auf "PLAY", um das Medium zu starten.',
      browserPreventsSoundNotificationsActionText: 'PLAY',
      filterByIsPrinted: 'Kassenbeleg',
      filterByHasInvoice: 'Bewirtungsbeleg',
      isTransferred: 'Bestellung übertragen',
      yes: 'Ja',
      no: 'Nein',
      isPrinted: 'Gedruckt',
      isNotPrinted: 'Nicht gedruckt',
      tipsFilter: 'Nur mit Trinkgeld',
      tips: 'Trinkgeld',
      tipsFor: 'Trinkgeld für',
      splittedBills: 'Gesplittete Rechnungen',
      filters: 'Filter',
      showFilters: 'Filter anzeigen',
      hideFilters: 'Filter ausblenden',
    },
    summary: {
      filterFromDate: 'Ab',
      filterToDate: 'Bis',
      filterByEmployee: 'Nach Mitarbeiter filtern',
      name: 'Name',
      role: 'Rolle',
      paidByCash: 'Bar bezahlt',
      paidByCard: 'per Karte bezahlt',
      alreadyPaid: 'Bereits bezahlt',
      unrealized: 'Storniert',
      realized: 'Realisiert',
      ordersNumber: 'Alle',
      discounts: 'Rabatte',
      deliveries: 'Für Lieferungen',
      total: 'Insgesamt',
      inProgress: 'im Gange',
      uberDiscount: 'Rabatte von UberEats',
      uberDiscountTooltip:
        'Diese Summe sollte UberEats an das Restaurant zurückzahlen (gilt für Bestellungen jeden Status in Papu)',
      uberDiscountStartDateWarning: 'UberEats Rabatte sind ab dem 05.06.2021 berechnet',
      tips: 'Trinkgeld',
      canceled: 'Annulliert',
      billValueTooHigh: 'Bestimmte Bestellungen haben einen niedrigeren Preis als gedruckte Rechnungen.',
      billValueTooHighOrdersBaseLink: 'Sehen Sie sie',
      info: {
        summaryChanges:
          'Sehr geehrte Kunden \n Im Zusammenhang mit der Einführung der Möglichkeit, die die Aufteilung der Rechnungen ermöglicht, hat sich die Art der Abrechnung mit den Arbeitnehmern ein wenig geändert. Um zu überprüfen, wie viel Bargeld ein Mitarbeiter empfing, wie viele bezahlte Kartenzahlungen und wie viele bezahlte Online Zahlungen müssen wir auf den Rechnungen basieren, weil ab jetzt zur einen Bestellung mehr als eine Rechnungen zugeordnet werden könen (zB. Kartenzahlungsrechnung und Bargeldszahlungrechung).',
        billsTableTip:
          'In der folgenden Tabelle befinden sich in den Kolumnen die Rechnungen mit der Aufgliederung nach Zahlungsarten. In den Zeilen befinden sich die Rechnungen mit Aufgliederung nach Mitarbeiter. Daher nehmen wir die Angaben wie viel Bargeld ein Mitarbeiter empfängt und wie viel Kartenzahlungen genauso wie bei den bezahlten Online Zahlungen.',
        ordersTableTip:
          'Die einzige Änderung in dieser Tabelle besteht darin, dass wir eine Spalte "verschiedene Zahlungsarten" hinzugefügt haben, aus der hervorgeht, für wie viele Bestellungen der verschiedenen Zahlungsarten akzeptiert wurden.',
      },
      orders: 'Bestellungen',
      bills: 'Rechnungen',
      withSplitPayments: 'Verschiedene Zahlungsarten',
      realizedOrders: 'Realisiert Bestellungen',
      realizedBills: 'Realisiert Rechnungen',
    },
    settings: {
      payments: 'Zahlungen',
      settings: 'Einstellungen',
      management: 'Verwaltung',
      addEmployee: 'Mitarbeiter hinzufügen',
      employeesList: 'Mitarbeiterliste',
      orderBase: 'Bestellarchiv',
      ordersSummary: 'Bestellübersicht',
      subscriptions: 'Abonnements',
      salaries: 'Lohnabrechnungen',
      payrollList: 'Gehaltsabrechnung',
      manHours: 'Arbeitsstunden?',
      penalties: 'Strafen',
      bonuses: 'Boni',
      advances: 'Vorschüsse',
      kilometrage: 'Kilometrestand',
      restaurantCash: 'Restaurant',
      daySummary: 'Tagesübersicht',
      currentShifts: 'Laufende Schichten',
      shifts: 'Schichten',
      shiftsList: 'Alle Schichten',
      addEditEmployee: 'Mitarbeiter hinzufügen oder bearbeiten',
      regions: 'Liefergebiete',
      currentBillingPeriods: 'Laufende Abrechnungszeiträume',
      billingPeriods: 'Abrechnungszeiträume',
      billings: 'Rechnungen',
      employees: 'Mitarbeiter',
      orders: 'Bestellungen',
      billingPeriod: 'Abrechnungsperiode',
      integrations: 'Integrationen',
      kdsSettings: 'KDS Settings',
      editWorkstation: 'Add new or edit workstation',
      goToAdmin: 'Go to admin.papu.io',
      actions: 'Aktionen',
      openDrawer: 'Öffnen die Kassenschublade',
      openingDrawer: 'Öffne die Kassenschublade...',
    },
    receiptPrintersSettings: {
      name: 'Einstellungen für Bondrucker',
      singlePrinterView: 'Belegdrucker verwalten',
      printerName: 'Name des Empfangsdruckers',
      edit: 'Bearbeiten',
      free: 'Zuordnung aufheben',
      assign: 'Zuordnen',
      delete: 'Entfernen',
      templates: 'Vorlage',
      freeTemplates: 'Nicht verwendete Vorlagen',
      save: 'Sparen',
      create: 'Erstellen',
      printAllMeals: 'Drucken Sie alle Gerichte',
      printRemainingMeals: 'Drucken Sie Gerichte, die nicht mit anderen Vorlagen übereinstimmen',
      printWhitelistedMeals: 'Drucken Sie nur Gerichte aus der Liste',
      templateName: 'Vorlagenname',
      no_of_copies: 'zahl der Exemplare jedes gedruckten Gutscheins',
      templateType: 'Vorlagentyp',
      whitelist:
        'Liste der Schlüsselphrasen, die die von der Vorlage gedruckten Gerichte enthalten. Einzelne Phrasen durch einen Enther getrennt.',
      deletePrinterModalTitle:
        'Möchten Sie den Drucker wirklich löschen? Alle ihm zugewiesenen Vorlagen werden freigegeben.',
      cancel: 'Stornieren',
      saved: 'Abgelegt',
      newPrinter: 'Bondrucker hinzufügen',
      newPrinterDetails: 'Geben Sie Details zur neue Rustikationsmaschine hinzu an',
      newTemplate: 'Fügen Sie eine neue Vorlage hinzu',
      template: 'Vorlage',
      ownReceiptDesign: 'Kundenspezifisches Design (um Kontakt papu.io hinzuzufügen)',
      receiptDesign: 'Gutscheinauftritt',
      expand: 'Erweitern',
      shrink: 'Zusammenbruch',
      printerInternalId: 'Der Name Rustikationsmaschine hinzu in Windows',
      newPrinterNameTooltipText:
        'Der Name, unter dem die Motorhaube in unserem System sichtbar ist. Zum Beispiel "Küche", "Grill".',
      newPrinterInternalIdTooltipText:
        'Der Name, unter dem die angegebene Motorhaube in Ihrem Windows-System angezeigt wird. Um dorthin zu gelangen, gehen Sie zur Systemsteuerung, dann zu Hardware und Sound, dann zu Geräten und Druckern. Geben Sie zum Schluss einfach den Namen des Druckers ein, den Sie unserem System hinzufügen möchten.',
      assignedTemplates: 'Zugewiesene Vorlagen',
      howItWorksCollapsible: 'Wie funktioniert es?',
      howItWorksCollapsibleDescription:
        'Vorlagen entscheiden, welche Gerichte auf dem Gutschein erscheinen, wie viele Exemplare des Gutscheins gedruckt werden, welcher Gutschein aus dem Gutschein kommt und wie der Gutschein aussehen wird.\n"Zugewiesene Vorlagen" ist eine Reihe von Vorlagen, die einer bestimmten Motorhaube zugewiesen sind.\n"Kostenlose Vorlagen" ist eine Sammlung von Vorlagen, die keiner Motorhaube zugeordnet sind. Wenn die Vorlage frei ist, hat dies keine Auswirkungen auf den Druck der Gutscheine.',
      exampleCollapsible: 'Aktionsbeispiel',
      exampleCollapsibleDescription:
        '1. Eine Vorlage mit der Anzahl der Kopien auf 2 und den Schlüsselwörtern "Pizza" und "Spaghetti" wird der Motorhaube "Küche" zugewiesen.\n2. Wir weisen der Motorhaube "Grill" eine Vorlage mit der Anzahl der Kopien auf 1 und dem Schlüsselwort "Steak" zu.\n3. Der Kunde bestellt Gerichte mit den Namen "Pizza Italiana", "Spaghetti Napoli", "Gurke", "Thunfischsteak".\n4. Nachdem Sie auf die Schaltfläche geklickt haben, um einen Gutschein auszudrucken, werden zwei Gutscheine mit den Gerichten "Pizza Italiana" und "Spaghetti Napoli" aus der Motorhaube "Küche" und ein Gutschein mit einem Gericht namens "Steak" aus dem Bonnier "Grill" ausgegeben mit Thunfisch ". Gurke in einem solchen Szenario erscheint nicht auf einem Gutschein!\n5. Um die Gurkenvorlage zu drucken, fügen Sie die Vorlage ANDERE GERICHTE einem der Drucker hinzu oder fügen Sie die Gurkenvorlage der Schlüsselwortliste einer der bereits verwendeten Vorlagen hinzu, oder erstellen Sie eine neue Vorlage und geben Sie die Gurkenvorlage in die Liste der Schlüsselwörter ein.\n6. Eine Vorlage = ein Gutschein. Wenn wir der Motorhaube zwei Vorlagen zuweisen, werden zwei verschiedene Gutscheine in den im Feld "Anzahl der Kopien" angegebenen Beträgen ausgegeben (zwei Vorlagen mit jeweils zwei Kopien drucken insgesamt 4 Gutscheine). Diese Lösung kann beispielsweise nützlich sein, wenn Sie sowohl Getränke als auch Vorspeisen auf die Bar drucken möchten, jedoch auf separaten Gutscheinen.',
      allMealsRemainingMealsCollapsible: 'ALLE KURSE / ANDERE KURSE',
      allMealsRemainingMealsCollapsibleDescription:
        'Standardmäßig werden zwei grundlegende, unauslöschliche Vorlagen für einen Ort erstellt: ALLE KURSE und ANDERE GERICHTE.\n1. Wenn die Vorlage ALL DISHES einer Motorhaube zugewiesen ist, wird nur ein Gutschein mit allen Gerichten für den gesamten Ort ausgestellt, unabhängig davon, ob einer Motorhaube andere Vorlagen zugewiesen sind.\n2. Wenn die Vorlage REMAINING DISHES einem der Gerichte zugeordnet ist, werden die Gerichte, die keinen Stichwörtern entsprechen, auf diesem Stand gedruckt.\n3. Die ALL DISHES-Vorlagen verhalten sich unabhängig von ihrer Anzahl immer gleich. Wenn wir zwei solcher Vorlagen an unsere Drucker angehängt haben, werden die Gutscheine mit allen Gerichten auf beiden Druckern gedruckt.\n4. Wenn ein Gutschein zwei Gutscheine mit allen Gerichten drucken soll, wird empfohlen, die Anzahl der Kopien zu erhöhen und keine zweite Vorlage vom Typ ALL DISHES zu erstellen.',
      deleteReceiptTemplate: 'Möchten Sie diese Vorlage wirklich löschen? Ihre Daten werden dauerhaft gelöscht',
      addNewTemplateTemplateType: 'Welche Art von Vorlage möchten Sie hinzufügen?',
      whitelistTemplateType: 'Vorlage mit einer Liste der Schlüsselphrasen',
      allMealsTemplateType: 'ALLE KURSE Vorlage',
      ALLMEALS: 'ALLE KURSE',
    },
    navMain: {
      orders: 'Bestellungen',
      ordersCompletedToday: 'Bestellungen heute abgeschlossen',
      settings: 'Einstellungen',
      newOrder: 'Neue Bestellung',
      logout: 'Abmelden',
      loggedInAs: 'Eingeloggt',
      couriersMap: 'Karte',
      chooseRestaurant: 'Wählen Sie ein Restaurant',
      addFunds: 'Fonds hinzufügen',
      newDelivery: 'neue Lieferung',
      onSite: 'vor Ort',
      scrapeSuccess: 'Bekommen',
      confirmMealChangeLoss: 'Möchten Sie nicht gespeicherte Essensänderungen verwerfen?',
      discardChanges: 'Änderungen verwerfen',
      saveChanges: 'Änderungen speichern',
      papuHelp: 'Papu.io Hilfe',
      helpManager: 'Kundendienstbetreuer',
      help: 'Hilfe',
      canWeHelp: 'Wie können wir Ihnen helfen?',
      addOrder: 'Bestellung hinzufügen',
      createNewOrder: 'Neuen Auftrag anlegen',
      delivery: 'Lieferung',
      chat: 'Kontaktieren Sie uns per Chat',
    },
    subscriptions: {
      balance: 'Geschuldeter',
      orders: 'Bestellung',
      days: 'Tage',
      run: 'Kaufen',
      addFunds: 'Fonds hinzufügen',
      acceptTerms: 'Ich bestätige, dass ich die Allgemeinen {0} von papu.io gelesen und akzeptiert habe',
      terms: 'Geschäftsbedingungen',
      termsAcceptRequired: 'Die Annahme der Allgemeinen Geschäftsbedingungen ist erforderlich',
      paymentsHistory: 'Zahlungsübersicht',
      numberOfOrders: 'Anzahl der Bestellungen',
      created: 'Erstellt am',
      value: 'Wert',
      inSubscription: 'Im Paket enthalten',
      left: 'Noch nutzbar',
      used: 'Verbleibend',
      downloadInvoice: 'Rechnung herunterladen',
      download: 'Download',
      gross: 'brutto',
      trial: 'Testbestellungen',
      paymentTrue: 'Vielen Dank! Ihre Zahlung wurde akzeptiert.',
      paymentFalse:
        'Es tut uns leid, aber bei der Zahlung ist ein Fehler aufgetreten. Bitte versuchen Sie erneut, ein Abonnement zu kaufen. Bei Fragen wenden Sie sich bitte an uns.',
      net: 'Reingewinn (net profit)',
      name: 'Name',
      address: 'Adresse',
      postal_code: 'Postleitzahl',
      city: 'Stadt',
      nip: 'UstID',
      email: 'E-Mail-Addresse',
      emailTooltip: 'Eine Rechnung wird an die angegebene E-Mail-Adresse gesendet',
      update: 'Aktualisieren',
      edit: 'Unternehmensdaten aktualisieren',
      editInfo: 'Die folgenden Daten werden verwendet, um die Rechnung für das gekaufte Abonnement auszufüllen.',
      required: 'Dieses Feld wird benötigt',
      companySaved: 'Unternehmensdaten gespeichert!',
      additionalInvoiceData: 'Zusätzliche Rechnungsdaten',
      additionalInvoiceDataInfo: 'Diese Daten werden zusätzlich unter den Firmendaten abgelegt',
    },
    billing: {
      name: 'Vor- und Nachname',
      startedAt: 'Schichtbeginn',
      manHours: 'Arbeitsstunden',
      penalties: 'Strafen',
      bonuses: 'Bonuszahlungen',
      advances: 'Vorschüsse',
      kilometrage: 'Gefahrene Kilometer',
      numberOfOrders: 'Anzahl der Bestellungen',
      toBePaidOut: 'Arbeitslohn',
      finishedAt: 'Ende der Schicht',
      showRemovedEmployees: 'Entfernte Mitarbeiter anzeigen',
      hideRemovedEmployees: 'Entfernte Mitarbeiter ausblenden',
      filterByEmployee: 'Nach Mitarbeiter filtern',
      closePeriod: 'Sperrfrist',
      addAdvance: 'Vorteil',
      addPenalty: 'Strafe hinzufügen',
      addBonus: 'Bonus hinzufügen',
      amount: 'Menge hinzufügen.',
      comment: 'Kommentar hinzufügen',
      cancel: 'Abbrechen',
      add: 'Hinzufügen',
      confirmation:
        'Die Bestätigung der Gehaltszahlung wird zurückgesetzt und der aktuelle Abrechnungszeitraum geschlossen.',
      confirm: 'Bestätigen',
      confirmAndPrint: 'Bestätigen und drucken',
      closed: 'Geschlossen',
      start: 'Start',
      end: 'Ende',
      operationalCash: 'Startgeld',
      paymentsFromCustomer: 'Zahlungen vom Kunden',
      balance: 'Saldo',
      shiftStart: 'Date and time of start',
      shiftFinish: 'Datum und Uhrzeit des Schichtendes',
      initialMileage: 'Anfangskilometer',
      finalMileage: 'Endkilometer',
      kilometers: 'Kilometer',
      mileageStart: 'Kilometerstand (Start)',
      mileageFinish: 'Kilometerstand (Ziel)',
      terminalNumber: 'Klemmennummer',
      returnedOperationalCash: 'Rückgabe von Betriebsmitteln',
      returnedCashForOrders: 'Rückgabe von Bargeld für Bestellungen',
      operationalCashOut: 'operatives Geld gegeben',
      terminalReturned: 'Terminal kehrte zurück',
      save: 'Speichern',
      startDay: 'Starttag',
      endDay: 'End Tag',
      newShift: 'neue Schicht',
      role: 'Rolle',
      noBillingPeriodAlert: 'Bitte aktivieren Sie den Abrechnungszeitraum für diesen Benutzer',
      noSalaryConfigAlert: 'Gehen Sie zu Mitarbeiter bearbeiten und konfigurieren Sie die Gehaltskonfiguration',
      operationalBalance: 'Betriebsbilanz',
      cashFromOrders: 'Zahlungen aus Bestellungen',
      details: 'Details',
      closeBillingPeriod: 'Schließen Sie auch den Abrechnungszeitraum',
      period: { component: 'Bestandteil', amount: 'Amount', value: 'Wert' },
      pendingOrdersWarning:
        'Nie wszystkie zamówienia zostały zakończone. Zakończenie ich przez pracownika, może wpłynąć na wysokość salda zmiany tego pracownika (nawet jeśli zmiana jest zakończona)',
      billingPeriodIsFinished: 'Der Abrechnungszeitraum ist beendet.',
      acceptedCash: 'Angenommenes Geld',
      givenBack: 'Zurückgegeben',
      nameLastName: 'Vor-und Nachname',
      date: 'Datum',
      type: 'Typ',
      none: 'Keine',
      notAllOrdersWithDA: 'Nicht allen Bestellungen ist ein Lieferbereich zugeordnet.',
      ordersToDA: 'Bestellungen an Liefergebiete',
      orders: 'Bestellungen',
      operationalCashBalance: 'Operativer Kassenbestand',
      beginning: 'Beginn',
      all: 'Alle Tage',
      closeAndSettle: 'Abrechnungszeitraum schließen und abrechnen',
      closedDate: 'Geschlossenes Datum',
      shifts: 'Schichten',
      shiftEdit: 'Bearbeiten',
      shiftEditInfo: 'Die Schichtbearbeitung ist nur für Manager verfügbar.',
      shiftEditFinishedBillingInfo:
        'Die Schichtbearbeitung ist nur für die letzte Schicht des letzten nicht abgeschlossenen Abrechnungszeitraums verfügbar',
      reopenBillingPeriod: 'Abrechnungszeitraum erneut öffnen und korrigieren',
      reopenBillingPeriodInfo: 'Der Abrechnungszeitraum kann nur vom Manager wieder geöffnet werden.',
      reopenBillingPeriodLastInfo:
        'Die Bearbeitung des Abrechnungszeitraums ist nur für den letzten Abrechnungszeitraum des Mitarbeiters verfügbar.',
    },
    shifts: {
      restaurantCash: 'Restaurant',
      value: 'Wert',
      cashForOrders: 'Barzahlung bei Bestellung',
      operationalCashBalance: 'Operativer Kassenbestand',
      sum: 'Summe',
      salary: 'Gehalt',
      quantity: 'Amount',
      employeePrice: 'Preis',
      orders: 'Bestellungen',
      notAllOrdersWithDA: 'Nicht allen Bestellungen ist ein Lieferbereich zugeordnet.',
      manHours: 'Gearbeitete Stunden',
      kilometres: 'Kilometer',
      date: 'Datum',
      type: 'Typ',
      amount: 'Amount',
      comment: 'Kommentare',
      none: 'Keine',
      data: 'Daten',
      shiftStart: 'Schichtstarttag',
      shiftEnd: 'Arbeitsende',
      startKilometres: 'Kilometer - starten',
      endKilometres: 'Kilometer - Ende',
      lasts: 'Leisten',
      no: 'Nein',
      address: 'Adresse',
      deliveryArea: 'Liefergeblet',
      price: 'Preis',
      payment: 'Zahlungen',
      status: 'Status',
      finishedType: 'Beendet',
      orderNotAssignedDA: 'Bestellung nicht dem Lieferbereich zugeordnet.',
      beginning: 'Beginn',
      end: 'Ende',
      billingPeriod: 'Abrechnungszeitraum',
      billingPeriodLink: 'Gehen Sie zu',
      shift: 'Schicht',
    },
    regions: {
      title: 'Lieferzonen',
      addNewBtnText: 'Neue Lieferzone hinzufügen',
      noRegions: 'Keine Lieferregionen',
      regionName: 'Name des Gebiets',
      active: 'Aktiv',
      save: 'Speichern',
      cancel: 'Abbrechen',
      courierPrice: 'Fahrergeld',
      freeDeliveryFromValue: 'Kostenlose Lieferung ab',
      minOrderValue: 'Mindestbestellwert',
      deliveryPrice: 'Lieferpreis',
      errors: {
        noPolygon: 'Es gibt keine gezeichnete Region. Bitte zeichnen Sie die Region auf die Karte.',
        polygonMinVertexes: 'Die Region muss mindestens 3 Scheitelpunkte haben.',
        areaRadiusInput: 'Alternativ: Radius',
      },
      list: 'Regionenliste',
      map: 'Karte',
    },
    invoiceModal: {
      titleEdit: 'Rechnung bearbeiten',
      title: 'Rechnung Drucken',
      companyName: 'Firmenname',
      streetAddress: 'Straße',
      postalCode: 'Postleitzahl',
      city: 'Stadt',
      taxIDNumber: 'Steuer-ID-Nummer',
      billNumber: 'Rechnungsnummer:',
      hideDetails: 'Ohne Details',
      allFieldsRequired: 'Pflichtfelder sind mit * gekennzeichnet',
      hideName: 'Name',
      hideNames: { default: "'Catering-Service'", custom: 'Benutzerdefiniert' },
      paymentType: 'Zahlungsart',
      paymentTypes: {
        order: 'Aus Bestellung',
        custom: 'Benutzerdefiniert',
        cash: 'bar',
        card: 'Kartenspiel',
        paid: 'Online',
      },
      sendInvoiceByEmail: 'Sende Rechnung per E-Mail',
      printInvoice: 'Drucke Rechnung',
    },
    administration: {
      settings: 'Einstellungen',
      localizationConfig: {
        message: 'Einstellungen zur Restaurant',
        localizationName: 'Name der Restaurant',
      },
      ordersSummaryTimeFilters: {
        message: 'Standard-Datumsfilter für die Auftragszusammenfassung und Datumsbereich für die Auftragsnummerierung',
        weekday: 'Wochentag',
        start: 'Start',
        end: 'Ende',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
      },
      openingHours: { message: 'Öffnungszeiten Restaurant' },
      integrationsConfig: {
        message: 'Wählen Sie Integrationen, für die Bestellungen automatisch gedruckt werden sollen',
        receiptAuto: 'Automatisches Drucken von Belegen',
        phone: 'Bestellungen über Telefon',
        pyszne: 'Lieferando Bestellungen',
        restaumatic: 'Restaumatic.com Bestellungen',
        upmenu: 'Upmenu.pl Bestellungen',
        erestaurant: 'Erestaurant Bestellungen',
        ubereats: 'Ubereats.com Bestellungen',
        groupon: 'Groupon.pl Bestellungen',
        new_erestaurant: 'Erestaurant Bestellungen',
        ordersmart: 'OrderSmart Bestellungen',
      },
      integrationsNewOrderStatus: {
        message: 'Wählen Sie die Standardregisterkarte für neue Aufträge aus Integrationen',
      },
      orders: {
        message: 'Botschaft',
        orderOnSite: 'Bestellung vor Ort hinzufügen',
        printBillCheck: 'Standardprüfung für Rechnungsdruckoption',
        finishOrderCheck: 'Standardprüfung für Bestellabschlussoption',
        orderDelivery: 'Auslieferungsaufträge AW17',
        printBillDeliveryCheck: 'Standardprüfung für Rechnungsdruckoption',
        orderDetails: 'Bestelldetails',
        closeOrderOnPrintBill: 'Schließen Sie die Bestellung, nachdem Sie auf "Rechnung" geklickt haben',
        ordersCommonConfig: 'Bestellungen - Allgemeines',
        manualSourceChoiceRequired:
          'Erfordern, dass eine Bestellquelle für neue Bestellungen ausgewählt wird, ' +
          'und verbergen Sie die Standardquelle in der Liste der Bestellquellen.',
      },
      deliveryOnBill: {
        message: 'Lieferung am',
        delivery: 'Lieferung',
        vatRate: 'Umsatzsteuersatz',
        positionNameOnBill: 'Positionsname auf Rechnung (optionale)',
        skipDeliveryOnBillAndInvoice: 'Weise kein Lieferung auf dem Kassenbon aus ',
      },
      tip: {
        message: 'Trinkgeld auf Rechnung',
        tips: 'Trinkgeld',
        vatRate: 'Umsatzsteuersatz',
        positionNameOnBill: 'Positionsname auf Rechnung (optionale)',
        skipTipOnBillAndInvoice: 'Weise kein Trinkgeld auf dem Kassenbon aus',
      },
      defaultPosView: {
        message: 'Wählen Sie die Standardansicht für das Papu.io-System',
        defaultView: 'Standardansicht',
        map: 'Karte',
        list: 'Liste der Anfragen',
      },
      halfPizza: {
        message: 'Fünfzig-fünfzig-Pizza-Berechnung',
        halfPizzaPrice: 'Halber Pizza-Preis',
        additionalPrice: 'Zusätzlicher Preis für fünfzig-fünfzig Pizza',
        halfPizzaAvgPrice: 'Durchschnittspreis aus beiden Hälften + Zusatzpreise',
        halfPizzaMoreExpensive: 'Teurer halber Preis + Zusatzpreise',
        halfPizzaMcgPrice: 'Der Preis für die halbe Pizza von Addition',
        halfPizzaMcgHalfPrice: 'Halber Aufpreis',
        halfPizzaMcgFullPrice: 'Voller Aufpreis',
      },
      shifts: {
        message: 'Schichten',
        oneDayBillingPeriods: 'Abrechnungszeiträume von einem Tag',
        closeBillingPeriod: 'Abrechnungszeiträume standardmäßig mit Schicht schließen',
      },
      summary: {
        message: 'Zusammenfassende Berichte',
        summaries: 'Einstellungen zum Senden von Zusammenfassungen per E-Mail',
        summary_description:
          'Tägliche Zusammenfassung - Enthält Informationen zu abgeschlossenen Bestellungen des letzten Tages basierend auf festgelegten Zeitfiltern',
        summary_fiscal_description:
          'Tägliche Finanzübersicht - enthält Informationen zu gedruckten Bestellungen des letzten Tages',
      },
      integrationsSettings: {
        message: 'Integrationen',
        includeUberPromotionDescription:
          'Fügen Sie den Gebotsbetrag (Code) von UberEats als Rabatt in den ' + 'Bestellpreis und den Belegwert ein',
        subtractStampcardFromPrice: 'Subtract stampcards value from order price',
        glovoServiceChargeVat: 'Umsatzsteuersatz Servicegebühr Glovo',
        gloVoServiceChargeNameOnBill: 'Name der Glovo-Servicegebühr auf dem Kassenbon (optionale)',
        takeawayLabel: 'Lieferando',
      },
      notifications: {
        message: 'Benachrichtigungen',
        newOrder: 'Neue Bestellung',
        soundNotification: 'Tonbenachrichtigung',
      },
      settingsSaved: 'Einstellungen gespeichert',
      saveChanges: 'Änderungen speichern',
    },
    erestaurant: {
      erestaurant: 'Einstellungen für das E-Restaurant',
      message: 'Einstellungen für Ihr E-Restaurant',
      link: 'Link',
      preview: 'Vorschau',
      logo: 'E-Restaurant Logo Bild',
      background: 'E-Restaurant Hintergrundbild',
      icon: 'E-Restaurant-Symbol',
      orderEmail:
        'Für die Bestellung erforderliche E-Mail-Adresse - Die Auftragsbestätigung wird an diese E-Mail gesendet',
      differentPrices: 'Sollte das E-Restaurant für {Restaurant} andere Preise für Mahlzeiten haben als der POS?',
      active: 'E-Restaurant aktiv?',
      activeTooltip: 'Online-Shop ein- / ausschalten',
      complexMealsAvailable: 'Sollten halb für halb Mahlzeiten im E-Restaurant erhältlich sein?',
      showRestaurantEmail: 'Restaurant-E-Mail anzeigen',
      sendOrderConfirmationEmailToBCC: 'Sollen wir eine Kopie der Bestellbestätigungs-E-Mail an das Restaurant senden?',
      brandActive: 'E-Restaurant aktiv für Restaurant {Restaurant}?',
      brandActiveTooltip: 'E-Restaurant für Restaurant {Restaurant} ein- / ausschalten',
      openingHours: 'Öffnungszeiten des E-Restaurants für {Restaurant}',
      ordersTimeLimit: {
        message: 'E-Restaurant Konfiguration bezüglich Bestellzeit für {Restaurant}',
        pickup: 'Abholaufträge',
        delivery: 'Auslieferungsaufträge AW17',
        minTime:
          'Die Mindestzeit von der Bestellung bis zur Fertigstellung. Dieser Wert wird zur aktuellen Uhrzeit ' +
          'für "Pünktliche" Bestellungen addiert, wodurch eine Mindestzeit für die Bestellung am ausgewählten ' +
          'Datum festgelegt wird.',
        maxTime:
          'Die maximale Zeit für die Bestellung vor dem Schließen des Restaurants. Dieser Wert wird von der ' +
          'Zeit abgezogen, zu der das Restaurant schließt, wodurch die maximale Zeit erstellt wird, die Sie am ' +
          'ausgewählten Tag bestellen können.',
      },
      minutes: 'Minuten',
      choose: 'Auswählen',
      loadFile: 'Lade Datei',
      analytics: 'Analytik',
      googleAnalyticsInfo: 'Geben Sie die GoogleAnalytics-ID ein (UA-XXXXXXXXX-X).',
      facebookPixelId: 'Facebook Pixel ID',
      facebookPixelIdInfo: 'Facebook Pixel ID einfügen',
      facebookPixelMetaTag: 'Facebook Pixel meta-tag-ID (Feld "content")',
      facebookPixelMetaTagInfo: 'Facebook Pixel meta-tag-ID (Feld "content") einfügen',
    },
    permissions: {
      permissionsSettings: 'Befugniseinstellungen',
      ordersPermissions: 'Berechtigungen für Bestellungen',
      pastHistoryOrdersView:
        'Entscheiden Sie, wie alte Bestellungen Ihre Mitarbeiter in der Archivansicht und in der Bestellübersicht sehen sollen (Anzahl der Tage zurück)',
      days: 'Tag | Tage',
      updateFinishedOrders:
        'Der Mitarbeiter kann den fertigen Typ und die Zahlungsmethode der fertigen Bestellung ändern',
      orderPaymentPaidChange:
        'Der Mitarbeiter darf die Zahlungsmethode von "Bezahlt" zu einer anderen und von einer anderen zu "Bezahlt" ändern',
      employeesPermissions: 'Berechtigungen der Mitarbeiter',
      manageEmployees: 'Der Kellner kann die Mitarbeiterliste verwalten',
    },
    menu: {
      menuSave: 'Menü speichern',
      menuEdit: 'Menü-Verwaltung',
      imageLoading: 'Bild hochladen, bitte warten',
      defaultSizeName: 'Standard',
      mealsCount: 'Anzahl der Mahlzeiten in der angegebenen Kategorie',
      sizesCount: 'Anzahl der Größe in der angegebenen Kategorie',
      categorySizeMealsCount: 'Anzahl der Mahlzeiten in der ausgewählten Kategorie in der ausgewählten Größe',
      sizeMealsCount: 'Anzahl der Mahlzeiten in gegebener Größe',
      choiceGroupMealsCount: 'Anzahl der Mahlzeiten in der ausgewählten Kategorie mit der angegebenen Gruppe',
      menuNotSaved: 'Die Änderungen wurden nicht gespeichert. Möchten Sie speichern?',
      save: 'Speichern\n',
      saving: 'Speichern...',
      savingWait: 'Speichern. Bitte warten...',
      leaveWithoutSaving: 'Speichern Sie keine Änderungen und verlassen Sie das Menübearbeitungsfeld',
      menuSavingError: 'Fehler beim Speichern des Menüs',
      menuSaved: 'Das Menü wurde gespeichert',
      menuChangesAfterWaiterRelog:
        'Damit die am Menü vorgenommenen Änderungen auf dem Konto des Kellners angezeigt ' +
        'werden, melden Sie sich bitte vom Konto des Kellners ab und erneut an',
      saveMenu: 'Beenden Sie die Bearbeitung und speichern Sie das Menü',
      exitMenu: 'Beenden Sie das Menüverwaltungsfeld',
      editMenuExternalSource: 'Externes Quellmenü',
      vatRate: 'Mehrwertsteuersatz',
      noCategories: 'Keine Kategorien',
      notFoundMeal: 'Wir haben den gesuchten Begriff im Geschirr nicht gefunden',
      deleteMsgBox: {
        confirm: 'Möchten Sie den Artikel wirklich löschen?',
        ok: 'Ja',
        cancel: 'Nein',
      },
      publish: 'Veröffentlichen',
      published: 'Veröffentlicht',
      publishModalText: 'Do you want to publish menu changes before exiting?',
      publishModalMenuChangeText:
        'Ob Sie Ihre Änderungen veröffentlichen möchten, bevor Sie ein anderes Menü auswählen?',
      choiceGroups: {
        groupName: 'Gruppenname',
        cloneGroup: 'Klongruppe',
        required: 'Erforderlich',
        requiredInfo: 'Überprüfen Sie, ob Sie einen Modifikator aus dieser Gruppe auswählen müssen',
        defaultScge: 'Modifikator standardmäßig ausgewählt',
        defaultScgeInfo: 'Der ausgewählte Modifikator wird immer zuerst ausgewählt',
        add: 'Fügen Sie eine neue Kategorie hinzu',
        name: 'Name',
        price: 'Preis',
        addNext: 'Neue hinzufügen',
        availableInEresto: 'Gruppe in E-Restaurants verfügbar?',
        mealsWithSelectedChoiceGroups: 'Gerichte mit einer ausgewählten Gruppe von Zusatzstoffen',
        chooseCategoryToEdit: 'Wählen Sie die Kategorie aus, für die Sie ändern möchten ',
        chooseSizeToEdit: 'Wählen Sie die Größe aus, für die Sie ändern möchten ',
        addGroupForSelectedSize: 'Fügen Sie eine neue Gruppe für die ausgewählte Größe hinzu',
        increaseAllEntriesPrice: 'Erhöhen Sie den Preis aller Gerichte um 1',
        decreaseAllEntriesPrice: 'Reduzieren Sie den Preis aller Gerichte um 1',
        changeAllEntriesPrice: 'Veränderung Sie den Preis aller Gerichte um',
        multiChoiceGroupTitle: 'Zutaten',
        singleChoiceGroupTitle: 'Modifikatoren',
        noEntries:
          'Sie können keine Gruppe ohne Einträge speichern. Fügen Sie mindestens einen Eintrag im linken Menübedienfeld hinzu.',
        noSelectedMeals: 'Sie müssen mindestens eine Mahlzeit auswählen, der diese Gruppe zugewiesen werden soll',
        multiChoiceGroupsTitle: 'Zutaten Gruppen',
        multiChoiceGroup: 'Zutaten Gruppe',
        noMultiChoiceGroups: 'Keine Zusatzstoffgruppen.',
        singleChoiceGroupsTitle: 'Modifikatiorengruppen',
        singleChoiceGroup: 'Modifikatiorengruppe',
        noSingleChoiceGroups: 'Keine Modifikatiorengruppen.',
      },
      meal: {
        title: 'Gerichte',
        ingredientDuplicatedInfo: 'Das Gericht darf nicht mehr als eine gleichnamige Zutat enthalten',
        name: 'Name des Gerichts',
        size: 'Größe',
        ingredients: 'Geschirrzutaten - woraus das Gericht besteht',
        addIngredient: 'Fügen Sie eine Zutat hinzu',
        availableInEresto: 'Ein Gericht in einem E-Restaurant?',
        description: 'Beschreibung des Gerichts',
        visibleInEresto: 'Das im E-Restaurant sichtbare Bild des Gerichts',
        chooseCategoryToEdit: 'Wählen Sie die Kategorie aus, für die Sie Gerichte wechseln möchten',
        add: 'Fügen Sie ein neues Gericht hinzu',
        price: 'Preis',
        erestoPrice: 'E-Restaurant Preis',
        noPrices: 'Das Feld mit dem Preis der Mahlzeit darf nicht leer sein.',
        noMealSizes: 'Die Mahlzeit muss mindestens einer Größe zugeordnet sein.',
        mealNameExists: 'Eine Mahlzeit mit diesem Namen existiert bereits in dieser Kategorie.',
      },
      sizes: {
        title: 'Größe',
        name: 'Name',
        defaultSize: 'Standard Größe?',
        defaultTooltipText:
          'Dies ist immer die ursprünglich ausgewählte Größe, wenn Sie eine neue Bestellung erstellen.',
        fiftyFiftyMeals: 'Können Größengerichte, z. B. Pizzaschachteln, in zwei Hälften geteilt werden?',
        availableInEresto: 'Größe in einem eRestaurant verfügbar?',
        mergeSizes: 'Führen Sie andere Größen zu dieser Größe zusammen',
        selectMergeSizes: 'Wählen Sie die Größe aus, die Sie mit dieser Größe zusammenführen möchten',
        add: 'Fügen Sie eine neue Größe hinzu',
        deleteWarning:
          'Die ausgewählte Größe aus der Liste wird entfernt und das Geschirr wird zur aktuellen Größe hinzugefügt (grau).',
      },
      categories: {
        title: 'Kategorien',
        add: 'Fügen Sie eine neue Kategorie hinzu',
        name: 'Kategoriename',
        defaultCategory: 'Standardkategorie?',
        defaultTooltipText:
          'Dies ist immer die Kategorie, die beim Erstellen einer neuen Bestellung ursprünglich ausgewählt wurde.',
        default: 'Standard',
        availableInEresto: 'Kategorie in einem eRestaurant verfügbar?',
        visibleInEresto: 'Kategoriebild im E-Restaurant sichtbar',
        addSize: 'Größen hinzufügen',
        addNextSize: 'Eine weitere Größe hinzufügen',
        moreThanOneSizeInCategory: 'Sind die Gerichte in der Kategorie mehr als 1 Größe?',
        noCategories: 'Keine Kategorien',
        kdsStatuses: 'The status of meals in KDS',
        kdsStatusesModalTitle: 'Select a status set',
        kdsStatusesTooltip:
          'Select a set of meal statuses that will apply in the KDS application for all meals in this category.',
      },
      states: {
        saved: 'Artikel gespeichert.',
        added: 'Artikel hinzugefügt.',
        deleted: 'Gegenstand wurde entfernt.',
      },
      edit: {
        categories: 'Kategorie bearbeiten',
        sizes: 'Größe bearbeiten',
        multi_choice_groups: 'Additivgruppe bearbeiten',
        single_choice_groups: 'Bearbeiten einer Gruppe von Modifikatoren',
        meals: 'Eine Mahlzeit bearbeiten',
        noEditElementTitle: 'Es gibt kein Element zum Bearbeiten.',
        noEditElementBody: 'Wählen Sie ein Element aus dem Menü zum Bearbeiten aus.',
      },
      new: {
        categories: 'Neue Kategorie',
        sizes: 'Neue Größe',
        multi_choice_groups: 'Neue Additivgruppe',
        single_choice_groups: 'Neue Gruppe von Modifikatoren',
        meals: 'Neue Mahlzeit',
      },
    },
    mealSets: {
      mealSetsSettings: 'Menüs',
      discount: 'Rabatt',
      count: 'Menge',
      category: 'Kategorie',
      sizes: 'Größen',
      meals: 'Mahlzeiten',
      newMealSet: 'Neue Essensset',
      addMealSet: 'Essensset hinzufügen',
      editMealSet: 'Essensset bearbeiten',
      mealSetName: 'Name des Essenssets',
      mealSetNameInput: 'Geben Sie den Namen des Essenssets ein',
      mealSetItems: 'Essenssets',
      created: 'Erstellungsdatum',
      brandName: 'Restaurant',
      name: 'Name',
      items: 'Essenssetsobjekte',
      type: 'Typ',
      normal: 'Normal',
      halfByHalf: 'Fünfzig-fünfzig',
      mealSetInEresto: 'Soll das Menü ebenfalls im E-Restaurant verfügbar se?',
      mealSetDiscount: 'Rabatt',
      backToMealSetsList: 'Zurück zu den Menüs',
      saveMealSet: 'Menü Speichern',
      addMealSetItem: 'Zum Menü hinzufügen',
      halfPizzaMealSetItemInfo: 'Im Fünfzig-fünfzig Essenset sind beide Artikel gleich.',
      mealSetItemQuantity: 'Anzahl der Mahlzeiten aus den unten ausgewählten Kategorien / Größen / Mahlzeiten',
      mealSetItemDiscount: 'Menüpunkt rabattieren',
      chooseCategory: 'Kategorie wählen',
      chooseSizes: 'Größe wählen',
      chooseMeals: 'Mahlzeiten wählen',
      all: 'Alle',
      checkUncheckAll: 'Alle aus-/abwählen',
      backToMealSet: 'Zurück zum Menü',
      saveMealSetItem: 'Menüpunkt speichern',
      allSizesOfCategory: 'Alle Größen der Kategorie',
      allMealsOfCategory: 'Alle Mahlzeiten der Kategorie',
      allMealsOfSizes: 'Alle Mahlzeiten der Größe',
      noMealSetItems: 'Keine Artikel im Menü',
      item: {
        itemCount: 'Anzahl der Mahlzeiten aus den unten ausgewählten Kategorien/Größen/Mahlzeiten',
        itemDiscount: 'Essensrabatt',
        chooseItemCategory: 'Wählen Sie die Kategorie der Mahlzeitensets',
        chooseItemSizes: 'Wählen Sie die Größe der Mahlzeitensets',
        chooseItemMeals: 'Wählen Sie die verfügbaren Mahlzeiten für dieses Essensset aus',
      },
      discountSort:
        'Rabatte für Gerichte derselben Kategorie und Größe werden sortiert, um dem teuersten Gericht den geringsten Rabatt zuzuweisen.',
    },
    mealsetEdit: {
      title: 'Menüs',
    },
    discounts: {
      discountsSettings: 'Rabatte',
      addDiscount: 'Rabatt hinzufügen',
      discountView: 'Rabattvorschau',
      noDiscount: 'Kein Rabatt',
      list: {
        new: 'Neu',
        created: 'Erstellt am',
        fromDatetime: 'Ab',
        toDatetime: 'Bis',
        used_codes: 'Verwendete Codes',
        usage: 'Verwendung',
        discountType: 'Gutschein Rabatt Typ.',
        discountValue: 'Rabatt',
        active: 'Aktiv',
        details: 'Zum Produkt',
        discountChoiceValue: 'Rabattwert',
        discountChoicePercent: 'Prozentuale Vergünstigung',
        discountChoicePromotionalPrice: 'Aktionspreis €',
        emptyList: 'Keine Rabatte',
      },
      edit: {
        noLimit: 'Begrenzung',
        limitedTime: 'Begrenzte Zeit',
        discountValuePrice: 'Rabattwert',
        discountValuePercent: 'Prozentuale Vergünstigung',
        discountPromotionalPrice: 'Aktionspreis €',
        provideDiscountValue: 'Geben Sie den Rabattwert an',
        provideDiscountPercent: 'Geben Sie einen Rabatt in Prozent an',
        providePromotionalPrice: 'Geben Sie den Aktionspreis an',
        codesToGenerate: 'Wie viele eindeutige Codes müssen generiert werden?',
        codesToGeneratePlaceholder: 'Geben Sie die Anzahl der zu erzeugenden Codes an',
        perCodeUsage: 'Wie oft kann jeder Code verwendet werden?',
        perCodeUsagePlaceholder: 'Geben Sie die Anzahl der Codes ein',
        addDiscount: 'Rabatt hinzufügen',
        removeDiscount: 'Rabatt entfernen',
        codes: 'Codes',
        confirmRemoveDiscount: 'Möchten Sie den Rabatt wirklich entfernen?',
      },
    },
    notifications: {
      deletable: 'Nach dem klicken auf X wird diese Nachricht dauerhaft entfernt',
    },
    regeocodeOrders: {
      orderIsAlreadyInRegeocode: 'Der Auftrag wird bereits umverteilt',
      orderRegeocodeIsBlocked: 'Die Neuverortung ist für den Auftrag blockiert',
      regeocodeOrdersSuccess: 'Die Neureverortung von Bestelllungen',
      regeocodeOrdersError: 'Es gab einen Regeocode-Fehler bei der Geolokalisierung der Bestellung',
      relocating: 'Re-geolocation...',
      relocatingError:
        'Die Adresse der Bestellung konnte nicht neu lokalisiert werden. Bitte wenden Sie sich an den technischen Support.',
    },
    splitBill: {
      split: 'Spliten',
      splitIntoEqualParts: 'Zu gleichen teilen Spliten',
      extractTheAmount: 'Betrag bestimmen',
      addBill: 'Rechnung hinzufügen',
      addFirstBill: 'Erste Rechnung hinzufügen',
      emptyBillDescription: 'Der Kassenbon ist leer.\n Bitte teile dem Kassenbon die Positionen aus der Bestellung zu.',
      emptyBaseBillDescription: 'Keine Positionen eingetragen',
      moveOrderItemsHeading: 'Wie viele Positionen übertragen?',
      numberOfItems: 'Anzahl der Positionen',
      splitBillHeading: 'Auf wieviele Rechnungen aufteilen?',
      numberOfBills: 'Anzahl der Rechnungen',
      enterAmount: 'Betrag',
      enterPercentage: 'Prozent',
      enterQuantity: 'Menge',
      selectPayment: 'Wählen Sie eine Zahlungsmethode',
      mark: 'R',
      bill: 'Rechnung',
      gettingBills: 'Rechnungen laden',
      deleteBillModal: {
        title: 'Rechnung löschen',
        description:
          'Möchtest du die Rechnung löschen? \n Alle Positionen der Rechnung werden auf die Hauptrechnung übertragen.',
      },
      status: {
        ended: 'Abgeschlossen',
        printed: 'Gedruckt',
        printError: 'Fehler beim Druck',
      },
      error: {
        closeBill: 'Es ist ein Fehler beim Abschluss der Bestellung aufgetreten.',
        movePosition:
          'Die ausgewählte Rechnung ist nicht bearbeitbar. Wählen Sie eine andere Rechnung aus oder legen Sie eine neue Rechnung an.',
      },
    },
    integrations: {
      active: 'Aktiv',
      wolt: {
        on: 'Restaurant auf der Wolt einschalten',
        off: 'Restaurant auf Wolt ausschalten',
      },
      woltConfirmation: 'Der Prozess des Toggle-Restaurants auf Wolt ist abgeschlossen.',
      woltFailure: 'Bei der Umschaltung des Restaurants auf Wolt ist ein Fehler aufgetreten.',
      online: 'Restaurant online',
      offline: 'Restaurant offline',
      updateMenu: 'Menü aktualisieren',
      updateMenuConfirmation:
        'Der Prozess der Aktualisierung der Speisekarte von Dotykačka ist abgeschlossen. Bitte melden Sie sich ab und wieder an, damit die Änderungen sichtbar werden.',
      updateMenuFailure: 'Beim Aktualisieren des Menüs ist ein Fehler aufgetreten.',
    },
    mapping: {
      mappingMenu: 'Mapping menu',
      mapping: 'Mapping',
      mapIt: 'Bind',
      checkMapping: 'Check',
      bind: 'Bind',
      ignore: 'Ignore',
      unblock: 'Unblock',
      editMapping: 'Edit mapping',
      selectMealToMap: 'Select menu item to bind',
      showUnmaped: 'Show items to mapping',
      showMapped: 'Show mapped items',
      showIgnored: 'Show ignored items',
      dynamicModeTitle: 'Mapping missing items in order no. ',
      showMappedPositions: 'Show mapped positions',
      meals: 'Meals',
      singleChoice: 'Modifiers',
      multiChoice: 'Additives',
      mappingBtnTooltip: 'The meal or its additive/modifier is not mapped. Go to mapping view to bind this items.',
      mappingBtnTooltipMapped: "Go to mapping view to check this meal's binding.",
      table: {
        positionFromIntegration: 'Item from integration',
        positionFromMenu: 'Item from integration',
        noMappedPositions: 'No mapped items',
        noPositionsToBind: 'No items to mapping',
        noMapping: 'No mapping',
        noPositionInMenu: 'No items in default menu',
        mealsInGroup: 'Meals in group',
      },
      remove: {
        removeMapping: 'Unbind',
        promptQuestion: 'Are you sure you want to delete binding from this item?',
        success: 'Binding removed from position: ',
      },
      mappingSuccess: 'Position bind',
      ignoreMappingSuccess: "Position ignored and won't be send to integration",
      sourceButtonAllLabel: 'All',
      isArchivedWarningTooltip: 'Meal/Additive/Modifier which is binded to this item has been deleted.',
    },
    workstations: {
      title: 'Workstations',
      addNewWorkstation: 'Add new workstation',
      activeWorkstation: 'Active',
      noWorkstation: 'No workstations',
      userLogout: 'Offline',
    },
    workstationEdit: {
      form: {
        name: 'Workstation name',
        color: 'Color',
        user: 'Assigned users',
        mealCategories: 'Meal categories',
        selectMealCategories: 'Select meal categories',
      },
      addButton: 'Add workstation',
      saveButton: 'Save workstation',
      workstationDeleted: 'Workstation deleted',
      workstationUpdated: 'Workstation updated',
    },
    kds: {
      mealStatus: {
        todo: 'Waiting',
        doing: 'Doing',
        inOven: 'In the oven',
        done: 'Done',
      },
    },
  },
}
