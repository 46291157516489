<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="h3 mb-0 mr-3">
        {{ choiceGroupName }}
        <b-badge v-if="!loadingList" variant="primary" pill class="ml-2" style="vertical-align: middle">
          {{ choiceGroups.length }}
        </b-badge>
      </h3>
      <b-button variant="primary" size="sm" :disabled="saving" @click="addNewChoiceGroup">
        <i class="fas fa-plus-square mr-2" aria-hidden="true" />
        {{ $t('message.menu.choiceGroups.addGroupForSelectedSize') }}
      </b-button>
    </div>
    <b-overlay :show="loadingList" spinner-variant="primary" spinner-small style="min-height: 300px">
      <draggable
        v-model="choiceGroups"
        v-bind="dragOptions"
        group="cg"
        class="list-group custom-list-group"
        @change="updateChoiceGroupPositions"
      >
        <ChoiceGroupsListElement
          v-for="choiceGroup in choiceGroups"
          :key="choiceGroup.id"
          :is-active="selectedChoiceGroup === choiceGroup.id"
          :choice-group="choiceGroup"
          @onClick="selectChoiceGroup"
        />
      </draggable>
      <button
        :disabled="saving"
        class="custom-list-group-item custom-list-group-item--add-new"
        @click="addNewChoiceGroup"
      >
        <i class="fas fa-plus-square mr-2" aria-hidden="true" />
        {{ $t('message.menu.choiceGroups.addGroupForSelectedSize') }}
      </button>
    </b-overlay>
    <template v-if="!loadingList && choiceGroups.length === 0">
      <p class="text-center">
        {{ $t(`message.menu.choiceGroups.${isSingleChoiceGroup ? 'noSingleChoiceGroups' : 'noMultiChoiceGroups'}`) }}
      </p>
    </template>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { categoryMixins } from '@/mixins/categoryMixins'
import ChoiceGroupsListElement from '@/views/menu/_components/list/choice-groups/ChoiceGroupsListElement'
import draggable from 'vuedraggable'

const STORE = '_menuEdit'

export default {
  name: 'ChoiceGroupsList',
  components: { draggable, ChoiceGroupsListElement },
  mixins: [categoryMixins],
  data() {
    return {
      selectedChoiceGroup: null,
      loadingList: false,
    }
  },
  computed: {
    ...mapFields(STORE, ['form', 'multiChoiceGroups', 'singleChoiceGroups']),
    ...mapGetters(STORE, ['menuElement', 'saving', 'getActiveMenuId', 'mealsTree']),
    ...mapGetters(['isMobile']),
    choiceGroups: {
      set(val) {
        this.menuElement === 'single_choice_groups' ? this.setSingleChoiceGroups(val) : this.setMultiChoiceGroups(val)
      },
      get() {
        return this.menuElement === 'single_choice_groups' ? this.singleChoiceGroups : this.multiChoiceGroups
      },
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
        handle: this.isMobile ? '.drag-icon' : '.custom-list-group-item',
      }
    },
    choiceGroupName() {
      return this.isSingleChoiceGroup
        ? this.$t('message.menu.choiceGroups.singleChoiceGroupsTitle')
        : this.$t('message.menu.choiceGroups.multiChoiceGroupsTitle')
    },
    isSingleChoiceGroup() {
      return this.menuElement === 'single_choice_groups'
    },
    disabledDraggable() {
      return this.formChoiceGroup.entries.some((item) => !item.id)
    },
  },
  watch: {
    menuElement() {
      this.refreshList()
      this.selectedChoiceGroup = null
    },
    form() {
      if (this.selectedChoiceGroup && this.form && this.form.id !== this.selectedChoiceGroup) {
        this.selectedChoiceGroup = this.form.id
      }
    },
  },
  mounted() {
    this.getMealsTree()
    this.refreshList()
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited', 'setSingleChoiceGroups', 'setMultiChoiceGroups']),
    ...mapActions(STORE, [
      'getSingleChoiceGroups',
      'getSingleChoiceGroup',
      'getMultiChoiceGroups',
      'getMultiChoiceGroup',
      'addChoiceGroup',
      'getMealsTree',
      'updateElementsPositions',
    ]),
    async refreshList() {
      this.loadingList = true
      this.isSingleChoiceGroup ? await this.getSingleChoiceGroups() : await this.getMultiChoiceGroups()
      this.loadingList = false
    },
    async selectChoiceGroup(id) {
      this.selectedChoiceGroup = id
      this.isSingleChoiceGroup ? await this.getSingleChoiceGroup(id) : await this.getMultiChoiceGroup(id)
    },
    addNewChoiceGroup() {
      this.addChoiceGroup({ category: this.category, size: this.size })
    },
    async updateChoiceGroupPositions() {
      const apiCall = this.isSingleChoiceGroup ? 'updateSingleChoiceGroupPositions' : 'updateMultiChoiceGroupPositions'
      let requestData = this.choiceGroups.map((group, index) => ({ id: group.id, position: index + 1 }))
      requestData = { menu_id: this.getActiveMenuId, positions: requestData }
      this.setSavingStatus(true)
      const { error, data } = this.updateElementsPositions({ requestData, apiCall })
      if (error) {
        this.refreshList()
      }
    },
  },
}
</script>
<style lang="scss">
.price-input {
  .col-form-label {
    padding: 0;
  }
}
</style>
