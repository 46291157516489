<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="h3 mb-0">
        {{ $t('message.menu.meal.title') }}
        <b-badge v-if="!loadingList" variant="primary" pill class="ml-2" style="vertical-align: middle">
          {{ mealGroups.length }}
        </b-badge>
      </h3>
      <b-button v-if="selectedCategoryId" size="sm" :disabled="saving" variant="primary" @click="addNewMealGroup">
        <i class="fas fa-plus-square mr-2" aria-hidden="true" /> {{ $t('message.menu.meal.add') }}
      </b-button>
    </div>

    <b-overlay :show="loadingList" spinner-variant="primary" spinner-small style="padding: 10px">
      <template v-if="categories.length === 0">
        {{ $t('message.menu.noCategories') }}
      </template>
      <template v-else>
        <div class="elementsGroups">
          <p>{{ $t('message.menu.meal.chooseCategoryToEdit') }}:</p>
          <b-list-group-item
            v-for="(category, index) in groupedCategories"
            :key="category.id"
            :active="selectedCategoryId === category.id"
            class="custom-list-group-item list-group-item--draggable"
            @click="categoryChosen(category.id)"
          >
            <div class="d-flex align-items-center">
              <span>{{ category.name }}</span>
            </div>
            <div class="d-flex align-items-center">
              <TooltipButton :id="'meals_category_' + category.id" variant="link">
                <b-badge
                  :variant="selectedCategoryId === category.id || !category.meals_count ? 'light' : 'secondary'"
                  pill
                >
                  {{ category.mealGroups.length }}
                </b-badge>
                <template #tooltipText>{{ $t('message.menu.mealsCount') }}</template>
              </TooltipButton>
            </div>
          </b-list-group-item>
        </div>
        <div v-if="selectedCategory" class="mcgsElements">
          <div
            v-if="selectedCategory.mealGroups.length > 0"
            class="mb-2 d-flex justify-content-between align-items-center"
          >
            <div>{{ $t('message.menu.meal.title') }} w {{ selectedCategory.name }}</div>
            <b-button size="sm" :disabled="saving" variant="primary" @click="addNewMealGroup">
              <i class="fas fa-plus-square mr-2" aria-hidden="true" /> {{ $t('message.menu.meal.add') }}
            </b-button>
          </div>

          <draggable
            v-model="selectedCategory.mealGroups"
            v-bind="dragOptions"
            :group="`meals_${selectedCategoryId}`"
            class="list-group custom-list-group"
            @change="updateMealGroupsPositions"
          >
            <b-list-group-item
              v-for="mealGroup in selectedCategory.mealGroups"
              :key="mealGroup.id"
              :active="selectedMealGroupId === mealGroup.id"
              class="custom-list-group-item custom-list-group-item-slim list-group-item--draggable"
              @click="editMealGroup(mealGroup.id)"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-align-justify fa-fw mr-2 drag-icon" />
                <span>{{ mealGroup.name }}</span>
              </div>
            </b-list-group-item>
          </draggable>
          <button
            :disabled="saving"
            class="custom-list-group-item custom-list-group-item--add-new"
            @click="addNewMealGroup"
          >
            <i class="fas fa-plus-square mr-2" aria-hidden="true" /> {{ $t('message.menu.meal.add') }}
          </button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import draggable from 'vuedraggable'
import TooltipButton from '@/components/TooltipButton'

const STORE = '_menuEdit'

export default {
  name: 'MealsList',
  components: {
    draggable,
    TooltipButton,
  },
  data() {
    return {
      loadingList: false,
      selectedMealGroupId: null,
    }
  },
  computed: {
    ...mapGetters(STORE, ['categories', 'saving', 'menuElement', 'selectedCategoryId', 'getActiveMenuId']),
    ...mapGetters(['isMobile']),
    ...mapFields(STORE, ['mealGroups']),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
        handle: this.isMobile ? '.drag-icon' : '.custom-list-group-item',
      }
    },
    groupedCategories() {
      return this.categories.map((category) => {
        return {
          ...category,
          mealGroups: this.mealGroups.filter((mealGroup) => mealGroup.category == category.id),
        }
      })
    },
    selectedCategory() {
      return this.groupedCategories?.find((category) => category.id === this.selectedCategoryId) || null
    },
  },
  async mounted() {
    this.selectedMealGroupId = null
    this.setSelectedCategoryId(null)
    this.loadingList = true
    this.refreshCategoryList()
    await this.getMealGroups()
    this.loadingList = false
  },
  methods: {
    ...mapMutations(STORE, ['setForm', 'clearForm', 'setSelectedCategoryId']),
    ...mapActions(STORE, ['getCategories', 'getMealGroups', 'getMealGroup', 'addMealGroup', 'updateElementsPositions']),
    getCategoryMealsCount(category) {
      return this.mealGroups?.filter((mealGroup) => mealGroup.category == category.id).length
    },
    async refreshCategoryList() {
      if (!this.categories) {
        await this.getCategories()
      }
    },
    async categoryChosen(categoryId) {
      this.clearForm()
      this.setSelectedCategoryId(categoryId)
    },
    async editMealGroup(mealGroupId) {
      this.selectedMealGroupId = mealGroupId
      const { error } = await this.getMealGroup(mealGroupId)
    },
    async addNewMealGroup() {
      await this.clearForm()
      this.selectedMealGroupId = null
      this.addMealGroup(this.selectedCategoryId)
    },
    async updateMealGroupsPositions() {
      let requestData = this.groupedCategories
        .find((category) => category.id === this.selectedCategoryId)
        .mealGroups.map((mealGroup, index) => ({ id: mealGroup.id, position: index + 1, name: mealGroup.name }))
      requestData = { menu_id: this.getActiveMenuId, positions: requestData }
      const { error, data } = await this.updateElementsPositions({ requestData, apiCall: 'updateMealGroupsPositions' })
      if (error) {
        await this.getMealGroups()
      }
    },
  },
}
</script>
