<template>
  <div class="relative">
    <transition name="slide-from-right">
      <div v-show="isNotificationListOpen" class="notification-list-container">
        <div class="notification-list">
          <div class="notification-list-header">
            <div class="notification-list-header__title">
              {{ $t('notification') }}
            </div>

            <div class="notification-list-header__close">
              <button class="close" @click="close">
                <i class="fas fa-times" />
              </button>
            </div>
          </div>

          <div class="notification-list-body">
            <NotificationListItem
              v-for="(notification, index) in notifications"
              :key="'notification_' + notification.pk + notification.type"
              :notification="notification"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import NotificationListItem from './NotificationListItem'

export default {
  name: 'NotificationList',
  components: {
    NotificationListItem,
  },
  data: function () {
    return {
      showType: 'all',
    }
  },
  computed: {
    ...mapState('notifications', ['isNotificationListOpen', 'notifications']),
  },
  methods: {
    ...mapMutations('notifications', ['closeNotificationList']),
    close() {
      this.closeNotificationList()
    },
  },
}
</script>

<style lang="scss">
.notification-list {
  color: #31373d;
  &-container {
    position: absolute;
    right: 12px;
    top: calc(100% + 12px);
    width: 300px;
    border: 1px solid #eee;

    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.15);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-bottom: 1px solid #eee;

    &__title {
      font-size: 14px;
      font-weight: bold;
    }

    &__close {
      i {
        font-size: 16px;
      }
    }
  }

  &-body {
    overflow: auto;
    height: calc(100vh / 3);
    border-radius: 0 0 6px 6px;
  }
}

.slide-from-right-enter-active,
.slide-from-right-active {
  transition: transform 0.3s;
}
.slide-from-right-enter, .slide-from-right-leave-to /* .slide-in-leave-active in <2.1.8 */ {
  transform: translateX(140%);
}
</style>
