<template>
  <div class="meals-container">
    <div v-if="chosenMeal" class="meals-search-container">
      <i class="fas fa-search"></i>
      <div class="input-group">
        <input
          ref="mealsSearchInput"
          v-model="searchKeyword"
          type="text"
          class="form-control"
          :placeholder="$t('message.mealsMenu.search')"
          @input="searchForMeal"
        />
      </div>
    </div>
    <div v-if="!isMealSets" id="meals" class="meals-list" :class="{ 'meal-search': chosenMeal }">
      <button
        v-for="(meal, index) in getFoundMeals()"
        :id="'meal-btn_' + meal.id"
        :key="meal.id"
        :class="['meal', { 'meal--active': meal.name === chosenMeal }]"
        @keyup.left.right="focusOnNextMeal"
        @click="mealChosen(meal)"
      >
        <span class="meal__title">{{ meal.meal_group.name }}</span>
        <span v-if="meal.meal_group.sizes.length > 1 && !chosenMeal" class="meal__sub-title meal__size">{{
          meal.size.name
        }}</span>
        <span v-if="!chosenMeal" class="meal__sub-title">{{ priceDecimal(meal.size.price) }} {{ currency }}</span>
      </button>
    </div>
    <div v-else class="meals-list">
      <button
        v-for="mealSet in getFoundMeals()"
        :id="'meal-set-btn_' + mealSet.id"
        :key="mealSet.id"
        class="meal"
        @click="mealSetChosen(mealSet)"
        @keyup.left.right="focusOnNextMeal"
      >
        {{ mealSet.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mealsMixins } from '@/mixins/mealsMixins'
import { COMPLEX_MEAL_TYPES } from '@/common/constants'
import { mealSetsMixins } from '@/mixins/mealSetsMixins'

export default {
  name: 'Meals',
  mixins: [mealsMixins, mealSetsMixins],
  props: ['meals', 'isMealSets', 'chosenMeal', 'mealSetItem'],
  data() {
    return {
      mealsFound: [],
    }
  },
  computed: {
    ...mapGetters(['currency']),
  },
  methods: {
    focusOnNextMeal(event) {
      switch (event.keyCode) {
        case 37:
          // If left arrowkey was clicked
          if (document.activeElement.className.includes('meal') && document.activeElement.previousSibling)
            document.activeElement.previousSibling.focus()
          break
        case 39:
          // If right arrowkey was clicked
          if (document.activeElement.className.includes('meal') && document.activeElement.nextSibling)
            document.activeElement.nextSibling.focus()
          break
      }
    },
    mealChosen(meal) {
      this.searchKeyword = ''
      if (this.mealSetItem) {
        meal = {
          ...this.mealSetItem,
          ...meal,
        }
      }
      this.$emit('input', meal)
    },
    mealSetChosen(mealSet) {
      // assign meal data based on mealSet, so MealDetails can operate on legit meal
      mealSet = {
        ...mealSet,
        complexMeal: true,
        complexMealType: COMPLEX_MEAL_TYPES.MEAL_SET,
        id: null,
        meal_set_id: mealSet.id,
      }
      this.mealChosen(mealSet)
    },
    getFoundMeals() {
      if (this.searchKeyword !== '') return this.searchMeals
      else return this.meals
    },
    searchForMeal() {
      this.searchThroughMeals(Object.values(this.meals))
      this.searchMeals = this.searchMeals.length > 0 ? this.searchMeals : this.meals
      this.$nextTick(() => {
        this.focusOnFoundMeal()
      })
    },
    focusOnFoundMeal() {
      if (this.searchMeals.length === 1) {
        document.getElementsByClassName('meal-search')[0].lastChild.focus()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.meals-container {
  height: 100%;
}
.meals-search-container {
  display: flex;
  align-items: center;
  i {
    margin-right: 15px;
  }
}
.meals-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.movable-meal {
  cursor: move !important;
}

@media screen and (max-width: 1250px) {
  .meals-list {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    //grid-template-rows: repeat(auto-fill, 80px);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
}
</style>
