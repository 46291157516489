<template>
  <div :class="['notification-toast', `notification-toast--${typeName}`]" :style="{ 'border-left-color': color }">
    <div class="notification-toast-content">
      <NotificationContent :notification="notification" />
    </div>

    <div class="notification-toast-close">
      <button class="btn btn-icon btn-lg btn-round" @click="() => closeNotification(notification)">
        <i class="fas fa-times" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NotificationContent from './NotificationContent.vue'
import { getNotificationTypeName, getNotificationTypeColor, getNotificationTypeIcon } from './helpers'

export default {
  name: 'NotificationToast',
  components: { NotificationContent },
  props: ['notification'],
  data: function () {
    const { type } = this.notification
    const typeName = getNotificationTypeName(type)
    const color = getNotificationTypeColor(type)
    const icon = getNotificationTypeIcon(type)

    return { typeName, color, icon }
  },
  methods: {
    ...mapActions('notifications', ['closeNotification']),
  },
}
</script>

<style lang="scss">
.notification-toast {
  max-width: 500px;
  display: flex;
  overflow: hidden;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.15);

  & + .notification-toast {
    margin-top: 10px;
  }

  &-content {
    margin-right: 16px;
  }

  &-close {
    margin-left: auto;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
