import { NOTIFICATION, NOTIFICATION_TYPE_MAP } from "@/common/constants"

export const getNotificationTypeName = (type: number): string => {
  const key = NOTIFICATION_TYPE_MAP[type];
  return key ? NOTIFICATION[key].name : 'default';
};

export const getNotificationTypeColor = (type: number) => {
  const key = NOTIFICATION_TYPE_MAP[type];
  return key ? NOTIFICATION[key].color : '#4A9CFD';
}

export const getNotificationTypeIcon = (type: number) => {
  let icon = 'bell'
  if (type === NOTIFICATION.NEW_FEATURE.type) {
    icon = 'star'
  } else if (type === NOTIFICATION.ORDER_CANCELED.type) {
    icon = 'ban'
  } else if (type === NOTIFICATION.REGEOCODE.type) {
    icon = 'map-pin'
  } else if (type === NOTIFICATION.CONNECTION_FAILED.type) {
    icon = 'exclamation-triangle'
  } else if (type === NOTIFICATION.CONNECTION_ESTABLISHED.type) {
    icon = 'check-circle'
  } else if (type === NOTIFICATION.PRINTING.type) {
    icon = 'print'
  } else if (type === NOTIFICATION.DAYS_7_UNTIL_PURCHASED_PLAN_FINISHES.type
    || type === NOTIFICATION.DAYS_3_UNTIL_PURCHASED_PLAN_FINISHES.type
    || type === NOTIFICATION.DAY_1_UNTIL_PURCHASED_PLAN_FINISHES.type
    || type === NOTIFICATION.HOUR_1_UNTIL_PURCHASED_PLAN_FINISHES.type
    || type === NOTIFICATION.SUBSCRIPTION_ENDS.type) {
    icon = 'calendar-times'
  }
  return `fas fa-${icon}`;
}