import router from '@/router'
import store from '@/store/index'
import { DEFAULT_ORDERS_VIEW, DELIVERY_TYPES, MENU_SOURCE_CHOICES, PICKUP_TYPE_DELIVERY } from '@/common/constants'
import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from './index'
import { IMenuExtended } from '@/types/interface'

const resetState = async ({ commit }: ActionContext<RootState, RootState>) => {
  commit('settings/resetState')
  commit('employees/resetState')
  commit('_orders/resetState')
  commit('integrations/resetState')
  commit('auth/resetState')
  commit('resetState')
}

const getMenus = async ({ commit, dispatch, rootGetters }: ActionContext<RootState, RootState>, withMealset = true) => {
  commit('setGetMenuRequestStatus', 'loading')
  const { error, data } = await API.getMenus()
  if (!error && data) {
    let brandId = sessionStorage.getItem('currentBrandId') || ''
    let brandName = sessionStorage.getItem('currentBrandName') || ''
    let sourceId = 1
    commit('setMenus', data)

    if (!brandId) {
      if (data.length === 1) {
        brandId = data[0].brand
        sourceId = data[0].source
      } else {
        const brandIdNotOnline = Object.keys(rootGetters['auth/brands']).find(
          (key) => !rootGetters['auth/brands'][key].only_online
        )

        if (brandIdNotOnline) {
          const firstMenuNotOnline = data.find((_menu: IMenuExtended) => _menu.brand === parseInt(brandIdNotOnline))
          brandId = firstMenuNotOnline.brand
          sourceId = firstMenuNotOnline.source
        }
      }

      brandName = rootGetters['auth/brands'][brandId].name
      sessionStorage.setItem('currentBrandId', brandId)
      sessionStorage.setItem('currentBrandName', brandName)
    }

    commit('setBrand', { brandId: parseInt(brandId), brandName: brandName }, { root: true })
    commit('setSource', sourceId)
    commit('setGetMenuRequestStatus', 'success')
  } else {
    commit('setGetMenuRequestStatus', 'error')
  }
  if (withMealset) dispatch('_menu/getMealSets')
}

const getMenusByIds = async ({ dispatch }: ActionContext<RootState, RootState>, ids: Array<number>) => {
  ids.forEach(async (id) => {
    await dispatch('getMenuById', id)
  })
}

const getMenuById = async ({ commit }: ActionContext<RootState, RootState>, id: number) => {
  commit('setGetMenuRequestStatus', 'loading')
  const { error, data } = await API.getMenu(id)
  if (!error && data) {
    commit('setMenu', data)
    commit('setGetMenuRequestStatus', 'success')
  } else {
    commit('setGetMenuRequestStatus', 'error')
  }
}

const goToDefaultOrdersList = ({ state }: ActionContext<RootState, RootState>) => {
  if (parseInt(store.getters['settings/defaultOrdersListView']) === DEFAULT_ORDERS_VIEW.ORDERS_LIST) {
    router.push({ name: 'orders-list' })
  } else {
    router.push({ name: 'couriers-map' })
  }
}

const commitJson = (commit: (arg0: any, arg1: any) => void, commitName: string, storageName: string) => {
  let json = sessionStorage.getItem(storageName)
  json = json && json !== 'undefined' ? JSON.parse(json) : {}
  commit(commitName, json)
}

const initStateFromSessionStorage = ({ commit, dispatch }: ActionContext<RootState, RootState>) => {
  commit('auth/setAuthToken', sessionStorage.getItem('authToken'))
  commit('auth/setName', sessionStorage.getItem('name'))
  commitJson(commit, 'auth/setLocalization', 'localization')
  commit('auth/setLoggedIn', sessionStorage.getItem('loggedIn'))
  commit('auth/setUserRole', sessionStorage.getItem('userRole'))
  commit('setStateLoadedFromSession', true)
  commit('auth/setPaymentBackend', sessionStorage.getItem('paymentBackend'))
  commitJson(commit, 'settings/setLocalizationPreferences', 'localizationPreferences')
  commitJson(commit, 'settings/setLocalizationPermissions', 'localizationPermissions')
  commitJson(commit, 'settings/setErestoPreferences', 'erestoPreferences')
  commitJson(commit, 'auth/setBrands', 'brands')
  commitJson(commit, 'auth/setMenuSources', 'menuSources')
  commitJson(commit, 'auth/setCompany', 'company')
  commitJson(commit, 'auth/setCompanyLocalizations', 'companyLocalizations')
  commitJson(commit, 'integrations/setIntegrations', 'integrations')
  dispatch('modules/assignModuleAccess')
}

const getPredictions = async (
  { state, getters }: ActionContext<RootState, RootState>,
  { lat, lon, address, deliveryType, pickupType, price, onSuccess }: any
) => {
  if (address && deliveryType == DELIVERY_TYPES.ASAP && pickupType == PICKUP_TYPE_DELIVERY && price > 0 && lat && lon) {
    if (getters['auth/getLocalizationFeatures'].enable_promised_time_prediction) {
      const localization_id = getters['auth/getLocalizationId']
      const localization_lat = getters['auth/getLocalizationLat']
      const localization_lon = getters['auth/getLocalizationLon']
      state.getPredictionsRequest = true
      const { error, data } = await API.getPredictions({
        lat: lat,
        lon: lon,
        price: parseFloat(price),
        localization_id: localization_id,
        localization_lat: localization_lat,
        localization_lon: localization_lon,
      })
      if (!error && data) {
        // set estimatedDeliveryTime in store of main action call (orderEdit or orderDetails)
        if (onSuccess) onSuccess(data)
      }
      state.getPredictionsRequest = false
    }
  }
}

const chooseMenu = (
  { commit }: ActionContext<RootState, RootState>,
  { brandId, brandName }: { brandId: number; brandName: string }
) => {
  commit('setBrand', { brandName: brandName, brandId: brandId })
  sessionStorage.setItem('currentBrandName', brandName)
  sessionStorage.setItem('currentBrandId', brandId.toString())
  commit('setSource', parseInt(MENU_SOURCE_CHOICES.DEFAULT))
  commit('_orderEdit/setEmptyCart', null, { root: true })
}

export default {
  resetState,
  getMenus,
  goToDefaultOrdersList,
  initStateFromSessionStorage,
  getPredictions,
  chooseMenu,
  getMenusByIds,
  getMenuById,
}
