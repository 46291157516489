<template>
  <div class="h-100">
    <h1 class="sr-only">{{ $t('message.menu.menuEdit') }}</h1>
    <portal to="nav-closable-left-side">
      <div class="d-flex align-items-center">
        <span v-if="!isMobile" class="mr-3">{{ $t('message.common.menu') }}:</span>
        <BrandsDropdown :value="actualBrand.name" :size="isMobile ? 'sm' : 'lg'" @choose-brand="chooseBrand" />
        <b-button
          v-if="externalSources"
          class="finishMenuEditButton ml-2"
          variant="info"
          :size="isMobile ? 'sm' : 'lg'"
          @click="chooseExternalMenu()"
        >
          {{ $t('message.menu.editMenuExternalSource') }}
        </b-button>
      </div>
    </portal>
    <b-container id="menuEdit" fluid class="menuEdit">
      <b-row>
        <b-col class="px-0">
          <b-breadcrumb class="pl-4 mb-0">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ $t('message.menu.menuEdit') }}
              <span class="text-dark">{{ actualBrand ? ': ' + actualBrand.name : '' }}</span>
              <span v-if="externalMenuSourceName" class="text-dark"> - {{ externalMenuSourceName }}</span>
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-row class="flex-grow-1 overflow-y-auto">
        <b-col v-show="!isMobile || !form" cols="12" md="6" class="px-0 px-md-3 h-100">
          <b-card :class="{ 'h-100': !isMobile }" no-body>
            <menu-list />
            <List />
          </b-card>
        </b-col>
        <b-col v-show="!isMobile || form" cols="12" md="6" class="menu px-0 pr-md-3">
          <edit />
        </b-col>
      </b-row>
      <menu-source-modal @changeExternalSource="changeExternalSource" />
    </b-container>
    <brands-modal />
  </div>
</template>

<script>
import store from './_store'
import { mapFields } from 'vuex-map-fields'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import MenuList from './_components/list/MenuList'
import List from './_components/List'
import Edit from './_components/Edit'
import MenuSourceModal from './_components/modals/MenuSourceModal'
import BrandsDropdown from '@/components/BrandsDropdown'
import BrandsModal from '@/components/BrandsModal'
import _ from 'lodash'
import { MENU_SOURCE_CHOICES } from '@/common/constants'

const STORE = '_menuEdit'

export default {
  name: 'MenuEdit',
  components: {
    MenuList,
    List,
    Edit,
    MenuSourceModal,
    BrandsDropdown,
    BrandsModal,
  },
  async beforeRouteLeave(to, from, next) {
    this.to = to
    if (this.$store.getters['menu']) {
      await this.checkIfShouldRefreshMenu()
      next()
    } else {
      this.actualBrand = ''
      next()
    }
  },
  data() {
    return {
      confirmModalShow: false,
      to: null,
      leave: false,
      actualBrand: '',
      MENU_SOURCE_CHOICES,
      nextMenuCache: null,
    }
  },
  computed: {
    ...mapGetters(STORE, [
      'externalMenuSource',
      'externalMenuSourceName',
      'getActiveMenuId',
      'saving',
      'isEdited',
      'form',
      'editedMenusIds',
    ]),
    ...mapFields(STORE, ['differentPrices']),
    ...mapGetters({
      erestoPreferences: 'settings/erestoPreferences',
      currentBrandId: 'currentBrandId',
      menuSources: 'auth/getMenuSources',
      menus: 'menus',
      menu: 'menu',
      isErestoBrand: 'auth/isErestoBrand',
      isMobile: 'isMobile',
    }),
    externalSources() {
      if (this.currentBrandId) {
        let sources = this.menuSources[this.currentBrandId]
        sources = _.omitBy(sources, (source) =>
          [MENU_SOURCE_CHOICES.DEFAULT, MENU_SOURCE_CHOICES.NEW_ERESTAURANT].includes(source.source.toString())
        )
        return Object.values(sources).length > 0 ? sources : null
      } else {
        return null
      }
    },
    isDefaultMenu() {
      return this.menu && this.menu.source == MENU_SOURCE_CHOICES.DEFAULT
    },
  },
  beforeMount() {
    if (!this.$store.state[STORE]) this.$store.registerModule(STORE, store)
  },
  mounted() {
    this.onInit()
  },
  destroyed() {
    this.$eventBus.$off('brandChosen')
    this.$store.commit(`${STORE}/resetState`)
    this.$store.unregisterModule(STORE)
  },
  methods: {
    ...mapMutations(STORE, [
      'setSavingStatus',
      'setIsEdited',
      'setMenuElement',
      'clearForm',
      'setActiveMenuId',
      'setExternalMenuSource',
    ]),
    ...mapActions(STORE, ['resetLists']),
    ...mapActions(['chooseMenu', 'getMenus', 'getMenusByIds']),
    async checkIfShouldRefreshMenu() {
      if (this.editedMenusIds.length > 0) {
        await this.getMenusByIds(this.editedMenusIds)
      }
    },
    getErestoSettingsForCurrentBrand() {
      let brand = null
      Object.values(this.erestoPreferences).some((eresto) => {
        brand = eresto.brands.find((brand) => brand.id == this.currentBrandId)
        return brand
      })
      if (brand) this.differentPrices = brand.eresto_different_prices
    },
    reset() {
      this.clearForm()
      this.resetLists()
      this.setMenuElement('')
      this.setIsEdited(false)
      this.$nextTick(() => {
        this.setMenuElement('categories')
        this.fillMenu()
      })
    },
    fillMenu() {
      this.actualBrand = JSON.parse(sessionStorage.getItem('brands'))[this.currentBrandId]
      this.getErestoSettingsForCurrentBrand()
    },
    onInit() {
      if (this.menus) {
        this.$eventBus.$emit('onShowBrandsModal', { setBrand: true })
        this.$eventBus.$on('brandChosen', () => {
          this.setActiveMenuId(this.menu?.id)
          this.fillMenu()
        })
      } else {
        this.fillMenu()
      }
    },
    chooseBrand({ name, brandId }, force = false) {
      if (this.isEdited && !force) {
        this.nextMenuCache = { type: 'brand', data: { name, brandId } }
        this.$bvModal.show('confirmLeaveModal')
        return true
      }
      this.chooseMenu({ brandId: parseInt(brandId), brandName: name })
      this.setActiveMenuId(this.menu.id)
      this.setExternalMenuSource(null)
      this.reset()
    },
    chooseExternalMenu() {
      this.$bvModal.show('menuSourceModal')
    },
    changeExternalSource(source, force = false) {
      if (this.isEdited && !force) {
        this.nextMenuCache = { type: 'source', data: source }
        this.$bvModal.show('confirmLeaveModal')
        return true
      }
      this.setActiveMenuId(source.menu_id)
      this.setExternalMenuSource(source)
      this.$bvModal.hide('menuSourceModal')
      this.reset()
    },
  },
}
</script>

<style scoped lang="scss">
.menuEdit {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.menu {
  height: 100%;

  &-main {
    border: none;
  }
}
</style>
<style lang="scss">
.menu {
  .switch input:checked {
    &[disabled] + .slider {
      background-color: #2196f369;
    }
  }
}

.navbar-closable {
  .navbar-nav {
    width: 100%;
  }

  .vue-portal-target {
    display: flex;
  }
}
</style>
