<template>
  <b-navbar class="bottom-nav" type="dark">
    <b-navbar-nav class="bottom-nav__list">
      <ItemOrders />
      <ItemMap />
      <li v-if="$hasModuleAccess(MODULES.newOrder)" class="nav-item">
        <b-button
          class="nav-link nav-link-button nav-link-button--modal"
          variant="success"
          @click.prevent="newOrderButtonClick"
        >
          <i class="fas fa-plus-square nav-link__icon" :aria-label="$t('message.navMain.addOrder')" />
        </b-button>
        <b-modal
          ref="add-order-modal"
          hide-footer
          hide-header-close
          :title="$t('message.navMain.createNewOrder')"
          modal-class="modal--bottom"
        >
          <ul class="modal__list--inline">
            <ItemNewDelivery class="mr-3" @trigger-clicked="closeModal">
              <template #content>
                <i class="nav-link__icon fas fa-truck" aria-hidden="true" />
                <span class="nav-link__text">{{ $t('message.navMain.delivery') }}</span>
              </template>
            </ItemNewDelivery>
            <ItemNewOnsite @trigger-clicked="closeModal">
              <template #content>
                <i class="nav-link__icon fas fa-concierge-bell" aria-hidden="true" />
                <span class="nav-link__text">{{ $t('message.navMain.onSite') }}</span>
              </template>
            </ItemNewOnsite>
          </ul>
        </b-modal>
      </li>
      <ItemHelp v-if="$hasModuleAccess(MODULES.helpButton)" id="contactButtonMobile" />
      <ItemSettings v-if="$hasModuleAccess(MODULES.settings)" show-label />
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import ItemOrders from '@/components/navigation/navigation-items/ItemOrders'
import ItemMap from '@/components/navigation/navigation-items/ItemMap'
import ItemSettings from '@/components/navigation/navigation-items/ItemSettings'
import ItemHelp from '@/components/navigation/navigation-items/ItemHelp'
import ItemNewDelivery from '@/components/navigation/navigation-items/ItemNewDelivery'
import ItemNewOnsite from '@/components/navigation/navigation-items/ItemNewOnsite'
import { newOrderMixins } from '@/mixins/newOrderMixins'

export default {
  name: 'BottomNav',
  components: { ItemOrders, ItemMap, ItemSettings, ItemHelp, ItemNewDelivery, ItemNewOnsite },
  mixins: [newOrderMixins],
  created() {
    window.addEventListener('resize', this.checkScreenSize)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkScreenSize)
  },
  methods: {
    newOrderButtonClick() {
      if (this.$hasModuleAccess(this.MODULES.newOrderPapu3)) {
        if (this.isMultiBrand) this.showBrandsModal({ orderPapu3: true, setBrand: true, showOnline: false })
        else this.$eventBus.$emit('showOrderPapu3Modal')
      } else {
        this.openModal()
      }
    },
    openModal() {
      if (this.$refs['add-order-modal']) this.$refs['add-order-modal'].show()
    },
    closeModal() {
      if (this.$refs['add-order-modal']) this.$refs['add-order-modal'].hide()
    },
    checkScreenSize() {
      if (window.innerWidth >= 768) {
        this.closeModal()
      }
    },
  },
}
</script>
