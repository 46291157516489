import posthogJS from 'posthog-js'

export const posthog = posthogJS.init(process.env.VUE_APP_POSTHOG_PROJECT_API_KEY, {
  api_host: 'https://eu.posthog.com',
  persistence: 'sessionStorage',
})

export const posthogPlugin = {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog
  },
}
