<template>
  <div :class="['ingredients-section', getClass]">
    <div class="ingredients-section-header">
      <span class="ingredients-section-header__title">{{ title }}</span>
    </div>
    <div :class="[`ingredients-section-body${variant !== 'selected' ? '--grid' : ''}`]">
      <template v-if="items && items.length > 0">
        <button
          v-for="(item, index) in items"
          :id="'ingredient-btn_' + index"
          :key="`ingredient_${item.id}_${index}`"
          type="button"
          :class="['pp-button pp-button--big', buttonClass]"
          @click="$emit('onIngredientClick', item)"
        >
          <span class="ingredient__title">{{ item.name }}</span>
          <span v-if="item.price && !noPrice" class="ingredient__price">
            {{ priceDecimal(item.price) }} {{ currency }}
          </span>
          <i v-if="closeable" class="pp-button__close fas fa-times" />
        </button>
      </template>
      <div v-else class="ingredients-section__empty-list-info">
        {{ emptyListMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'IngredientsList',
  props: {
    title: {
      type: String,
      required: true,
    },
    emptyListMessage: {
      type: String,
      required: true,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    noPrice: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['currency']),
    getClass() {
      if (this.variant === 'deleted') {
        return 'ingredients-section--alert'
      } else if (this.variant === 'selected') {
        return 'ingredients-section--selected'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ingredients-section {
  width: 100%;
  padding: 15px;
  border-bottom: 1px dashed #e0e0e0;
  background-color: #fff;

  &--alert {
    background-color: #fadddd;
    border-color: #f9cfcf;
  }

  &--selected {
    background-color: #f7f7f7;
  }

  &-header {
    margin-bottom: 20px;
    &__title {
      margin: 0;
      color: $gray-600;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &-body {
    display: flex;
    flex-wrap: wrap;
    .pp-button {
      margin-bottom: 10px;
    }
    .pp-button--primary {
      span + span {
        margin-left: 10px;
      }
    }
  }
  &-body--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  &__empty-list-info {
    color: $gray-700;
    opacity: 0.5;
    margin: 15px auto;
  }
}
.ingredient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: capitalize;
  }
  &__price {
    font-weight: 400;
    opacity: 0.5;
    font-size: 14px;
  }
}
</style>
