<template>
  <div>
    <name-category v-model="name" @blur="updateCategory({ name })" />
    <is-default-switch
      v-model="is_default"
      :disabled="is_default && isEditMode"
      :tooltip-text="$t('message.menu.categories.defaultTooltipText')"
      @click="updateCategory({ is_default })"
    />
    <hr />
    <vat-dropdown v-model="vat" @click="updateCategory({ vat })" />
    <hr />
    <template v-if="isEditMode">
      <size-list-category :category-id="id" />
      <hr />
    </template>
    <div v-if="isErestoBrand" class="edit-section">
      <div class="d-flex align-items-center mb-3">
        <label class="switch mb-0 mr-2" aria-labelledby="availableInEresto">
          <input
            v-model="in_erestaurant"
            :disabled="saving"
            type="checkbox"
            @change="updateCategory({ in_erestaurant })"
          />
          <span class="slider round" />
        </label>
        <h4 id="availableInEresto" class="label mb-0">{{ $t('message.menu.categories.availableInEresto') }}</h4>
      </div>
      <template v-if="in_erestaurant">
        <eresto-image-upload v-model="icon" @upload="saveImage" @remove="removeImage" />
      </template>
    </div>
    <hr />
    <MealStatuses
      v-if="$hasModuleAccess('kds')"
      v-model="kds_available_statuses"
      @onChange="updateCategory({ kds_available_statuses })"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import NameCategory from './category/NameCategory'
import SizeListCategory from './category/SizeListCategory'
import MealStatuses from './category/MealStatuses'
import IsDefaultSwitch from './generic/IsDefaultSwitch'
import VatDropdown from './generic/VatDropdown'
import ErestoImageUpload from './generic/ErestoImageUpload'
import { inErestaurantMixin } from '../../inErestaurantMixin'

const STORE = '_menuEdit'

export default {
  name: 'CategoryEdit',
  components: {
    NameCategory,
    IsDefaultSwitch,
    VatDropdown,
    SizeListCategory,
    ErestoImageUpload,
    MealStatuses,
  },
  mixins: [inErestaurantMixin],
  computed: {
    ...mapGetters({
      isErestoBrand: 'auth/isErestoBrand',
    }),
    ...mapGetters(STORE, ['isEditMode', 'saving', 'menuElement']),
    ...mapFields(STORE, [
      'form.id',
      'form.name',
      'form.is_default',
      'form.sizes',
      'form.vat',
      'form.in_erestaurant',
      'form.icon',
      'form.kds_available_statuses',
    ]),
  },

  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited']),
    ...mapActions(STORE, ['getCategories', 'updateElement', 'updateListByElement']),
    saveImage(image) {
      this.updateCategory({ icon: image })
    },
    async updateCategory(requestData) {
      if (this.isEditMode) {
        const { error } = await this.updateElement({ id: this.id, requestData, apiCall: 'updateCategory' })
        if (!error) {
          await this.getCategories()
        } else if (error) {
          this.checkForErestaurantMealsetError(
            error,
            () =>
              this.updateElement({
                id: this.id,
                requestData: { ...requestData, confirm_set_in_erestaurant_in_meal_sets: true },
                apiCall: 'updateCategory',
              }),
            () => {
              this.in_erestaurant = true
            }
          )
        }
      }
    },
    async removeImage() {
      this.icon = ''
      await this.updateCategory({ icon: '' })
    },
  },
}
</script>
