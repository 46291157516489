import { render, staticRenderFns } from "./CheckboxButton.vue?vue&type=template&id=5f509a80&scoped=true&"
import script from "./CheckboxButton.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CheckboxButton.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CheckboxButton.vue?vue&type=style&index=0&id=5f509a80&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f509a80",
  null
  
)

export default component.exports