<template>
  <b-navbar class="main-nav" type="dark">
    <div class="main-nav__left">
      <b-navbar-brand
        :to="{
          name:
            defaultOrdersList === DEFAULT_ORDERS_VIEW.ORDERS_LIST && $hasModuleAccess(MODULES.ordersList)
              ? 'orders-list'
              : 'couriers-map',
        }"
      >
        <img :class="['navbar-brand__logo', logoClass]" :src="logoSrc" />
      </b-navbar-brand>

      <b-navbar-nav v-if="!isMobile">
        <ItemNewDelivery v-if="$hasModuleAccess(MODULES.newOrderDelivery)" />
        <ItemNewOnsite v-if="$hasModuleAccess(MODULES.newOrderOnSite)" />
        <ItemOrders v-if="$hasModuleAccess(MODULES.ordersList)" />
        <ItemMap />
      </b-navbar-nav>
    </div>

    <div class="main-nav__right">

      <b-navbar-nav class="py-0">
        <b-nav-text>
          <span class="localization">{{ getLocalizationName }}</span>
          <span class="username">{{ name }}</span>
        </b-nav-text>
      </b-navbar-nav>

      <b-navbar-nav>
        <NotificationButton />
        <template v-if="!isMobile">
          <ItemHelp v-if="$hasModuleAccess(MODULES.helpButton)" tooltip />
          <ItemChat v-if="$hasModuleAccess(MODULES.helpdeskChat)" navbar />
          <ItemSettings v-if="$hasModuleAccess(MODULES.settings)" />
        </template>
        <li v-if="$hasModuleAccess(MODULES.logout)" class="nav-item">
          <b-button class="nav-link nav-link-button" variant="navbar" @click="logout">
            <i class="fas fa-power-off" :aria-label="$t('message.navMain.logout')" />
          </b-button>
        </li>
      </b-navbar-nav>
      <NotificationList />
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_ORDERS_VIEW } from '@/common/constants'
import Vue from 'vue'
import Hotjar from 'vue-hotjar'
import ItemOrders from '@/components/navigation/navigation-items/ItemOrders'
import ItemMap from '@/components/navigation/navigation-items/ItemMap'
import ItemSettings from '@/components/navigation/navigation-items/ItemSettings'
import ItemHelp from '@/components/navigation/navigation-items/ItemHelp'
import ItemNewDelivery from '@/components/navigation/navigation-items/ItemNewDelivery'
import ItemNewOnsite from '@/components/navigation/navigation-items/ItemNewOnsite'
import ItemChat from '@/components/navigation/navigation-items/ItemChat'
import NotificationButton from '@/components/notification/NotificationButton'
import NotificationList from '@/components/notification/NotificationList'
import { isMobile, addResizeEvent, removeResizeEvent } from '@/utils/common'
import Pusher from 'pusher-js'
import { __DEV__, __STAGING__ } from '@/utils/global'

export default {
  name: 'NavMain',
  components: {
    ItemOrders,
    ItemMap,
    ItemSettings,
    ItemHelp,
    ItemNewDelivery,
    ItemNewOnsite,
    ItemChat,
    NotificationButton,
    NotificationList
  },
  data: function () {
    return {
      DEFAULT_ORDERS_VIEW: DEFAULT_ORDERS_VIEW,
      name: sessionStorage.getItem('name'),
      defaultOrdersList: this.$store.getters['settings/defaultOrdersListView'] || DEFAULT_ORDERS_VIEW.COURIERS_MAP,
      eventSource: null,
      eventSourceReconnectFrequency: 1, // Seconds
      eventSourceReconnectTimeout: null,
      isMobile: false,
      pusher: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['getCompany', 'getLocalizationName', 'createdByPosAppsmart']),
    logoSrc() {
      return this.createdByPosAppsmart ? '/static/images/order-smart-logo.svg' : '/static/images/papuio_logo_white.svg'
    },
    logoClass() {
      return this.createdByPosAppsmart ? 'navbar-brand__logo--ordersmart' : ''
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
    this.closePusher()
  },
  created() {
    this.initPusher()
    this.getNotifications()
    this.refreshInterval = setInterval(() => {
      this.getNotifications()
    }, 60000)

    if (this.getCompany.is_trial && process.env.VUE_APP_HOTJAR_ID && !sessionStorage.getItem('isPapuStaff')) {
      Vue.use(Hotjar, {
        id: process.env.VUE_APP_HOTJAR_ID,
      })
    }
    this.setIsMobile()
    addResizeEvent(this.setIsMobile)
  },
  destroyed() {
    removeResizeEvent(this.setIsMobile)
  },
  methods: {
    ...mapActions({
      getNotifications: 'notifications/getNotifications',
      logout: 'auth/logout',
    }),
    handleMessage(_data) {
      const data = JSON.parse(_data)
      if (data?.['type'] === 'I') {
        sessionStorage.removeItem('callerID')
      } else if (data?.['type'] === 'O') {
        sessionStorage.setItem('callerID', data['number'])
      }
    },
    initPusher() {
      // Enable pusher logging - don't include this in production
      Pusher.logToConsole = true

      let channelName = sessionStorage.getItem('fanoutChannel')

      if (channelName && channelName !== 'null') {
        this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
          cluster: 'eu',
        })

        const channel = this.pusher.subscribe(channelName)
        channel.bind('message', (data) => {
          this.handleMessage(JSON.stringify(data))
        })

        this.pusher.connection.bind('error', (err) => {
          console.error('Pusher error', err)
          this.$rollbar.error('Pusher error', {
            channel: channelName,
            error: JSON.stringify(error),
          })
        })

        this.pusher.connection.bind('disconnected', () => {
          console.error('Pusher disconnected')
        })
      }
    },
    closePusher() {
      if (this.pusher && typeof this.pusher.disconnect === 'function') {
        this.pusher.disconnect()
      }
    },
    setIsMobile() {
      this.isMobile = isMobile()
    },
  },
}
</script>
