<template>
  <div class="notification-list-item">
    <NotificationContent :notification="notification" :compact="true" />

    <div>
      <button
        v-if="notification.closable"
        class="btn btn-icon btn-lg notification-list-item-delete"
        @click="() => deleteNotification(notification)"
      >
        <i class="fas fa-trash" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NotificationContent from './NotificationContent'
export default {
  name: 'NotificationListItem',
  components: {
    NotificationContent,
  },
  props: ['notification'],
  data: function () {
    return {}
  },
  methods: {
    ...mapActions('notifications', ['deleteNotification']),
  },
}
</script>

<style scoped lang="scss">
.notification-list-item {
  border-bottom: 1px solid $gray-400;
  display: flex;
  justify-content: space-between;

  &-delete {
    opacity: 0.2;
    border-radius: 50%;
    margin: 4px;
    &:hover {
      background-color: $gray-100;

      opacity: 1;
      i {
        color: $red;
      }
    }
  }
}
</style>
