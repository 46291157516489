<template>
  <b-tr v-if="hasError">
    <b-td colspan="3" class="response-error">
      <response-error-msg :field="field" :response-errors="responseErrors" />
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: 'ErrorRow',
  props: {
    field: {
      type: String,
      required: true,
    },
    responseErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasError() {
      return this.responseErrors && this.responseErrors[this.field]
    },
  },
}
</script>

<style scoped>
.response-error {
  color: red;
}
</style>
