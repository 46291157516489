import { AxiosError } from 'axios'
import Vue from 'vue'

type Mealset = {
  id: string
  name: string
}

export const inErestaurantMixin = Vue.extend({
  methods: {
    checkForErestaurantMealsetError(error: Error | AxiosError, onConfirm: () => void, onCancel: () => void) {
      if (error?.response?.status === 400) {
        const errorData = error.response?.data
        const inErestaurantError = errorData?.in_erestaurant
        const mealsets = inErestaurantError
          ? inErestaurantError?.confirm_set_in_erestaurant_in_meal_sets
          : errorData?.confirm_set_in_erestaurant_in_meal_sets

        if (mealsets?.length) {
          this.disableInErestaurantConfirmation(mealsets, onConfirm, onCancel)
        }
      }
    },
    disableInErestaurantConfirmation(mealsets: Array<Mealset>, onConfirm: () => void, onCancel: () => void) {
      const mealsetsList = mealsets.map((mealset: Mealset) => mealset.name).join(',\n\n')
      this.$bvModal
        .msgBoxConfirm(`${this.$t('deactivate_meal_in_eresto_tooltip')}: ${mealsetsList}`, {
          centered: true,
          size: 'sm',
          title: this.$t('deactivate_meal_in_eresto') as string,
          okTitle: this.$t('deactivate') as string,
          cancelTitle: this.$t('message.common.cancel') as string,
          okVariant: 'danger',
          cancelVariant: 'outline-secondary',
          footerClass: 'modal-footer--menu-management',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then(async (confirm: boolean) => {
          if (!confirm) {
            onCancel()
          } else onConfirm()
        })
    },
  },
})
