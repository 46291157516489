<template>
  <b-modal
    id="remove-multi-choice-entry-modal"
    ref="modal"
    dialog-class="modal-fullscreen-dialog"
    content-class="modal-fullscreen-content"
    body-class="p-0"
    scrollable
    tabindex="-1"
    @hide="hide()"
    @show="show()"
  >
    <template #modal-header>
      <div class="modal-title mr-2">
        {{ $t('message.multiChoiceEntryModal.additives') }}
      </div>
      <button id="save-ingredients-btn--footer" type="button" class="btn btn-success save-btn" @click="hide()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark small">esc</kbd> {{ $t('message.multiChoiceEntryModal.save') }}
      </button>
    </template>
    <IngredientsList
      :key="'multiChoiceEntries' + refreshKey"
      :title="$t('message.multiChoiceEntryModal.additives')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noAdditives')"
      :closeable="true"
      :items="multiChoiceEntries"
      :button-class="'pp-button--yellow'"
      variant="selected"
      @onIngredientClick="removeMultiChoiceEntry"
    />
    <IngredientsList
      :key="'removedMultiChoiceEntries' + refreshKey"
      :title="$t('message.multiChoiceEntryModal.removedMultiChoiceEntries')"
      :empty-list-message="$t('message.multiChoiceEntryModal.noAdditives')"
      :items="removedMultiChoiceEntries"
      :button-class="'pp-button--warning'"
      variant="deleted"
      @onIngredientClick="restoreMultiChoiceEntry"
    />
    <template #modal-footer class="p-0">
      <button id="save-ingredients-btn" type="button" class="btn btn-success btn-lg btn-block" @click="hide()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark small">esc</kbd> {{ $t('message.multiChoiceEntryModal.save') }}
      </button>
    </template>
  </b-modal>
</template>

<script setup>
import { getCurrentInstance, ref } from 'vue'
import IngredientsList from './IngredientsList'

defineProps({
  multiChoiceEntries: {
    type: Array,
    required: true,
  },
  removedMultiChoiceEntries: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['removeMultiChoiceEntry', 'restoreMultiChoiceEntry', 'onModalToggle'])

const refreshKey = ref(0)
const app = getCurrentInstance()

const hide = () => {
  app.proxy.$bvModal.hide('remove-multi-choice-entry-modal')
  emit('on-modal-toggle', false)
}

const show = () => {
  emit('on-modal-toggle', true)
}

const removeMultiChoiceEntry = (item) => {
  emit('remove-multi-choice-entry', item)
  refreshKey.value++
}

const restoreMultiChoiceEntry = (item) => {
  emit('restore-multi-choice-entry', item)
  refreshKey.value++
}
</script>

<style lang="scss" scoped>
.ingredients-sections-divider {
  border-bottom: 2px solid #bbbbbb;
  width: 100%;
}
.save-btn {
  width: 200px;
  margin-left: 15px;
}
</style>
