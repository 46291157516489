<template>
  <div class="view-container">
    <b-overlay :show="loading" class="h-100">
      <template v-if="isMobile">
        <portal to="nav-title"> {{ $t('message.mealSets.backToMealSetsList') }} </portal>

        <portal to="nav-right-side">
          <b-button class="px-3" size="sm" block variant="success" @click="saveMealSet()">
            {{ $t('message.common.save') }}
          </b-button>
        </portal>
      </template>

      <template #overlay>
        <div class="text-center">
          <div class="mb-3">
            {{ $t('message.common.savingWait') }}
          </div>
          <b-spinner></b-spinner>
        </div>
      </template>

      <div class="widget">
        <div class="widget-body-wrapper">
          <b-breadcrumb class="bg-white px-0">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'mealsets-list', params: { menuId: mealSet ? mealSet.menu : null } }">
              {{ $t('message.mealSets.mealSetsSettings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active :to="{ name: 'mealset-edit' }">{{ getBreadCrumbName() }}</b-breadcrumb-item>
          </b-breadcrumb>

          <b-container fluid class="px-0">
            <MealSet
              :meal-set="mealSet"
              :type="type"
              @loading="setLoading"
              @form-changed="setMealSetFormChanged"
              @success="discardOrSaveClicked = true"
            />
          </b-container>
        </div>

        <div v-if="!isMobile" class="widget-footer-container">
          <b-col class="d-inline-flex justify-content-between my-3">
            <b-button size="md" variant="outline-secondary" class="mr-4" @click="goToMealSetList">{{
              $t('message.common.cancel')
            }}</b-button>

            <b-button size="md" block variant="success" @click="saveMealSet()">
              <i class="fas fa-save" />
              <span>{{ mealSet ? $t('message.mealSets.saveMealSet') : $t('message.mealSets.addMealSet') }}</span>
            </b-button>
          </b-col>
        </div>

        <DiscardChangesModal
          id="discardChangesModal"
          @discard-changes="discardMealSet"
          @stay-on-page="nextPage = null"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MealSet from './components/MealSet'
import DiscardChangesModal from '@/components/DiscardChangesModal'
import { isMobile, addResizeEvent, removeResizeEvent } from '@/utils/common'
import { MEALSET_TYPES } from '@/common/constants'

export default {
  name: 'MealSetEdit',
  components: { MealSet, DiscardChangesModal },
  beforeRouteLeave(to, from, next) {
    const mealSet = this.mealSet
    const haveChangeMealItems = mealSet ? mealSet.items.some((item) => item.isChanged) : true
    const showModal = haveChangeMealItems || this.mealSetFormChanged
    const discardOrSaveClicked = this.discardOrSaveClicked

    if (showModal && !discardOrSaveClicked) {
      next(false)
      this.$bvModal.show('discardChangesModal')
      this.discardOrSaveClicked = false
      this.nextPage = to
    } else {
      next()
    }
  },
  props: ['mealSet', 'type'],
  data() {
    return {
      loading: false,
      discardOrSaveClicked: false,
      nextPage: null,
      mealSetFormChanged: false,
      isMobile: false,
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
    }),
    edit() {
      return !!this.mealSet
    },
  },
  mounted() {
    // Show BrandsModal for new mealSet if more than 1 menu to set menu in store
    if (!this.mealSet && this.$store.getters['auth/isMultiBrand'] && this.$store.getters['menus']) {
      this.$eventBus.$emit('onShowBrandsModal', { setBrand: true })
    }

    this.setIsMobile()
    addResizeEvent(this.setIsMobile)
  },
  destroyed() {
    removeResizeEvent(this.setIsMobile)
  },
  methods: {
    saveMealSet() {
      this.$eventBus.$emit('onSaveMealSet')
    },
    discardMealSet() {
      this.discardOrSaveClicked = true
      this.$router.push(this.nextPage)
    },
    setLoading(value) {
      if (value) this.loading = value
      else this.loading = false
    },
    setMealSetFormChanged(value) {
      this.mealSetFormChanged = value
    },
    setIsMobile() {
      this.isMobile = isMobile()
    },
    goToMealSetList() {
      this.$router.push({ name: 'mealsets-list', params: { menuId: this.mealSet?.menu } })
    },
    getBreadCrumbName() {
      let name = this.edit ? this.mealSet.name : this.$t('message.mealSets.addMealSet')
      if (this.type === MEALSET_TYPES.HALF_PIZZA) {
        name = `${name} - [${this.$t('message.mealSets.halfByHalf')}]`
      }
      return name
    },
  },
}
</script>
