<template>
  <b-nav-item link-classes="btn-navbar" @click="toggleNotificationList">
    <i class="fas fa-bell mr-0 text-white" style="font-size: 18px" />
    <div v-if="count" class="nav-link__badge">
      <span>{{ count }}</span>
    </div>
  </b-nav-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotificationButton',
  data: function () {
    return {}
  },
  computed: {
    ...mapState('notifications', ['unreadNotifications', 'isNotificationListOpen']),
    count() {
      return this.unreadNotifications?.length || 0
    },
  },
  methods: {
    toggleNotificationList() {
      if (this.isNotificationListOpen) {
        this.$store.commit('notifications/closeNotificationList')
      } else {
        this.$store.commit('notifications/openNotificationList')
      }
    },
  },
}
</script>
<style lang="scss">
.notification-button-count {
  position: absolute;
  right: 4px;
  top: 50%;
}
</style>
