import { SOUNDS } from '@/common/constants'
import { Coordinates } from '@/types/interface'
import { isNumber } from 'lodash'

export const isMobile = () => {
  const clientWidth = window.document.body.clientWidth
  const mobileMaxWidth = 767
  return clientWidth <= mobileMaxWidth
}

export const addResizeEvent = (handler: () => void) => {
  window.addEventListener('resize', handler)
}

export const removeResizeEvent = (handler: () => void) => {
  window.removeEventListener('resize', handler)
}

export const showErrorToast = ($toasted: any, error: any) => {
  const response = error?.response?.data || ''

  let message = ''
  if (typeof response[Object.keys(response)[0]] === 'string') {
    message = response[Object.keys(response)[0]]
  } else if (response['non_field_errors']) {
    message = response['non_field_errors'][0]
  } else {
    message = 'Wystąpił błąd'
  }

  $toasted.error(message, { duration: 5000 })
}

export const showSuccessToast = ($toasted: any, message: any) => {
  $toasted.success(message, { duration: 5000 })
}

export const padZero = (str: string, len?: number) => {
  len = len || 2
  const zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export const invertColor = (hex: string, bw: boolean) => {
  if (!hex) return '#000000'
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  let r: string | number = parseInt(hex.slice(0, 2), 16)
  let g: string | number = parseInt(hex.slice(2, 4), 16)
  let b: string | number = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b)
}

export const getSoundFile = (fileName: string) => {
  switch (fileName) {
    case SOUNDS.POINT:
      return require('../../public/static/sounds/point.mp3')
    case SOUNDS.CORRECT:
      return require('../../public/static/sounds/correct.mp3')
    case SOUNDS.CRICKETS:
      return require('../../public/static/sounds/crickets.mp3')
    case SOUNDS.POSSITIVE:
      return require('../../public/static/sounds/possitive.mp3')
    case SOUNDS.EXQUISITE:
      return require('../../public/static/sounds/exquisite.mp3')
    case SOUNDS.NOTIFICATION:
      return require('../../public/static/sounds/notification.mp3')
    case SOUNDS.NOTIFY:
      return require('../../public/static/sounds/notify.mp3')
    case SOUNDS.MELODY:
      return require('../../public/static/sounds/melody.mp3')
    case SOUNDS.OMINANT:
      return require('../../public/static/sounds/ominant.mp3')
    case SOUNDS.REQUEST:
      return require('../../public/static/sounds/request.mp3')
  }
}
export const validateCoords = (coords: Coordinates) => {
  return isNumber(coords?.lat) && isNumber(coords?.lng)
}

export const createBase64Image = async (imageObject: Blob) => {
  let image = null
  const reader = new FileReader()
  reader.onload = (e) => {
    image = e?.target?.result
  }
  await reader.readAsBinaryString(imageObject)
  console.log(image)
  return image
}

export function isWordInString(text: string, word: string) {
  if (!text || !word) return false

  const wordWithoutSpecialChars = word.replace(/[^a-zA-Z0-9\s\-_]/g, '')
  const regex = new RegExp(`\\b${wordWithoutSpecialChars}\\b`, 'i')
  return text.match(regex)
}
