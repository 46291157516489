<template>
  <div
    :class="['notification-content', { 'notification-content--compact': compact }]"
    :style="{ 'border-left-color': color }"
  >
    <div>
      <div class="notification-content-icon" :style="{ 'background-color': color }">
        <i :class="`fas fa-${icon}`" />
      </div>
    </div>

    <div>
      <div class="notification-content-title">
        {{ notification.title || $t(`notificationTypes.${typeName}.title`) }}
      </div>

      <div class="notification-content-time">
        {{ notification.time }}
      </div>

      <div class="notification-content-message">
        <div v-html="notification.content" />
      </div>
    </div>
  </div>
</template>
<script>
import { getNotificationTypeName, getNotificationTypeColor, getNotificationTypeIcon } from './helpers'

export default {
  name: 'NotificationContent',
  data: function () {
    const { type } = this.notification
    const typeName = getNotificationTypeName(type)
    const color = getNotificationTypeColor(type)
    const icon = getNotificationTypeIcon(type)

    return { typeName, color, icon }
  },
  props: ['notification', 'compact'],
}
</script>
<style lang="scss">
.notification-content {
  border-left: 4px solid;
  display: flex;
  flex-wrap: nowrap;
  padding: 16px;

  &-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 16px;

    i {
      color: #fff;
    }
  }

  &-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  &-time {
    font-size: 12px;
    color: #9e9e9e;
  }

  &-message {
    margin-top: 4px;
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.9;

    > div {
      word-break: break-word;
    }
  }
}

.notification-content--compact {
  padding: 16px 12px;

  .notification-content {
    &-title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    &-message {
      margin-top: 4px;
      font-size: 13px;
      line-height: 1.3;
    }

    &-icon {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 12px;

      i {
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
</style>
