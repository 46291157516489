<template>
  <div v-if="!isMealNotFound" class="meal-edit">
    <meal-edit-header
      :title="headerName"
      :toggle-half-pizza-mode="toggleHalfPizzaMode"
      :show-half-pizza-button="showHalfPizzaButton"
      :is-half-pizza-mode-on="formInputs.complexMeal"
      :total-price="totalPrice"
    />
    <MealsetsModal :meal-sets="availableHalfPizzaMealSets" @onMealSetChosen="selectHalfPizzaMealSet" />
    <div class="meal-edit-body">
      <Sizes v-if="showSizes" :sizes="mealAvailableSizes" :form-inputs="formInputs" :on-size-select="selectSize" />
      <div v-if="formInputs.complexMeal" class="section section-border">
        <div class="section-body mb-3">
          <div v-for="(submeal, index) in formInputs.submeals" :key="index" class="submeal">
            <b-button
              variant="white"
              class="pp-button pp-button--mcgroup"
              :class="{ active: submealChosen === index }"
              @click="submealChosen = index"
            >
              <!--      MealSetItem button -->
              <div v-if="isNormalMealSet">
                <span class="meal__title" v-html="getSubmealButtonName(index)" />
                <span v-if="submeal.category" class="meal__sub-title-meal-set-item">
                  {{ submeal.category.name || submeal.category }}
                </span>
                <span v-if="submeal.size" class="meal__sub-title-meal-set-item">
                  {{ getMealSizeName(submeal) }}
                </span>
                <span v-if="submeal.price" class="meal__sub-title-meal-set-item">
                  {{ priceDecimal(submeal.price) }} {{ currency }}
                </span>
              </div>
              <!--      HalfPizza button -->
              <div v-else>
                <span v-html="getSubmealButtonName(index)" />
              </div>
            </b-button>
            <!--      MealSetItem discount -->
            <div v-if="isNormalMealSet" class="submeal--discount">
              {{ getParsedMealDiscount(submeal) }}
              <tooltip-button
                v-if="submeal.discount_sorted"
                :id="'submeal_discount_' + index"
                button-class="defaultTooltipButton"
                size="sm"
                class="d-inline-flex"
              >
                ?
                <template #tooltipText>
                  {{ $t('message.mealSets.discountSort') }}
                </template>
              </tooltip-button>
            </div>
          </div>
        </div>
        <input-error-message :_errors="errors" field="submealsNotFilled" :small="false" class="ml-3" />
      </div>
      <div class="master-section">
        <div v-if="isIngredientsSectionActive" class="section section-border" style="width: auto">
          <div class="section-header">
            <label class="section-header__title">{{ $t('message.multiChoiceEntryModal.ingredients') }}</label>
          </div>
          <div class="section-body section-body--no-margin">
            <b-button
              variant="light"
              class="pp-button pp-button--mcgroup pp-button--stretch my-1"
              @click="$bvModal.show('ingredients-modal')"
            >
              {{ $t('message.multiChoiceEntryModal.ingredients') }}
            </b-button>
            <IngredientsModal
              :ingredients-list="getIngredientsList"
              :removed-ingredients="getRemovedIngredients"
              @removeIngredient="removeIngredient"
              @restoreIngredient="restoreIngredient"
              @onModalToggle="toggleModal"
            />
          </div>
        </div>
        <div v-if="isMultiChoiceGroupsActive" class="section section-border">
          <div class="section-header">
            <label class="section-header__title">{{ $t('message.mealDetails.chooseAdditives') }}</label>
          </div>
          <div class="section-body section-body--no-margin">
            <b-button
              v-for="multi_choice_group in getMultiChoiceGroups"
              :key="'multi_choice_group_' + multi_choice_group.id"
              variant="light"
              class="pp-button pp-button--mcgroup pp-button--stretch my-1"
              @click="$bvModal.show('choices' + multi_choice_group.id)"
            >
              {{ multi_choice_group.name }}
            </b-button>
            <MultiChoiceEntryModal
              v-for="multi_choice_group in getMultiChoiceGroups"
              :key="'multi_choice_group_modal_' + multi_choice_group.id"
              :multi-choice-group="multi_choice_group"
              :multi-choice-group-entries="
                !formInputs.complexMeal ? formInputs.multiChoiceGroupEntries : activeSubmeal.multiChoiceGroupEntries
              "
              @addMultiChoiceEntry="addMultiChoiceGroupEntry"
              @removeMultiChoiceGroupEntry="removeMultiChoiceGroupEntry"
              @onModalToggle="toggleModal"
            />
          </div>
        </div>
      </div>
      <div v-if="isRemovedMultiChoiceGroupsActive" class="section section-border">
        <div class="section-body section-body--no-margin">
          <b-button
            variant="light"
            class="pp-button pp-button--mcgroup pp-button--stretch my-1"
            @click="$bvModal.show('remove-multi-choice-entry-modal')"
          >
            {{ $t('message.multiChoiceEntryModal.additives') }}
          </b-button>
          <RemoveMultiChoiceEntryModal
            :multi-choice-entries="formInputs.multiChoiceGroupEntries"
            :removed-multi-choice-entries="removedMultiChoiceEntries"
            @remove-multi-choice-entry="removeMultiChoiceGroupEntry"
            @restore-multi-choice-entry="addMultiChoiceGroupEntry"
            @on-modal-toggle="toggleModal"
          />
        </div>
      </div>
      <div v-if="isSubmealChangeButtonActive()" class="section section-border">
        <div class="section-body">
          <b-button
            id="halfChangeButton"
            variant="light"
            class="pp-button pp-button--mcgroup pp-button--stretch submeal submeal--change"
            @click="changeSubmeal()"
          >
            {{ isNormalMealSet ? $t('message.mealDetails.changeMeal') : $t('message.mealDetails.changeHalfPizza') }}
          </b-button>
          <MealsModal
            :chosen-meal="getSubmealParseClean(activeSubmeal.name)"
            :meals="getSubmealAvailableMeals()"
            @onMealChosen="halfMealChange"
          />
          <MealsMenuModal v-if="isNormalMealSet && activeSubmeal" :meal-set-item="activeSubmeal" />
        </div>
      </div>
      <div v-if="isSubmealChangeButtonActive()" class="widget-footer">
        <b-col cols="12">
          <b-button
            v-shortkey="submealChosen !== null ? ['enter'] : ['null']"
            type="button"
            class="btn btn-success btn-block btn-lg text-center"
            style="line-height: 50px"
            @click="submealChosen = null"
            @shortkey="submealChosen = null"
          >
            <kbd class="pb-0 pt-1 mr-3 bg-white text-dark">&crarr;</kbd>
            {{ $t('message.mealDetails.finalView') }}
          </b-button>
        </b-col>
      </div>
      <template v-if="isSingleChoiceGroupsActive()">
        <div
          v-for="single_choice_group in getSingleChoiceGroups"
          :key="'single_choice_group_' + single_choice_group.id"
          :class="[
            'section section-border',
            { 'section--error': errors.first('entryRequiredScg_' + single_choice_group.id) },
          ]"
        >
          <div class="section-header">
            <label class="section-header__title">{{ single_choice_group.name }}</label>
            <span v-if="single_choice_group.is_required" class="text-danger ml-1">*</span>
          </div>
          <div class="section-body">
            <b-button
              v-for="entry in single_choice_group.entries"
              :key="'single_choice_entry_' + entry.uuid"
              variant="light"
              :class="['btn--choice', { active: isActive('singleChoiceGroupEntries', entry) }]"
              @click="addOrRemoveSingleChoiceGroupEntry(entry, single_choice_group.id)"
            >
              {{ entry.name }} ({{ priceDecimal(entry.price) }} {{ currency }})
            </b-button>
          </div>
          <input-error-message
            :_errors="errors"
            :field="'entryRequiredScg_' + single_choice_group.id"
            :small="false"
            :no-margin="true"
          />
        </div>
      </template>
      <div v-if="isFormSectionActive()" class="section">
        <div class="section-form pt-2">
          <b-row>
            <b-col cols="12" lg="4">
              <price-input
                id="mealDiscountInput"
                v-model="formInputs.discount"
                class="mb-3"
                :disabled="isMealPercentDiscountDisabled"
                :label="`${$t('message.mealDetails.discount')} %`"
                append-text="%"
                :info="$t('message.mealDetails.discountDisabledInfo')"
              >
              </price-input>
              <price-input
                id="mealDiscountValueInput"
                v-model="formInputs.discount_value"
                class="mb-3"
                :disabled="isMealDiscountValueDisabled"
                :label="$t('message.mealDetails.valueDiscount')"
                :append-text="currency"
              >
              </price-input>
              <price-input
                id="mealPromotionalPriceInput"
                v-model="formInputs.promotionalPrice"
                class="mb-3"
                :disabled="isMealPromotionalPriceDisabled"
                :label="$t('message.mealDetails.promotionalPrice')"
                :append-text="currency"
                :info="$t('message.mealDetails.discountDisabledInfo')"
              >
              </price-input>
            </b-col>
            <b-col cols="12" lg="4">
              <div class="comment">
                <label for="comment" class="col-form-label pt-0">{{ $t('message.mealDetails.addComment') }}</label>
                <textarea id="comment" v-model="formInputs.comment" class="form-control comment__form-control" />
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group :label="$t('message.mealDetails.quantity')">
                <b-input-group>
                  <b-input-group-prepend style="width: 35%">
                    <b-button
                      class="button-append-text"
                      variant="danger"
                      block
                      :disabled="isMealSetItem"
                      @click="changeQuantity('decrease')"
                    >
                      -
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="quantity-input"
                    v-model="formInputs.quantity"
                    class="h-auto text-center font-weight-bold"
                    :formatter="quantityInputFormatter"
                    :disabled="isMealSetItem"
                    @blur=";['', 0].includes(formInputs.quantity) ? (formInputs.quantity = 1) : null"
                  />
                  <b-input-group-append style="width: 35%">
                    <b-button
                      class="button-append-text"
                      variant="success"
                      block
                      :disabled="isMealSetItem"
                      @click="changeQuantity('increase')"
                    >
                      +
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <meal-edit-footer
      v-if="isFormSectionActive()"
      :is-edit="edit"
      :active-meal="activeMeal"
      :total-price="totalPrice"
      :show-delete-btn="cartPosition !== null"
      :delete-meal-from-cart="deleteMealFromCart"
      :add-or-update-meal-in-cart="addOrUpdateMealInCart"
    />
    <MealDiscardChangesModal
      @saveMeal="addOrUpdateMealInCart"
      @discardChanges="discardMealChanges"
    ></MealDiscardChangesModal>
  </div>
  <div v-else class="section">
    <b-alert show variant="info">{{ $t('message.mealDetails.mealRemovedFromMenu') }}</b-alert>
    <b-button size="lg" variant="danger" block @click="deleteMealFromCart"
      >{{ $t('message.mealDetails.delete') }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { COMPLEX_MEAL_TYPES, MEALSET_ITEM_CONTEXT_CHOICES, MEALSET_TYPES } from '@/common/constants'
import { mealsMixins, mealSetsMixins, posErestoMixins } from '@/mixins'
import PriceInput from '@/components/PriceInput'
import MealsMenuModal from '@/components/meals-menu/MealsMenuModal'
import TooltipButton from '@/components/TooltipButton'
import MealsModal from '@/components/meals-menu/MealsModal'
import InputErrorMessage from '@/components/InputErrorMessage'
import MultiChoiceEntryModal from './MultiChoiceEntryModal'
import MealDiscardChangesModal from './MealDiscardChangesModal'
import MealEditHeader from './MealEditHeader'
import MealEditFooter from './MealEditFooter'
import Sizes from './Sizes'
import IngredientsModal from './IngredientsModal'
import MealsetsModal from '@/components/meal-edit/MealSetsModal'
import RemoveMultiChoiceEntryModal from './RemoveMultiChoiceEntryModal'

const STORE = '_orderEdit'

const LEFT_HALF = 0
const RIGHT_HALF = 1

export default {
  name: 'MealEdit',
  components: {
    MealsetsModal,
    TooltipButton,
    MealsMenuModal,
    MultiChoiceEntryModal,
    MealDiscardChangesModal,
    MealsModal,
    PriceInput,
    InputErrorMessage,
    MealEditHeader,
    MealEditFooter,
    Sizes,
    IngredientsModal,
    RemoveMultiChoiceEntryModal,
  },
  mixins: [mealsMixins, mealSetsMixins, posErestoMixins],
  props: ['activeMeal', 'edit', 'meals', 'mealSetItemId'],
  data: function () {
    return {
      COMPLEX_MEAL_TYPES: COMPLEX_MEAL_TYPES,
      meal: this.activeMeal.hasOwnProperty('mealCartPosition') ? this.activeMeal['mealData'] : this.activeMeal,
      mealSet: null,
      formInputs: {
        id: '',
        name: '',
        uuid: '',
        category: '',
        discount: '',
        discountValue: '',
        promotionalPrice: '',
        quantity: 1,
        comment: '',
        size: {
          name: '',
          price: '',
        },
        singleChoiceGroupEntries: [],
        multiChoiceGroupEntries: [],
        ingredients: [],
        price: '',
        complexMeal: false,
        submeals: [],
      },
      temporaryFormInputs: {},
      ingredientsList: [],
      cartPosition:
        this.activeMeal['mealCartPosition'] && this.activeMeal['mealCartPosition'] !== 0
          ? this.activeMeal['mealCartPosition']
          : null,
      isAnyModalOpen: false,
      mealChanged: false,
      submealChosen: RIGHT_HALF,
      removedMultiChoiceEntries: [],
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
    }),
    ...mapGetters(STORE, ['cart', 'isCartDiscountCode', 'activeMenuNormalMealSets', 'activeMenuHalfPizzaMealSets']),
    totalPrice() {
      let price = this.getMealTotalPrice(this.formInputs)
      if (isNaN(price)) {
        return 0
      } else {
        // Emit price change only for meal update and if price changes
        if (!this.isMealSetItem) this.$eventBus.$emit('onUpdateCartActiveMeal', this.formInputs)
        this.formInputs.price = price
        return price
      }
    },
    showSizes() {
      if (!this.mealAvailableSizes.length) return false

      return this.mealAvailableSizes.length > 1 || this.mealAvailableSizes[0]?.name !== 'default'
    },
    headerName() {
      if (this.isHalfPizza) return this.formInputs.name
      return this.meal.meal_group?.name || this.meal.name
    },
    isMealPercentDiscountDisabled() {
      return (
        this.formInputs.promotionalPrice !== '' ||
        this.formInputs.discount_value !== '' ||
        this.isCartDiscountCode ||
        this.isMealSetItem ||
        this.isNormalMealSet
      )
    },
    isMealDiscountValueDisabled() {
      return (
        this.formInputs.promotionalPrice !== '' ||
        this.formInputs.discount !== '' ||
        this.isCartDiscountCode ||
        this.isMealSetItem ||
        this.isNormalMealSet
      )
    },
    isMealPromotionalPriceDisabled() {
      return (
        this.formInputs.discount !== '' ||
        this.formInputs.discount_value !== '' ||
        this.isCartDiscountCode ||
        this.isMealSetItem ||
        this.isNormalMealSet
      )
    },
    isMealNotFound() {
      return this.activeMeal.mealData && this.activeMeal.mealData.mealNotFound
    },
    isHalfPizza() {
      return this.formInputs.complexMeal && this.formInputs.complexMealType === COMPLEX_MEAL_TYPES.HALF_PIZZA
    },
    isMealSetItem() {
      return !!this.mealSetItemId
    },
    isNormalMealSet() {
      return this.formInputs.complexMeal && this.formInputs.complexMealType === COMPLEX_MEAL_TYPES.MEAL_SET
    },
    activeSubmeal() {
      return this.formInputs.submeals[this.submealChosen]
    },
    mealAvailableSizes() {
      if (this.isHalfPizza) {
        return this.getSizesForMealSetItem(this.mealSet.items[0])
      }

      let sizes = this.meal?.meal_group?.sizes
      if (!sizes || sizes.length === 0) return []

      if (this.isMealSetItem) {
        let sizeIds = this.activeMeal.sizes.map((s) => s.id)
        return sizes.filter((size) => sizeIds.includes(size.id))
      } else return sizes
    },
    submeals() {
      return this.formInputs.submeals
    },
    getIngredientsList() {
      const ingredientsList = !this.formInputs.complexMeal
        ? this.ingredientsList
        : this.activeSubmeal
        ? this.activeSubmeal.ingredientsList
        : []
      return ingredientsList || [] // always return array
    },
    getRemovedIngredients() {
      return !this.formInputs.complexMeal
        ? this.formInputs.ingredients
        : this.activeSubmeal
        ? this.activeSubmeal.ingredients
        : []
    },
    getMultiChoiceGroups() {
      const multiChoiceGroups = this.isHalfPizza
        ? this.activeSubmeal?.multi_choice_groups
        : this.meal.multi_choice_groups
      return multiChoiceGroups || []
    },
    getSingleChoiceGroups() {
      if (this.isHalfPizza) {
        const leftHalfSingleChoiceGroups = this.submeals[LEFT_HALF]?.single_choice_groups || []
        const rightHalfSingleChoiceGroups = this.submeals[RIGHT_HALF]?.single_choice_groups || []
        return _.intersectionBy(leftHalfSingleChoiceGroups, rightHalfSingleChoiceGroups, 'id') || []
      }

      const singleChoiceGroups = this.meal.single_choice_groups
      return singleChoiceGroups || []
    },
    isIngredientsSectionActive() {
      return this.getIngredientsList.length > 0 || this.getRemovedIngredients.length > 0
    },
    isMultiChoiceGroupsActive() {
      return this.getMultiChoiceGroups.length > 0
    },
    isRemovedMultiChoiceGroupsActive() {
      return !this.isMultiChoiceGroupsActive && this.meal?.multiChoiceGroupEntries?.length > 0
    },
    availableHalfPizzaMealSets() {
      if (this.formInputs.complexMeal) return []
      return this.activeMenuHalfPizzaMealSets.filter((mealSet) => {
        return mealSet.items.some((mealSetItem) => {
          switch (mealSetItem.context) {
            case MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY:
              return mealSetItem.category.id === this.meal.category_id
            case MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_SIZE:
              return mealSetItem.sizes.map((size) => size.id).includes(this.meal.size.id)
            case MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_MEAL:
              return mealSetItem.meals.map((meal) => meal.id).includes(this.meal.meal_id)
          }
        })
      })
    },
    showHalfPizzaButton() {
      if (this.isHalfPizza) {
        // allow toggle back to normal meal only if first half is filled (so we can go back to normal meal from first half)
        return !!this.submeals[LEFT_HALF]?.meal_group?.id
      } else return this.meal.size && this.availableHalfPizzaMealSets.length > 0 && !this.isMealSetItem
    },
  },
  watch: {
    activeMeal() {
      this.initMealData()
    },
    meal() {
      this.assignDefaultSingleChoiceEntries()
    },
    submeals(newValue) {
      if (this.isNormalMealSet) {
        this.sortSubmealsDiscounts(this.formInputs, this.meal.items)
      }
      if (this.isNormalMealSet || this.isHalfPizza) {
        // Clear validation error of not filling all submeals
        if (newValue.every((s) => s.uuid)) this.validateSubmealsFilled()
      }
    },
  },
  mounted() {
    if (this.isNormalMealSet) {
      this.$eventBus.$on('onMealSetItemSave', ({ item, itemId }) => {
        this.$bvModal.hide('mealsMenuModal')
        let submealIndex = this.formInputs.submeals.findIndex((submeal) => submeal.meal_set_item_id === itemId)
        let submeal = { ...this.formInputs.submeals[submealIndex], ...item }
        this.formInputs.submeals.splice(submealIndex, 1, submeal)
        this.$eventBus.$emit('onUpdateCartActiveMeal', this.formInputs)
      })
    }
  },
  // destroyed () {
  // TODO: IS this needed? Probably. In case of multisignals, change to vuex.
  //   console.log("DESTROYED")
  //   this.$eventBus.$off('onMealSetItemSave')
  // },
  created() {
    // <!-- hide, feature for future version -->
    // this.setInitialBill()
    this.initMealData()
  },
  beforeUpdate() {
    if (!this.isMealNotFound) this.checkForChanges()
  },
  methods: {
    changeQuantity(value) {
      if (typeof this.formInputs.quantity === 'string') {
        this.formInputs.quantity = Number(this.formInputs.quantity.toString().replace(',', '.'))
      }
      if (value === 'increase') this.formInputs.quantity++
      else if (value === 'decrease' && this.formInputs.quantity > 1) this.formInputs.quantity--
    },
    quantityInputFormatter(value, event) {
      if (value == 0) return ''
      else if (value.length > 3) return value.substring(0, 3)
      else return value.replace(/[^\d]/g, '')
    },
    discardMealChanges() {
      // Discard meal changes, close MealDetails view and clear active meal in cart
      this.$store.commit('_orderEdit/setMealSaved', true)
      this.$emit('onSuccess', true)
      this.$eventBus.$emit('onUpdateCartActiveMeal', null)
    },
    checkForChanges() {
      // Check for any changes in meal
      let promoPriceChanged = this.formInputs.promotionalPrice !== this.temporaryFormInputs.promotionalPrice
      let discountChanged = this.formInputs.discount !== this.temporaryFormInputs.discount
      let discountValueChanged = this.formInputs.discount_value !== this.temporaryFormInputs.discount_value
      let scgChanged =
        JSON.stringify(this.formInputs.singleChoiceGroupEntries) !==
        JSON.stringify(this.temporaryFormInputs.singleChoiceGroupEntries)
      let mcgChanged =
        JSON.stringify(this.formInputs.multiChoiceGroupEntries) !==
        JSON.stringify(this.temporaryFormInputs.multiChoiceGroupEntries)
      let ingredientChanged =
        JSON.stringify(this.formInputs.ingredients) !== JSON.stringify(this.temporaryFormInputs.ingredients)
      let submealsChanged =
        JSON.stringify(this.formInputs.submeals) !== JSON.stringify(this.temporaryFormInputs.submeals)
      let mealFormChanged =
        promoPriceChanged ||
        discountChanged ||
        discountValueChanged ||
        scgChanged ||
        mcgChanged ||
        ingredientChanged ||
        submealsChanged

      this.$store.commit('_orderEdit/setMealSaved', !mealFormChanged)
    },
    initMealData() {
      if (this.activeMeal.hasOwnProperty('mealCartPosition')) {
        // FIXME: We shouldn't set activeMeal to meal when editing meal from cart.
        // this.meal should be raw meal from menu.
        // We should assign activeMeal to formInputs, as formInputs is current form state.
        this.meal = this.activeMeal['mealData']
        this.cartPosition = this.activeMeal['mealCartPosition']
      } else {
        this.meal = { ...this.activeMeal }
        // // We don't need Meal.id in later flow
        // // Meal.id SHOULD NOT BE PASSED TO FORM on new meal,
        // // because then we think it's OrderMeal.id
        this.meal.meal_id = this.activeMeal.id
        this.meal.id = null
      }
      this.getMealData()
      this.assignMealData()
    },
    getMealData() {
      // Finds raw meal in menu or raw mealSet and assigns to this.meal
      let mealData = null
      const isNormalMealSet = this.meal.complexMeal && this.meal.complexMealType === COMPLEX_MEAL_TYPES.MEAL_SET
      const isHalfPizzaMealSet = this.meal.complexMeal && this.meal.complexMealType === COMPLEX_MEAL_TYPES.HALF_PIZZA
      const isNormalMeal = !this.meal.complexMeal

      if (isNormalMealSet) {
        if (this.edit) {
          mealData = this.activeMenuNormalMealSets.find(({ id }) => id === this.meal.meal_set_id)
          if (mealData) this.mealSet = mealData
          // FIXME: This should only set mealSet and not mealData which is merged in meal
        } else {
          this.mealSet = this.activeMenuNormalMealSets.find(({ id }) => id === this.meal.meal_set_id)
        }
      } else if (isHalfPizzaMealSet && this.edit) {
        this.mealSet = this.activeMenuHalfPizzaMealSets.find(({ id }) => id === this.meal.meal_set_id)
      } else if (isNormalMeal) {
        mealData = this.findMealInMenu(this.meal)
      }

      // FIXME: We shouldn't merge mealData (from menu) with this.meal (from cart).
      if (mealData) {
        // Merge of meal from menu and meal from cart
        this.meal = { ...mealData, ...this.meal }
      }

      // Assigning scgs and mcgs from category
      if (isNormalMeal && this.meal.single_choice_groups && this.meal.multi_choice_groups) {
        let [scgs, mcgs] = this.getChoices(this.meal)
        this.meal.single_choice_groups = scgs
        this.meal.multi_choice_groups = mcgs
      }
    },
    assignDefaultSingleChoiceEntries() {
      // select default single choice group entry
      if (!this.edit && this.getSingleChoiceGroups) {
        this.getSingleChoiceGroups.forEach((scg) => {
          let defaultEntry = scg.entries.find((entry) => entry.is_default)
          if (defaultEntry) this.addOrRemoveSingleChoiceGroupEntry(defaultEntry, scg.id)
        })
      }
    },
    assignMealSetItemSingleMeal(submeal) {
      // If MealSetItem has only 1 meal available to choose - assign it
      let mealSetItemCategoryJson = this.generateMealSetItemMenuCategoryJson(submeal)
      let countOfMealsAvailable = this.getCategoryMealsCount(mealSetItemCategoryJson)
      if (countOfMealsAvailable === 1) {
        let theOnlyMeal = mealSetItemCategoryJson.sizes[0].meals[0]
        Object.assign(submeal, theOnlyMeal)
      }
    },
    getChoices(meal) {
      if (Object.values(this.menu).length > 0 && Object.values(meal).length) {
        let mealScgs = this.menu.single_choice_groups.filter((scg) => {
          return meal.single_choice_groups.includes(scg.id)
        })
        let mealMcgs = this.menu.multi_choice_groups.filter((mcg) => {
          return meal.multi_choice_groups.includes(mcg.id)
        })
        return [[...mealScgs], [...mealMcgs]]
      } else return [[], []]
    },
    initFormInput() {
      this.formInputs = {
        name: '',
        uuid: '',
        category: '',
        discount: '',
        promotionalPrice: '',
        quantity: 1,
        comment: '',
        // <!-- hide, feature for future version -->
        // bill: null,
        size: '',
        singleChoiceGroupEntries: [],
        multiChoiceGroupEntries: [],
        ingredients: [],
        price: '',
        complexMeal: false,
        submeals: [],
        meal_id: null,
      }
    },
    getMealForm(meal = {}) {
      // standard meal form
      let form = {
        id: meal.id ? meal.id : null,
        meal_id: meal.meal_id ? meal.meal_id : null,
        name: meal.name ? meal.name : '',
        uuid: meal.uuid ? meal.uuid : null,
        category: meal.category ? meal.category : '',
        category_id: meal.category_id ? meal.category_id : null,
        discount: meal.discount ? meal.discount : '',
        discount_value: meal.discount_value ? meal.discount_value : '',
        promotionalPrice: meal.promotionalPrice ? meal.promotionalPrice : '',
        quantity: meal.quantity ? meal.quantity : 1,
        comment: meal.comment ? meal.comment : '',
        size: meal.size ? meal.size : '',
        singleChoiceGroupEntries: meal.singleChoiceGroupEntries ? meal.singleChoiceGroupEntries : [],
        multiChoiceGroupEntries: meal.multiChoiceGroupEntries ? meal.multiChoiceGroupEntries : [],
        ingredients: meal.ingredients ? meal.ingredients : [],
        price: meal.price ? meal.price : '',
        complexMeal: meal.complexMeal ? meal.complexMeal : false,
        submeals: meal.submeals ? meal.submeals.sort((a, b) => a.meal_set_item_id - b.meal_set_item_id) : [],
      }
      if (form.discount || form.promotionalPrice) form.discount_value = ''
      return form
    },
    adjustMealSetItemForm(sub) {
      this.assignDiscount(sub)
      sub.meal_set_item_id = sub.id
      sub.meal_set_item_uuid = sub.uuid
      delete sub.id
      delete sub.uuid
      delete sub.created_at
      sub.quantity = sub.count
      delete sub.count
      this.assignMealSetItemSingleMeal(sub)
      sub.price = this.getMealTotalPrice(sub)
    },
    assignMealData() {
      const meal = JSON.parse(JSON.stringify(this.meal))
      this.formInputs = this.getMealForm(meal)

      if (this.formInputs.complexMeal) {
        this.initComplexMeal()
        if (this.isNormalMealSet) {
          if (!this.edit) {
            // NEW MEAL_SET
            // Fill every MEAL_SET_ITEM with empty meal form and submeal data
            this.formInputs.submeals = meal.items.map((submeal) => {
              let sub = { ...this.getMealForm(), ...submeal }
              this.adjustMealSetItemForm(sub)
              return sub
            })
          } else {
            this.formInputs.submeals = meal.items.map((submeal, index) => {
              return { ...submeal, ...this.formInputs.submeals[index] }
            })
          }
        }
        this.formInputs.meal_set_id = this.mealSet.id
        this.formInputs.meal_set_uuid = this.mealSet.uuid

        if (this.edit) this.getHalfPizzaSubmealsData(this.formInputs.submeals)
        if (!this.edit) this.assignDiscount(this.formInputs, this.mealSet)
      }

      this.temporaryFormInputs = JSON.parse(JSON.stringify(this.formInputs))
      this.ingredientsList = meal && meal.meal_group ? [...meal.meal_group.ingredients] : []
      if (this.edit && meal.ingredients) {
        // remove already chosen ingredients from edited meal
        let activeMealIngredientUUIDs = meal.ingredients.map((ing) => ing.uuid)
        this.ingredientsList = this.ingredientsList.filter((ing) => !activeMealIngredientUUIDs.includes(ing.uuid))
      }
    },
    selectSize(size) {
      if (!this.formInputs.complexMeal) {
        this.setSize(size)
        this.setMealFromSizeAndMealGroupId(this.meal.category_id, size, this.meal.meal_group.id)
      } else if (this.isHalfPizza) {
        this.formInputs.submeals.forEach((submeal, index) => {
          let meal = null
          if (submeal?.meal_group?.id)
            meal = this._searchMealBySizeAndMealGroupId(submeal.category_id, size.name, submeal.meal_group.id)
          if (meal) {
            this.halfMealChange({ ...meal }, index)
          } else {
            this.clearHalfPizzaSubmealForm(index)
            this.clearSelectedSingleChoiceEntries()
          }
        })
        // this.setMealFromSizeAndMealGroupId(size) FIXME: Seems like not needed, because parent meal of half pizza doesn't have meal
        this.setSize(size)
        this._setHalfPizzaMealSetNameAndPrice()
      }
    },
    setSize(value) {
      if (!this.formInputs.complexMeal) this.formInputs.size = { name: value.name, price: value.price, id: value.id }
      else this.formInputs.size = { name: value.name, price: '', id: value.id }
    },
    addOrRemoveSingleChoiceGroupEntry(newEntry, scgId) {
      // remove 'required' errors for scg
      this.errors.items = this.errors.items.filter((item) => {
        return item.field !== `entryRequiredScg_${scgId}`
      })
      const singleChoiceGroupEntries = this.formInputs.singleChoiceGroupEntries
      let scgExists = false
      singleChoiceGroupEntries.forEach((entry) => {
        if (newEntry.group_name === entry.group_name) {
          singleChoiceGroupEntries.splice(singleChoiceGroupEntries.indexOf(entry), 1)
          if (newEntry.name === entry.name) {
            scgExists = true
            // add required error if scg is required
            if (this.getSingleChoiceGroups.find((scg) => scg.id === scgId).is_required) {
              this.errors.add({
                field: `entryRequiredScg_${scgId}`,
                msg: this.$t('message.mealDetails.selectionRequired'),
              })
            }
          }
        }
      })

      // add new scg only if it wasn't the same scg that we removed
      if (!scgExists) {
        singleChoiceGroupEntries.push({
          uuid: newEntry.uuid,
          name: newEntry.name,
          group_name: newEntry.group_name,
          price: newEntry.price,
        })
      }
    },
    addMultiChoiceGroupEntry(newEntry) {
      let formInputs = !this.formInputs.complexMeal ? this.formInputs : this.activeSubmeal
      const entryToAdd = {
        uuid: newEntry.uuid,
        name: newEntry.name,
        group_name: newEntry.group_name,
        price: newEntry.price,
      }
      if (newEntry.id) {
        entryToAdd.id = newEntry.id
      }
      formInputs.multiChoiceGroupEntries.push(entryToAdd)
      this.removedMultiChoiceEntries = this.removedMultiChoiceEntries.filter((entry) => entry.id !== newEntry.id)
    },
    removeMultiChoiceGroupEntry(entry) {
      let formInputs = !this.formInputs.complexMeal ? this.formInputs : this.activeSubmeal
      formInputs.multiChoiceGroupEntries.splice(formInputs.multiChoiceGroupEntries.indexOf(entry), 1)
      this.removedMultiChoiceEntries.push(entry)
    },
    removeIngredient(ingredient) {
      let ingredientsList = !this.formInputs.complexMeal ? this.ingredientsList : this.activeSubmeal.ingredientsList
      let removedIngriedients = !this.formInputs.complexMeal ? this.formInputs : this.activeSubmeal
      ingredientsList.splice(ingredientsList.indexOf(ingredient), 1)
      removedIngriedients.ingredients.push(ingredient)
    },
    restoreIngredient(ingredient) {
      let ingredientsList = !this.formInputs.complexMeal ? this.ingredientsList : this.activeSubmeal.ingredientsList
      let removedIngriedients = !this.formInputs.complexMeal ? this.formInputs : this.activeSubmeal
      removedIngriedients.ingredients.splice(removedIngriedients.ingredients.indexOf(ingredient), 1)
      ingredientsList.push(ingredient)
    },
    isActive(inputName, value) {
      let active = false
      if (typeof this.formInputs[inputName] !== 'object') {
        return this.formInputs[inputName].name === value.name
      } else {
        this.formInputs[inputName].forEach(function isItDefault(entry) {
          if (JSON.stringify(entry.uuid) === JSON.stringify(value.uuid)) {
            active = true
          }
        })
      }
      return active
    },
    isSingleChoiceGroupsActive() {
      return !this.formInputs.complexMeal || (this.formInputs.complexMeal && this.submealChosen === null)
    },
    isFormSectionActive() {
      return !this.formInputs.complexMeal || (this.formInputs.complexMeal && this.submealChosen === null)
    },
    isSubmealChangeButtonActive() {
      return this.formInputs.complexMeal && this.submealChosen !== null
    },
    initComplexMeal() {
      if (this.meal.complexMealType) this.formInputs.complexMealType = this.meal.complexMealType
      this.formInputs.uuid = null
      this.submealChosen = LEFT_HALF
    },
    toggleHalfPizzaMode() {
      if (this.formInputs.complexMeal) {
        // Change from complexMeal to normal - find the original menu meal and fill null or blank values of meal with found meal,
        // remove submeals and change meal to normal meal
        // then assign meal data
        const { category_id, size, meal_group } = this.submeals[LEFT_HALF]
        this.setMealFromSizeAndMealGroupId(category_id, size, meal_group.id)
        this.assignMealData()
      } else {
        if (this.availableHalfPizzaMealSets.length > 1) this.$bvModal.show('mealSetsModal')
        else this.selectHalfPizzaMealSet(this.availableHalfPizzaMealSets[0])
      }
    },
    selectHalfPizzaMealSet(halfPizzaMealSet) {
      this.mealSet = halfPizzaMealSet

      // Change from normal meal to HALF_PIZZA complexMeal
      this.assignMealData()
      this.assignDefaultSingleChoiceEntries()
      this.formInputs.complexMeal = true
      this.formInputs.complexMealType = COMPLEX_MEAL_TYPES.HALF_PIZZA
      this.formInputs.meal_set_id = halfPizzaMealSet.id
      this.formInputs.meal_set_uuid = halfPizzaMealSet.uuid

      this.assignDiscount(this.formInputs, halfPizzaMealSet)

      const meal = this._searchMealBySizeAndMealGroupId(
        this.meal.category_id,
        this.meal.size.name,
        this.meal.meal_group.id
      )
      this.formInputs.submeals.push(
        this.initHalfPizzaSubmealForm(meal, halfPizzaMealSet.items[0]),
        this.initHalfPizzaSubmealForm(meal, halfPizzaMealSet.items[1])
      )
      this._setHalfPizzaMealSetNameAndPrice()
    },
    initHalfPizzaSubmealForm(meal, mealSetItem) {
      // Form of Submeal of HALF_PIZZA COMPLEX MEAL
      const [scgs, mcgs] = this.getChoices(meal)

      return {
        name: `1/2 ${meal.name}`,
        quantity: 0.5,
        price: meal.size.price,
        size: {
          name: meal.size.name ? meal.size.name : '',
        },
        multiChoiceGroupEntries: [],
        singleChoiceGroupEntries: [],
        multi_choice_groups: this._getHalfPizzaMultiChoiceGroups([...mcgs]),
        single_choice_groups: [...scgs],
        ingredientsList: meal && meal.meal_group ? [...meal.meal_group.ingredients] : [],
        ingredients: [],
        uuid: meal.uuid,
        meal_group: meal.meal_group,
        category: meal.category,
        meal_set_item_id: mealSetItem.id,
        meal_set_item_uuid: mealSetItem.uuid,
      }
    },
    getHalfPizzaSubmealsData(submeals) {
      // Only edit
      // Adds raw meal data from menu to every submeal to make it operative
      // Only HALF_PIZZA, because MEAL_SET_ITEMs are filled with menu raw meal when entering MealDetails for MealSet submeal
      if (this.isHalfPizza) {
        // For HALF_PIZZA complexMeal
        let meals =
          Object.values(this.getMealsForActualSize()).length > 0 ? this.getMealsForActualSize() : this.getAllMeals()
        if (Object.values(meals).length > 0) meals = Object.values(meals)
        submeals.forEach((submeal) => {
          let meal = meals.find((meal) => meal.uuid === submeal.uuid)
          meal = JSON.parse(JSON.stringify(meal))
          let [scgs, mcgs] = this.getChoices(meal)
          submeal.multi_choice_groups = this._getHalfPizzaMultiChoiceGroups([...mcgs])
          submeal.single_choice_groups = [...scgs]
          let submealActualIngredientUUIDs = submeal.ingredients.map((ing) => ing.uuid)
          submeal.ingredientsList = meal.meal_group.ingredients.filter(
            (ing) => !submealActualIngredientUUIDs.includes(ing.uuid)
          )
          submeal.price = meal.price
          submeal.category = meal.category
          submeal.meal_group = meal.meal_group
        })
      }
    },
    _getHalfPizzaMultiChoiceGroups(mcgs) {
      // returns multi_choice_groups with half price for entries
      let halfPizzaMcgHalfPrice = this.$store.getters['settings/halfPizzaMcgHalfPrice']
      return this.getHalfPizzaMultiChoiceGroups(mcgs, halfPizzaMcgHalfPrice)
    },
    changeSubmeal() {
      if (this.isNormalMealSet) this.$bvModal.show('mealsMenuModal')
      else this.$bvModal.show('mealsModal')
    },
    halfMealChange(meal, side) {
      const half = [LEFT_HALF, RIGHT_HALF].includes(side) ? side : this.submealChosen
      this.formInputs.submeals.splice(half, 1, this.initHalfPizzaSubmealForm({ ...meal }, this.mealSet.items[half]))
      this._setHalfPizzaMealSetNameAndPrice()
      this.clearSelectedSingleChoiceEntries()
    },
    clearHalfPizzaSubmealForm(submealIndex) {
      this.formInputs.submeals[submealIndex] = {
        name: '',
        price: 0,
        meal_set_item_id: this.mealSet.items[submealIndex].id,
        meal_set_item_uuid: this.mealSet.items[submealIndex].uuid,
      }
    },
    clearSelectedSingleChoiceEntries() {
      this.formInputs.singleChoiceGroupEntries = []
    },
    _setHalfPizzaMealSetNameAndPrice() {
      if (this.isHalfPizza) {
        let halfPizzaPriceType = this.$store.getters['settings/halfPizzaPrice']
        let halfPizzaAdditionalPrice = this.$store.getters['settings/halfPizzaAdditionalPrice']
        this.setHalfPizzaMealNameAndPrice(this.formInputs, halfPizzaPriceType, halfPizzaAdditionalPrice)
      }
    },
    getSubmealAvailableMeals() {
      if (!this.isNormalMealSet) return this.getMealsForActualSize()
      // PIZZA HALF
      else return null // MEAL_SET_ITEM - will generate
    },
    getMealsForActualSize() {
      if (Object.values(this.meal).length > 0 && Object.values(this.menu.categories).length > 0) {
        let category = this.menu.categories.find((category) => {
          return category.id === this.meal.category_id
        })
        if (category) {
          let size = category.sizes.find((size) => {
            return this.formInputs.size.name === size.name
          })
          if (this.mealSet.items[0].context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_MEAL) {
            return _.intersectionBy(size.meals, this.mealSet.items[0].meals, 'id')
          }
          return [...size.meals]
        } else return []
      }
    },
    getSubmealButtonName(index) {
      // returns name of submeal shown on button
      let name = this.getSubmealParseClean(this.formInputs.submeals[index].name)
      if (!name) name = this.$t('message.mealDetails.none') + '<span class="text-danger ml-1">*</span>'
      if (this.isHalfPizza) {
        // HALF_PIZZA -  every name has 'left'/'right' appended
        let halfPizzaName = index === 0 ? this.$t('message.mealDetails.left') : this.$t('message.mealDetails.right')
        name = `${name} - ${halfPizzaName}`
      }
      return name
    },
    cleanMealSet() {
      delete this.formInputs.uuid
      delete this.formInputs.category
      delete this.formInputs.category_id
      delete this.formInputs.size
      delete this.formInputs.singleChoiceGroupEntries
      delete this.formInputs.multiChoiceGroupEntries
      delete this.formInputs.ingredients
    },
    validateRequiredScg() {
      let result = true
      this.getSingleChoiceGroups.forEach((scg) => {
        if (scg.is_required) {
          // find selected entries from group
          let selectedEntries = _.intersectionWith(
            scg.entries,
            this.formInputs.singleChoiceGroupEntries,
            (entry, selectedEntry) => {
              return entry.name === selectedEntry.name && entry.group_name === selectedEntry.group_name
            }
          )
          // add or remove validation errors bag
          if (selectedEntries.length === 0) {
            this.errors.add({
              field: `entryRequiredScg_${scg.id}`,
              msg: this.$t('message.mealDetails.selectionRequired'),
            })
            result = false
          } else {
            this.errors.items = this.errors.items.filter((item) => {
              return item.field !== `entryRequiredScg_${scg.id}`
            })
          }
        }
      })
      return result
    },
    validateSubmealsFilled() {
      if (!(this.isNormalMealSet || this.isHalfPizza)) return true

      let result = this.formInputs.submeals.every((submeal) => submeal.uuid)
      let fieldName = 'submealsNotFilled'
      if (!result) {
        this.errors.add({
          field: fieldName,
          msg: this.$t('message.mealDetails.submealsNotFilled'),
        })
        return false
      } else {
        this.errors.items = this.errors.items.filter((item) => item.field !== fieldName)
        return true
      }
    },
    addOrUpdateMealInCart() {
      // For update or add of meal by Save/Add button
      if (this.isAnyModalOpen) return false
      // check if required scgs have at least 1 entry selected
      if (!this.validateRequiredScg()) return false
      // check if all submeals of mealSet are filled
      if (!this.validateSubmealsFilled()) return false
      this.formInputs.price = this.totalPrice
      // for cases when quantity is empty
      this.formInputs.quantity = ['', null].includes(this.formInputs.quantity) ? 1 : this.formInputs.quantity
      if (this.formInputs.complexMeal) {
        // remove unnecessary data from submeals
        this.formInputs.submeals.forEach((submeal) => {
          this.cleanSubmeal(submeal)
        })
        // remove unnecessary data from MealSet
        if (this.isNormalMealSet) this.cleanMealSet()
      }

      // MEAL_SET_ITEM
      if (this.isMealSetItem) {
        this.saveMealSetItem()
        return
      }
      // MEALS
      // if updated meal
      if (this.cartPosition !== null) this.updateMeal()
      // if new
      else {
        this.$store.dispatch('_orderEdit/addMealToCart', this.formInputs)
        this.$eventBus.$emit('onAddMealToCart')
      }
      this.initFormInput()
      this.$emit('onSuccess', true)
      this.$eventBus.$emit('onUpdateCartActiveMeal', null)
      this.$store.commit('_orderEdit/setMealSaved', true)
    },
    updateMeal() {
      // Only for dynamic update of meal after changing meal
      this.formInputs.price = this.totalPrice
      this.$store.dispatch('_orderEdit/updateMealInCart', { meal: this.formInputs, cartPosition: this.cartPosition })
    },
    saveMealSetItem() {
      this.$eventBus.$emit('onMealSetItemSave', { item: this.formInputs, itemId: this.mealSetItemId })
      this.initFormInput()
      this.$emit('onSuccess', true)
    },
    _searchMealBySizeAndMealGroupId(categoryId, mealSizeName, mealGroupId) {
      let category = this.menu.categories.find((category) => {
        return this.meal.category_id === category.id
      })
      let size = category?.sizes.find((size) => {
        return mealSizeName === size.name // FIXME: use id
      })
      return size?.meals.find((meal) => {
        return mealGroupId === meal.meal_group.id
      })
    },
    setMealFromSizeAndMealGroupId(categoryId, mealSize, mealGroupId) {
      const meal = this._searchMealBySizeAndMealGroupId(categoryId, mealSize.name, mealGroupId)
      this.meal = { ...meal, meal_id: meal.id, id: null }
      this.ingredientsList = [...meal.meal_group.ingredients]
      let [scgs, mcgs] = this.getChoices(meal)
      this.meal.single_choice_groups = scgs
      this.meal.multi_choice_groups = mcgs
      this.formInputs.uuid = meal.uuid
      this.setFormInputsEntriesEmpty()
    },
    setFormInputsEntriesEmpty() {
      this.formInputs.multiChoiceGroupEntries = []
      this.formInputs.singleChoiceGroupEntries = []
      this.formInputs.ingredients = []
    },
    deleteMealFromCart() {
      this.$store.commit('_orderEdit/removePositionFromCart', this.cartPosition)
      this.$emit('onSuccess', true)
      this.$eventBus.$emit('onUpdateCartActiveMeal', null)
      this.$store.commit('_orderEdit/setMealSaved', true)
    },
    toggleModal(show) {
      this.isAnyModalOpen = show
    },
    getSizesForMealSetItem(mealSetItem) {
      const category = this.menu.categories.find((category) => category.id === mealSetItem.category.id)
      return category?.sizes.filter((size) => mealSetItem.sizes.map((s) => s.id).includes(size.id)) || []
    },
  },
}
</script>

<style lang="scss">
.meal-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.master-section {
  display: flex;
  border-bottom: 1px dashed #e0e0e0;
  .section + .section {
    border-left: 1px dashed #e0e0e0;
  }
  .section-border {
    border-bottom: none;
  }
}
.section {
  padding: 5px 15px 8px 15px;
  width: 100%;

  &--error {
    background-color: #fff9f9;
    box-shadow: inset 0 0 0 1px #ffbdbd;
  }

  &-header {
    margin: 5px 0;
    &__title {
      margin: 0;
      color: $gray-600;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  &-border {
    border-bottom: 1px dashed #e0e0e0;
  }

  &-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;

    &--no-margin {
      margin: 0 -10px;
    }
  }
}

.comment__form-control {
  height: 78px;
  margin-bottom: 15px;
}
.button-append-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5rem;
}
.btn--choice {
  @include media-breakpoint-down(lg) {
    flex-grow: 1;
  }

  &:focus {
    z-index: 1;
  }
}
</style>
